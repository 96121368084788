import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProduct } from './getAllProductApi';

export const productAsync = createAsyncThunk(
  'product/fetchProduct',
  async () => {
    const response = await fetchProduct();
    return response.data;
  }
);

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    value: [],
    minPriceValue: 1,
    maxPriceValue: 1000,
    minDataValue: 1,
    maxDataValue: 1000,
    minContractLengthValue: 1,
    maxContractLengthValue: 1000,
    status: "idle",
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(productAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(productAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;

        // Calculate min and max values for price, data, and contract length
        const prices = action.payload.map(product => product.finalPrice);
        const data = action.payload.map(product => product.data);
        const contractLengths = action.payload.map(product => product.contractTerm);
        state.minPriceValue = Math.min(...prices);
        state.maxPriceValue = Math.max(...prices);
        state.minDataValue = Math.min(...data);
        state.maxDataValue = Math.max(...data);
        state.minContractLengthValue = Math.min(...contractLengths);
        state.maxContractLengthValue = Math.max(...contractLengths);
      })
      .addCase(productAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.error;
      })
  }
});
export const selectProduct = (state) => state.product.value;
export const selectProductStatus = (state) => state.product.status;
export const selectMinMaxValues = (state) => ({
  minPriceValue: state.product.minPriceValue,
  maxPriceValue: state.product.maxPriceValue,
  minDataValue: state.product.minDataValue,
  maxDataValue: state.product.maxDataValue,
  minContractLengthValue: state.product.minContractLengthValue,
  maxContractLengthValue: state.product.maxContractLengthValue
});

export default productSlice.reducer;

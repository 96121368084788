//Local url
//export const API_URL="http://localhost:8085/";

//UAT url
// export const API_URL="https://uatgktsimoapi.altius.cc/";

//Live url
export const API_URL="https://simoapi.altius.cc/";


// Dealer signUp Url
//UAT url
// export const DEALER_SIGNUP_URL="https://uat-abacus.altius.cc/dealer/dealerSignUp.htm";

//Live url
export const DEALER_SIGNUP_URL="https://gkdata.co.uk/dealer/dealerSignUp.htm";

//recaptcha data
export const REACT_APP_SITE_KEY = "6LfE-U4qAAAAACvKlvIbY3UIjzfUN4WvK-M7m4C3";

import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { faqContent } from "../../constant/faqContent";
import AccordionComponent from "../common/accordion/AccordionComponent";
import useScrollToTop from "../customHooks/useScrollToTop";

const FAQ = () => {
  useScrollToTop();
  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <div className="flex flex-col mx-auto max-w-6xl">
          <h1 className="text-2xl font-semibold leading-9 tracking-tight primary-text capitalize mb-4">
            Frequently Asked Questions
          </h1>
          <AccordionComponent data={faqContent} />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FAQ;

import React, { useEffect, useState } from "react";
import Loader from '../loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ModalComponent from "../modalComponent/ModalComponent";
import { closeModal, openModal } from "../modalComponent/modalSlice";
import VerificationService from "../../services/VerificationService";

const VerificationOnly = () => {
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const { id } = useParams();
  const modalInitState = useSelector((state) => state.modal);

  useEffect(() => {
    if (id !== undefined && id !== null && id !== "") {
      setShowLoader(true);
      VerificationService.doVerification(id)
        .then((response) => {
          setShowLoader(false);

          if (response.status === 200) {
            setMessage("You are successfully verified");
            dispatch(openModal("verification success"));

          } else if (response.status === 202) {
            setMessage("Verification success with additional action required");
            dispatch(openModal("verification success"));

          } else if (response.data.failedValue === 39 || response.data.failedValue === 40) {
            setMessage("Verification failed");
          } else {
            setMessage("");
          }
        })
        .catch((error) => {
          setShowLoader(false);
          if (error.message === "Network Error") {
            setMessage("Network Error");
            dispatch(openModal("network error"));

          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                setMessage(error.response.data.failedReason);
                break;
              case 403:
                dispatch(openModal("internal error"));
                break;
              case 404:
                dispatch(openModal("internal error"));
                break;
              case 500:
                setMessage("Server error occurred. Please try again later.");
                dispatch(openModal("server error"));
                break;
              case 406:
                setMessage("Verification link is expired");
                dispatch(openModal("link expired"));
                break;
              case 409:
                setMessage("Email already verified");
                dispatch(openModal("already verified"));
                break;
              case 412:
                dispatch(openModal("unknown error"));
                break;
              default:
                dispatch(openModal("unknown error"));
                break;
            }
          }
        });
    }
  }, [id, dispatch]);

  return (
    <>
      <ModalComponent
        size="md"
        type={modalInitState.contentId === "verification success" ? "success" : "failed"}
        position="center"
        title={
          modalInitState.contentId === "network error" ||
          modalInitState.contentId === "internal error" ||
          modalInitState.contentId === "server error" ||
          modalInitState.contentId === "unknown error" ||
          modalInitState.contentId === "link expired" ||
          modalInitState.contentId === "already verified"
            ? "Verification pending"
            : modalInitState.contentId === "verification success"
            ? "Verification Successful"
            : "Error Occurred"
        }
        description=""
        bottomLine={true}
      >
        <div>
          {modalInitState.contentId === "network error" ? (
            <p>Network Error Occurred. Please try again later.</p>
          ) : modalInitState.contentId === "internal error" ? (
            <p>Internal Error Occurred. Please try again later.</p>
          ) : modalInitState.contentId === "server error" ? (
            <p>Server Error Occurred. Please try again later.</p>
          ) : modalInitState.contentId === "unknown error" ? (
            <p>Unknown Error Occurred. Please try again later.</p>
          ) : modalInitState.contentId === "link expired" ? (
            <p>Verification link is expired!</p>
          ) : modalInitState.contentId === "already verified" ? (
            <p>Verification link is already verified.</p>
          ) : modalInitState.contentId === "verification success" ? (
            <p>You are successfully verified.</p>
          ) : null}
        </div>
      </ModalComponent>

      {showLoader && <Loader />}
    </>
  );
}

export default VerificationOnly;

import React, { useEffect, useState } from "react";
import Button from "../button/Button";
import {
  closeModal,
  openModal,
  selectModalContentId,
} from "../modalComponent/modalSlice";
import ModalComponent from "../modalComponent/ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CancellationService from "../../services/CancellationService";
// import Loader from '../loader/Loader'
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate, useParams } from 'react-router-dom';
// import ModalComponent from "../modalComponent/ModalComponent";
// import { closeModal, openModal } from "../modalComponent/modalSlice";
// import Button from "../button/Button";
// import VerificationService from "../../services/VerificationService";

const ModalError = ({ errorMsg }) => {
  return (
    <div>
      <p>{errorMsg}</p>
      <p>Please try again</p>
    </div>
  );
};

const ModalSuccess = ({ successMsg }) => {
  return (
    <div>
      <p>{successMsg}</p>
    </div>
  );
};

const OrderCancellationStatus = () => {
  const { verificationId, orderId } = useParams();
  const [errorMessageModal, setErrorMessageModal] = useState("");
  const modalContentId = useSelector(selectModalContentId);

  const dispatch = useDispatch();
  useEffect(() => {
    if (verificationId && orderId) {
      OrderCancelConfirmation(verificationId, orderId);
    }
    // /orderCancelConfirmation/{verificationId}/{orderId}
  }, []);

  const OrderCancelConfirmation = async (verificationId, orderId) => {
    CancellationService.orderCancelConfirmation(verificationId, orderId)
      .then((response) => {
        if (response.status === 200) {
          //console.log("working");
          dispatch(openModal("successModal"));
        }
      })
      .catch((error) => {
        dispatch(openModal("errorModal"));
        switch (error.response ? error.response.status : "") {
          case 400:
            setErrorMessageModal(error.response.data.failedReason);
            break;
          case 403:
            setErrorMessageModal(error.response.data.failedReason);
            break;
          case 404:
            setErrorMessageModal(error.response.data.failedReason);
            break;
          case 500:
            setErrorMessageModal(error.response.data.failedReason);
            break;
          default:
            break;
        }
      });
  };
  //   const [showLoader, setShowLoader] = useState(false);
  //   const navigate = useNavigate();
  //   const dispatch = useDispatch();
  //   const [message, setMessage] = useState();
  //   const [failedValue, setFailedValue] = useState("");
  //   const { id } = useParams();
  //   const modalInitState = useSelector((state) => state.modal);

  //for dealer verification
  //   console.log("id in rgister====", id);
  //   useEffect(() => {
  //     if (id !== undefined && id !== null && id !== "") {
  //       setShowLoader(true)
  //       console.log("idd====", id);
  //       VerificationService.doVerification(id)
  //         .then((response) => {
  //           setShowLoader(false);
  //           console.log("response=====", response.data);
  //           console.log("response tttt=====", response.status);

  //           if (response.status === 200) {
  //             localStorage.setItem("accesstoken", response.data.token);
  //             localStorage.setItem("emailId", response.data.email);
  //             localStorage.setItem("name", response.data.name);
  //             localStorage.setItem("userId", response.data.id);
  //             localStorage.setItem("isVerified", response.data.isVerified);
  //             localStorage.setItem("roleId", response.data.role.roleId);
  //             localStorage.setItem("reffralCode", response.data.referralCode ? response.data.referralCode : "");
  //             localStorage.setItem("loggedIn", true);
  //             setFailedValue("");
  //             setMessage("login successful");
  //             navigate("/");

  //           } else if (response.status === 202) {
  //             setFailedValue(202);
  //             dispatch(openModal("verification success"))

  //             localStorage.setItem("dealerId", response.data.dealerId);
  //             console.log("rsponse 202=====");

  //           } else if (response.data.failedValue === 39 || response.data.failedValue === 40) {
  //             console.log("======failed value ==", response.data.failedValue);
  //             setFailedValue(response.data.failedValue);
  //           }
  //           else {
  //             setMessage("");
  //           }
  //         })
  //         .catch((error) => {
  //           setShowLoader(false);
  //           if (error.message === "Network Error") {
  //             setMessage("Network Error");
  //             dispatch(openModal("Network Error"))

  //           } else {
  //             console.log("errrrroorrr res====", error.response);
  //             console.log("errrrroorrr====", error.response.data);

  //             switch (error.response ? error.response.status : "") {
  //               case 401:
  //                 setMessage(error.response.data.failedReason);
  //                 break;
  //               case 403:
  //                 //show internal error
  //                 dispatch(openModal("internal error"));
  //                 break;
  //               case 404:
  //                 dispatch(openModal("internal error"));
  //                 break;
  //               case 500:
  //                 setMessage("Server error occured. Please try again later.");
  //                 dispatch(openModal("server error"));
  //                 break;
  //               case 406:
  //                 setFailedValue(40);   // verification link is expired
  //                 dispatch(openModal("link expired"));
  //                 break;
  //               case 409:
  //                 setFailedValue(39);   // email allready verified
  //                 dispatch(openModal("allready verified"));
  //                 break;
  //               case 412:
  //                 dispatch(openModal("unknown Error"));
  //                 break;
  //               default:
  //                 dispatch(openModal("unknown Error"));
  //                 break;
  //             }
  //           }
  //         })
  //       // .finally(() => {
  //       //   setShowLoader(false);
  //       // });

  //     }
  //   }, []);

  return (
    <>
      <ModalComponent
        size="md"
        type={modalContentId === "successModal" ? "success" : "error"}
        position="center"
        title={
          modalContentId === "successModal"
            ? "Confirmation Successfull"
            : "Something went wrong"
        }
        description=""
        bottomLine={true}
      >
        {modalContentId === "successModal" ? (
          <ModalSuccess successMsg="Thank you for your confirmation, you can close this tab." />
        ) : (
          <ModalError errorMsg={errorMessageModal} />
        )}
      </ModalComponent>
    </>
  );
};

export default OrderCancellationStatus;

import axios from 'axios';
import { API_URL } from '../constant/Constant';

class ResetPassword {
  forgotPasswordVerification(json) {
    return axios.post(`${API_URL}authentication/forgotPasswordVerification`, json, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });
  }

  resetPassword(reset) {
    //console.log("reset service----->",reset);
    return axios.post(`${API_URL}authentication/forgotPasswordReset`, reset, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });
  }
}

export default new ResetPassword();

import axios from 'axios';

import React, { Component } from 'react';
import { API_URL } from '../constant/Constant';

class LoginService {
    
    loginUser(json) {
        //console.log("service json---->",json);
        return axios.post(`${API_URL}authentication/signinWeb`,json,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              }
        });

    }
    
}



export default new LoginService()

import axios from "axios";
import { API_URL } from "../constant/Constant";

class AcknowledgementService{
    checkAcknowledgeStatus(orderId) {
        return axios.get(`${API_URL}api/v1/checkCustomerAcknowledgment`, {
          headers: {
            orderId: orderId, 
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
        });
      }

}
export default new AcknowledgementService()
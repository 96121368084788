import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    accesstoken: "",
    emailId: "",
    name: "",
    username: "",
    userId: 0,
    isVerified: 0,
    roleId: "",
  },
};

const modalSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload;
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    removeUser: (state) => {
      state.user = {};
    },
  },
});

export const { addUser, removeUser, updateUser } = modalSlice.actions;

export default modalSlice.reducer;

import React from "react";
import Lottie from "react-lottie";
import animationData from "../../lottie/loader.json";

const LoaderButton = ({ type, title, disabled, handleClick, style, name }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <button
    name={name}
      onClick={handleClick}
      type={type}
      disabled={disabled}
      className={`${style} w-full cta-one h-auto py-1.5 px-3  rounded font-normal ${
        disabled ? "pointer-events-none cursor-none" : ""
      }`}
    >
      {disabled ? (
        <Lottie options={defaultOptions} height={24} width={24} />
      ) : (
        // <img src={loadingGif} alt="loading..." className="mx-auto" width={24} height={24} />
        title
      )}
    </button>
  );
};

export default LoaderButton;
import React, { useEffect, useState } from "react";
import { RiShareBoxFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { openDocumentModal } from "../documentModal/DocumentModalSlice";
import DocumentModal from "../documentModal/DocumentModal";
import Button from "../../button/Button";
import { useNavigate } from "react-router-dom";
import { GrDocumentText } from "react-icons/gr";
import { API_URL } from "../../../constant/Constant";
import axios from "axios";

const DocumentUpdates = () => {
  const [threeDocClicked, setThreeDocClicked] = useState(false);
  const [partnerC8DocClicked, setPartnerC8DocClicked] = useState(false);
  const [allDoc, setAllDoc] = useState(false);
  const navigate = useNavigate();
  const tAndCShowToCustomerId = localStorage.getItem("tAndcshowtoCustomerId");
  console.log("tAndCShowToCustomerId===>", tAndCShowToCustomerId);
  const dealerContractshowtoCustomerId = localStorage.getItem(
    "dealerContractshowtoCustomerId"
  );
  const emailId = localStorage.getItem("emailId");
  const docData = localStorage.getItem("DocInfo");
  const parsedDocDataTry = docData ? JSON.parse(docData) : null;

  const [docError, setDocError] = useState("");

  const dispatch = useDispatch();
  // const[tandCShowCostomer,setTandCShowCostomer]=useState(false);
  // const [dealerDocShowCostomer,setDealerDocShowCostomer]=useState(false);
const tandCShowCostomer=parsedDocDataTry.termAndCondition.showtoCustomer;
const dealerDocShowCostomer=parsedDocDataTry.dealerContract.showToCustomer;
console.log("allDoc",allDoc);
useEffect(() => {
  if (tandCShowCostomer && dealerDocShowCostomer) {
    if (threeDocClicked && partnerC8DocClicked) {
      setAllDoc(true);
    }else{
      setAllDoc(false);
    }
  } else if (tandCShowCostomer == true && dealerDocShowCostomer == false) {
    if (threeDocClicked) {
      setAllDoc(true);
    } else if (threeDocClicked === false) {
      setAllDoc(false);
    }
  } else if (dealerDocShowCostomer == true && tandCShowCostomer == false) {
    if (partnerC8DocClicked) {
      setAllDoc(true);
    } else if (partnerC8DocClicked === false) {
      console.log("inside parter else if");
      setAllDoc(false);
    }
  }
}, [
  threeDocClicked,
  partnerC8DocClicked,
  tandCShowCostomer,
  dealerDocShowCostomer,
]);


  const handleDocumentSubmitButton = async () => {
    // setResendBtnDisabled(true);
    //api call ack
    try {
      const a = parsedDocDataTry.termAndCondition.showToCustomer == false ? 0 : tAndCShowToCustomerId;
      const b=parsedDocDataTry.dealerContract.showToCustomer ? Number(dealerContractshowtoCustomerId) : 0;
     

      const response = await axios.put(`${API_URL}api/v1/updateTermsAndConditionIdAndDealerContractId`, 
      {},
      {
        headers: {
          emailId: emailId, 
          termsAndConditionId: a,
          dealerContractId: b, 
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        console.log("Document submitted successfully:", response.data);
    }
  } catch (error) {
    // Disable the loader if it's enabled
    // setSubmitLoader(false);

    if (error.message === "Network Error") {
        setDocError("Network error occurred. Please try again later.");
    } else {
        switch (error.response ? error.response.status : "") {
            case 400:
                setDocError("Failed to update Document Info. Please try again later.");
                break;
            case 403:
                setDocError("Failed to update Document Info. Please try again later.");
                break;
            case 404:
                setDocError("Failed to update Document Info. Please try again later.");
                break;
            case 500:
                setDocError("Server error occurred. Please try again later.");
                break;
            default:
                setDocError("Unknown error occurred. Please try again later.");
                break;
        }
    }
}
  };

  return (
    <>
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <div className="flex flex-col mx-auto max-w-6xl mt-8">
          <h1 className="text-2xl font-semibold tracking-tight primary-text capitalize flex items-center gap-2">
            <GrDocumentText className="text-[24px]" />
            Updated policies
          </h1>
          <p className="mb-2">please read all the updated policies</p>
          <div className="mt-4 flex flex-col items-start gap-2">
          {parsedDocDataTry.termAndCondition.showtoCustomer ?(
              <div className="flex items-center">
                <button
                  onClick={() => setThreeDocClicked((prev) => !prev)}
                  className={`dummy-checkbox relative mr-[10px] !mt-0`}
                >
                  {threeDocClicked ? (
                    <span className="dummy-checked"></span>
                  ) : null}
                </button>
                <label
                  name="contract-info"
                  htmlFor="contract-info"
                  className="text-base flex items-center"
                >
                  Terms and Conditions for using the Three Network
                  <span
                    onClick={() => dispatch(openDocumentModal(2))}
                    className="underline hover:opacity-50 transition flex items-center ml-1 cursor-pointer font-semibold"
                  >
                    View Document
                    <RiShareBoxFill className="ml-1" />
                  </span>
                </label>
              </div>
            ): null}
           {parsedDocDataTry.dealerContract.showToCustomer ?(
              <div className="flex items-center">
                <button
                  onClick={() => setPartnerC8DocClicked((prev) => !prev)}
                  className={`dummy-checkbox relative mr-[10px] !mt-0`}
                >
                  {partnerC8DocClicked ? (
                    <span className="dummy-checked"></span>
                  ) : null}
                </button>
                <label className="text-base flex items-center">
                  Three Consumer C8 Information
                  <span
                    onClick={() => dispatch(openDocumentModal(1))}
                    className="underline hover:opacity-50 transition flex items-center ml-1 cursor-pointer font-semibold"
                  >
                    View Document
                    <RiShareBoxFill className="ml-1" />
                  </span>
                </label>
              </div>
            ): null}
          </div>
        </div>
        <div className="w-full flex justify-center mt-6">
          <Button
            style={`${allDoc ? "" : "btn-disabled"} cta-one rounded px-3`}
            title="Viewed all Documents & Proceed"
            type="button"
            handleClick={async () => {
              await handleDocumentSubmitButton(); // Call your API function
              navigate("/productDetails"); // Navigate to the next route after API call
            }}
          />
        </div>
        {docError && <p className="error-text">{docError}</p>}
      </main>
      <DocumentModal
        position="center"
        title="Documentation"
        bottomLine={true}
      />
    </>
  );
};

export default DocumentUpdates;

import axios from 'axios';
import { API_URL } from '../constant/Constant';
class ForgotPassword {

    forgotPassword(json) {
        return axios.post(`${API_URL}authentication/forgotPassword`,json,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              }
        });
    }
}
export default new ForgotPassword()
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isDocumentOpen: false,
  contentId: null,
};


const modalSlice = createSlice({
  name: 'documentModal',
  initialState,
  reducers: {
    openDocumentModal: (state, action) => {
      state.isDocumentOpen = true;
      state.contentId = action.payload;
    },
    closeDocumentModal: (state) => {
      state.isDocumentOpen = false;
      state.contentId = null;
    },
  },
});

export const { openDocumentModal, closeDocumentModal } = modalSlice.actions;

export const selectDocumentModalState = (state) => state.documentModal.isDocumentOpen;
export const selectDocumentModalContentId = (state) => state.documentModal.contentId;

export default modalSlice.reducer;

import React, { useEffect } from "react";
// import './styles.css';
import Lottie from "react-lottie";
import animationData from "../../lottie/loader.json";

export default function Loader({ size }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
    };

    // Add event listener to prevent scrolling
    window.addEventListener('scroll', preventScroll, { passive: false });
    window.addEventListener('wheel', preventScroll, { passive: false });
    window.addEventListener('touchmove', preventScroll, { passive: false });

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', preventScroll);
      window.removeEventListener('wheel', preventScroll);
      window.removeEventListener('touchmove', preventScroll);
    };
  }, []);
  return (
    <div
      style={{ zIndex: 999999999999 }}
      className="fixed inset-0 bg-teal-800/40 flex justify-center items-center pointer-events-auto"
    >
      <Lottie
        options={defaultOptions}
        height={size ? size : 60}
        width={size ? size : 60}
      />
    </div>
  );
}

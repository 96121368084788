import { createSlice } from '@reduxjs/toolkit';
// import thunk from 'redux-thunk';

export const initialState = {
  isOpen: false,
  contentId: null
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
      state.contentId = action.payload
    },
    closeSync: (state) =>{
      state.isOpen = false;
      state.contentId = null;
    },
    closeAsync: (state) => {
      state.isOpen = false;
      state.contentId = null;
    },
  },
});

export const { openModal,closeSync, closeAsync } = modalSlice.actions;
export const selectModalState = (state) => state.modal.isOpen;
export const selectModalContentId = (state) => state.modal.contentId;

export const closeModal = (state) => (dispatch) => {
  dispatch(closeSync());
  setTimeout(() => {
    dispatch(closeAsync(state))
  }, 200)
}

export default modalSlice.reducer;

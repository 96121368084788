import axios from "axios";
import { API_URL } from "../constant/Constant";
class TermsAndConditionService {
  getTermsAndCondition(emailId) {
    return axios.get(`${API_URL}api/v1/getTermAndCondition`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        emailId: emailId,
      },
    });
  }

  updateTermsAndCondition(emailId, termsAndConditionId) {
    return axios.put(`${API_URL}api/v1/getTermAndCondition`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        emailId: emailId,
        termsAndConditionId: termsAndConditionId,
      },
    });
  }

  updateDocumentId(emailId, termsAndConditionId, dealerContractId) {
    return axios.put(
      `${API_URL}api/v1/updateTermsAndConditionIdAndDealerContractId`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          emailId: emailId,
          termsAndConditionId: termsAndConditionId,
          dealerContractId: dealerContractId,
        },
      }
    );
  }
}
export default new TermsAndConditionService();

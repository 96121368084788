import React, { useEffect } from "react";
import Lottie from "react-lottie";
import animationData from "../../lottie/loader-green.json";

const MicroLoaderGreen = ({size}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
    };

    // Add event listener to prevent scrolling
    window.addEventListener('scroll', preventScroll, { passive: false });
    window.addEventListener('wheel', preventScroll, { passive: false });
    window.addEventListener('touchmove', preventScroll, { passive: false });

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', preventScroll);
      window.removeEventListener('wheel', preventScroll);
      window.removeEventListener('touchmove', preventScroll);
    };
  }, []);
  return (
    <Lottie
        options={defaultOptions}
        height={size?size:24}
        width={size?size:24}
      />
  )
}

export default MicroLoaderGreen
import { createSlice } from '@reduxjs/toolkit';

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    isLoggidIn: false,
  },
  reducers: {
    handleLogIn: (state) => {
      state.isLoggidIn = true;
    },
    handleLogOut: (state) => {
      state.isLoggidIn = false;
    },
  },
});

export const { handleLogIn, handleLogOut } = navbarSlice.actions;

export const selectLoggedIn = (state) => state.navbar.isLoggidIn;

export default navbarSlice.reducer;

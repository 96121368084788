import axios from "axios";
import { API_URL } from "../constant/Constant";
class OrderService {
  async createOrder(order) {
    return await axios.post(`${API_URL}api/v1/createOrder`, order, {});
  }

  async getOrder(startDate, endDate, orderStatusId) {
    return await axios.get(
        `${API_URL}api/v1/order/${startDate}/${endDate}/${orderStatusId}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('accesstoken')}` }
        }
    );
}
  async createOrderForPayAsYouGo(order) {
    return await axios.post(`${API_URL}api/v1/createOrder`, order, {headers: {'payAsYouGo': 1}});
  }
}
export default new OrderService();

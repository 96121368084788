import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "../features/modalComponent/modalSlice";
import productReducer from "../features/product/productSlice";
import navbarReducer from '../features/navbar/navbarSlice';
import userSliceReducer from "../features/login/userSlice";
import documentModalReducer from "../features/legal/documentModal/DocumentModalSlice";

export const store = configureStore({
    reducer: {
        modal: modalReducer,
        product: productReducer,
        navbar: navbarReducer,
        user: userSliceReducer,
        documentModal: documentModalReducer,
    }
})
import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import CustomSelect from "../common/dropdown/CustomSelect";
import LoaderButton from "../button/LoaderButton";
import CancellationService from "../../services/CancellationService";
import ModalComponent from "../modalComponent/ModalComponent";
import { closeModal, openModal } from "../modalComponent/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";

const ModalSuccess = ({ refNumber }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      dispatch(closeModal());
    };
  }, []);
  return (
    <div>
      <p>
        Your SIM only contract cancellation request has been successfully
        submitted. Our team will review your request and get back to you within
        24 working hours. Please note your reference number for future
        communication.
      </p>
      <br />
      <p className="font-semibold">Reference Number: {refNumber}</p>
      <Button
        title="Go to Homepage"
        handleClick={() => {
          navigate("/");
          dispatch(closeModal());
        }}
        style="cta-one mt-4 w-full"
        type="button"
      />
    </div>
  );
};
const ModalDirectSuccess = ({ refNumber }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      dispatch(closeModal());
    };
  }, []);
  return (
    <div>
      <p>
        Your SIM only contract cancellation request has been successfull. The details has been sent to your registered email ID.
      </p>
      <br />
      <p className="font-semibold">Reference Number: {refNumber}</p>
      <Button
        title="Go to Homepage"
        handleClick={() => {
          navigate("/");
          dispatch(closeModal());
        }}
        style="cta-one mt-4 w-full"
        type="button"
      />
    </div>
  );
};

const ModalError = ({ error }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      dispatch(closeModal());
    };
  }, []);
  return (
    <div>
      <p>{error}</p>
      <Button
        title="Go to Homepage"
        handleClick={() => {
          navigate("/");
          dispatch(closeModal());
        }}
        style="cta-one mt-4 w-full"
        type="button"
      />
    </div>
  );
};

const CancellationConfirmation = () => {
  const orderDetails = localStorage.getItem("orderCancellationDetails");
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [iccidValue, setIccidValue] = useState("");
  const [msisdnValue, setMsisdnValue] = useState("");
  const [showExtraField, setShowExtraField] = useState(false);
  const [selectCancellationReason, setSelectCancellationReason] =
    useState(null);
  const [totalData, setTotalData] = useState({});
  const [notesValue, setNotesValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refNumber, setRefNumber] = useState();
  const [modalErrors, setModalErrors] = useState(null);

  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [notes, setNotes] = useState("");
  const [portedMsisdn, setPortedMsisdn] = useState(undefined);
  const [requirePacCode, setRequirePacCode] = useState(null);
  const modalInitState = useSelector((state) => state.modal);

  // useEffect(() => {
  //   dispatch(openModal("OrderCancel"));
  // }, [])

  useEffect(() => {
    if (JSON.parse(orderDetails)) {
      setNameValue(JSON.parse(orderDetails).name);
      setEmailValue(JSON.parse(orderDetails).email);
      setIccidValue(JSON.parse(orderDetails).iccid);
      setMsisdnValue(JSON.parse(orderDetails).lockMsisdn);
      setTotalData(JSON.parse(orderDetails));
    }
    if (JSON.parse(orderDetails)?.mobileNumberSelection === 1) {
      setShowExtraField(false);
    } else {
      setShowExtraField(true);
    }
  }, [orderDetails]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      portedMsisdn: null,
    }));
  }, [portedMsisdn]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      requirePacCode: null,
    }));
  }, [requirePacCode]);
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      selectCancellationReason: null,
    }));
  }, [selectCancellationReason]);

  const cancellationReasonList =
    JSON.parse(orderDetails).cancellationReasonList;
  //console.log("cancellationReasonList---", cancellationReasonList);
  const cancellationReason = cancellationReasonList.map((reason) => ({
    id: reason.cancellationReasonId,
    value: reason.cancellationReasonDesc,
  }));

  const handleFinalCancellationRequest = async (e) => {
    e.preventDefault();
    //validation
    setLoader(true);
    setErrors({});
    const tempError = {};
    // const msisdnPattern = /^\d{1,11}$/;
    const msisdnPattern = /^\d{10}$/; //10 digit number allow
    //console.log("selectCancellationReason", selectCancellationReason);
    //console.log("portedMsisdn", portedMsisdn);
    //console.log("requirePacCode", requirePacCode);
    //console.log("notes", notes);

    if (
      selectCancellationReason === null ||
      selectCancellationReason === undefined ||
      selectCancellationReason === "" ||
      selectCancellationReason.length <= 0
    ) {
      tempError.selectCancellationReason = "Please select cancellation reason";
    }
    if (showExtraField === true) {
      if (!portedMsisdn) {
        tempError.portedMsisdn = "Mobile Number is required";
      } else if (!msisdnPattern.test(portedMsisdn)) {
        tempError.portedMsisdn = "Mobile Number should be a 10 digit number";
      }
    }
    if (
      showExtraField === true &&
      (requirePacCode === null ||
        requirePacCode === undefined ||
        requirePacCode === "")
    ) {
      tempError.requirePacCode = "Please select your requirement";
    }

    if (Object.keys(tempError).length > 0) {
      setErrors((prev) => ({
        ...prev,
        ...tempError,
      }));
      setLoader(false);
      return;
    }

    const finalData = {
      orderIdEncrypted: totalData.orderIdEncrypted,
      iccid: totalData.iccid,
      msisdn: portedMsisdn,
      lockMsisdn: totalData.lockMsisdn,
      userId: totalData.userId,
      name: totalData.name,
      emailId: totalData.email,
      cancellationReasonId: cancellationReason.find(
        (ele) => ele.value === selectCancellationReason
      ).id,
      cancellationNotes: notes ? notes : null,
      mobileNumberSelectionId: totalData.mobileNumberSelection,
      pacCode: requirePacCode === 1 ? true : false,
    };
    //console.log(finalData, "final data");

    CancellationService.saveOrderCancellationRequest(finalData)
      .then((response) => {
        //console.log("Response:", response);
        if (response.status === 200) {
          setLoader(false);
          if (response.data != null) {
            setRefNumber(response.data);
            dispatch(openModal("cancelSuccess"));
          }
        }
        if (response.status === 201) {
          setLoader(false);
          if (response.data != null) {
            setRefNumber(response.data);
            dispatch(openModal("cancelDirectSuccess"));
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        dispatch(openModal("cancelError"));
        switch (error.response ? error.response.status : "") {
          case 400:
            setLoader(false);
            setModalErrors(error.response.data.failedReason);
            break;
          case 403:
            setLoader(false);
            setModalErrors(error.response.data.failedReason);
            break;
          case 404:
            setLoader(false);
            setModalErrors(error.response.data.failedReason);
            break;
          case 406:
            setLoader(false);
            setModalErrors(error.response.data.failedReason);
            break;
          case 500:
            setLoader(false);
            setModalErrors("Server error occured. Please try again later.");
            break;
          default:
            setLoader(false);
            setModalErrors("Unknown error occured. Please try again later.");
            break;
        }
      });
  };
  useEffect(() => {
    return () => {
      dispatch(closeModal());
    };
  }, []);

  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <h1 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text capitalize mb-4">
          Cancel Your Contract
        </h1>
        <div className="mx-auto max-w-5xl">
          <form className="">
            <fieldset className="border custom-border-50 rounded-lg p-4 w-full mb-4">
              <h2 className="text-lg font-semibold leading-8">
                Please enter all the details to proceed
              </h2>

              <div className="grid md:grid-cols-2 gap-4">
                <div className="mt-4">
                  <div className="flex-col-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6"
                    >
                      Name*
                    </label>
                    <div className="mt-2">
                      <input
                        defaultValue={nameValue}
                        //   // value={localStorage.getItem("name")? localStorage.getItem("name").split(' ')[0] : firstNameValue}
                        //   onChange={(e) => {
                        //     setFirstNameValue(e.target.value);
                        //   }}
                        type="text"
                        id="first-name"
                        placeholder=""
                        className="readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                      />
                    </div>
                    {/* {errors?.firstNameValue && (
                                <p className="error-text">
                                  {errors.firstNameValue}
                                </p>
                              )} */}
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex-col-2">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 "
                    >
                      Email ID*
                    </label>
                    <div className="mt-2">
                      <input
                        defaultValue={emailValue}
                        //   onChange={(e) => {
                        //     setLastNameValue(e.target.value);
                        //   }}
                        placeholder=""
                        type="text"
                        id="last-name"
                        className="readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                      />
                    </div>
                    {/* {errors?.lastNameValue && (
                                <p className="error-text">
                                  {errors.lastNameValue}
                                </p>
                              )} */}
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 "
                  >
                    ICCID*
                  </label>
                  <div className="mt-2">
                    <input
                      defaultValue={iccidValue}
                      // readOnly={isEmailValueReadonly}
                      // onChange={(e) => {
                      //   setEmailValue(e.target.value);
                      // }}
                      id="email"
                      type="email"
                      placeholder=""
                      className={
                        "readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                      }
                    />
                  </div>
                  {/* {errors?.emailValue && (
                              <p className="error-text">
                                {errors.emailValue}
                              </p>
                            )} */}
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="number"
                    className="block text-sm font-medium leading-6 "
                  >
                    Connected Customer Mobile Number (DR)*
                  </label>
                  <div className="mt-2">
                    <input
                      defaultValue={msisdnValue}
                      // onChange={(e) => {
                      //   setContactNoValue(e.target.value);
                      // }}
                      placeholder=""
                      type="text"
                      className="readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                    />
                  </div>
                  {/* {errors?.contactNoValue && (
                              <p className="error-text">
                                {errors.contactNoValue}
                              </p>
                            )} */}
                </div>
                {showExtraField === true ? (
                  <>
                    <div className="mt-4">
                      <label
                        htmlFor="number"
                        className="block text-sm font-medium leading-6 "
                      >
                        Ported in Customer Mobile Number*
                      </label>
                      <div className="mt-2">
                        <input
                          // defaultValue={msisdnValue}
                          value={portedMsisdn}
                          onChange={(e) => {
                            setPortedMsisdn(e.target.value);
                          }}
                          placeholder="Please enter Mobile Number"
                          type="number"
                          className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                        />
                      </div>
                      {errors?.portedMsisdn && (
                        <p className="error-text">{errors.portedMsisdn}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="number"
                        className="block text-sm font-medium leading-6 "
                      >
                        Does the customer require a PAC code*
                      </label>
                      <div className="mt-2">
                        <div className="mt-6 flex items-center space-x-6">
                          <div className="relative flex gap-x-2">
                            <div className="flex h-6 items-center">
                              <input
                                id="requirePacCode"
                                checked={requirePacCode === 1}
                                onChange={() => setRequirePacCode(1)}
                                name="requirePacCode"
                                type="radio"
                                className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label
                                htmlFor="candidates"
                                className="font-medium "
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                          <div className="relative flex gap-x-2">
                            <div className="flex h-6 items-center">
                              <input
                                id="requirePacCode"
                                checked={requirePacCode === 0}
                                onChange={() => setRequirePacCode(0)}
                                name="requirePacCode"
                                type="radio"
                                className="h-4 w-4 rounded custom-border-50 text-teal-600 focus:ring-teal-600 bg-transparent"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium "
                              >
                                No
                              </label>
                            </div>
                          </div>
                          {/*{errors?.stepOne && (
                          <p className="error-text">{errors.stepOne}</p>
                        )} */}
                        </div>
                        {errors?.requirePacCode && (
                          <p className="error-text">{errors.requirePacCode}</p>
                        )}
                      </div>
                      {/* {errors?.contactNoValue && (
                              <p className="error-text">
                                {errors.contactNoValue}
                              </p>
                            )} */}
                    </div>
                  </>
                ) : null}

                <div className="mt-4">
                  <label
                    htmlFor="number"
                    className="block text-sm font-medium leading-6 "
                  >
                    Reason for Cancellation*
                  </label>
                  <div className="mt-2">
                    <CustomSelect
                      value={selectCancellationReason}
                      options={cancellationReason.map((e) => e.value)}
                      placeholder="Select the reason for cancellation"
                      onChange={setSelectCancellationReason}
                    />
                  </div>
                  {errors?.selectCancellationReason && (
                    <p className="error-text">
                      {errors.selectCancellationReason}
                    </p>
                  )}
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="number"
                    className="block text-sm font-medium leading-6 "
                  >
                    Notes
                  </label>
                  <div className="mt-2">
                    <textarea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      className="resize-y rounded-md block w-full border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                    ></textarea>
                  </div>
                  {/* {errors?.contactNoValue && (
                              <p className="error-text">
                                {errors.contactNoValue}
                              </p>
                            )} */}
                </div>
              </div>

              <div className="mt-3 mx-auto w-1/2">
                {/* {modalErrors && <p className="error-text">{modalErrors}</p>} */}
                <LoaderButton
                  type="submit"
                  title="Submit"
                  //   disabled={btnDisableState}
                  handleClick={handleFinalCancellationRequest}
                />
              </div>
            </fieldset>
          </form>
        </div>
      </main>
      <ModalComponent
        size="md"
        position="center"
        title={
          modalInitState.contentId === "cancelSuccess" || modalInitState.contentId === "cancelDirectSuccess"
            ? "Cancellation Request Successful"
            : modalInitState.contentId === "cancelError"
            ? "Error Occurred"
            : "Cancellation Request"
        }
        type={
          modalInitState.contentId === "cancelSuccess" || modalInitState.contentId === "cancelDirectSuccess"
            ? "success"
            : modalInitState.contentId === "cancelError"
            ? "error"
            : "pending"
        }
        bottomLine={true}
        crossButton={
          modalInitState.contentId === "cancelSuccess" || modalInitState.contentId === "cancelDirectSuccess"
            ? false
            : modalInitState.contentId === "cancelError"
            ? true
            : true
        }
      >
        {modalInitState.contentId === "cancelSuccess" ? (
          <ModalSuccess refNumber={refNumber} />
        ) : modalInitState.contentId === "cancelDirectSuccess" ? (
          <ModalDirectSuccess refNumber={refNumber} />
        ) : modalInitState.contentId === "cancelError" ? (
          <ModalError error={modalErrors} />
        ) : null}
      </ModalComponent>
      <Footer />
      {loader && <Loader />}
    </>
  );
};

export default CancellationConfirmation;

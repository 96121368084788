import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import ForgotPasswordService from "../../services/ForgotPasswordService";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../modalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../modalComponent/modalSlice";
import Button from "../button/Button";
import loadingWhiteGif from "../../assets/loading-white.gif";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import LoaderButton from "../button/LoaderButton";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is Required").email("Invalid Email"),
});

const ForgotPassword = () => {
  const [loginEmailId, setLoginEmailId] = useState("");
  const navigate = useNavigate();
  const [forgotPassState, setForgotPassState] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const {id}=useParams();
  const [isCustomer, setIsCustomer] = useState();
  const [login, setLogin] = useState({
    email: "",
    isCustomer: true,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setLogin({
      email: loginEmailId,
      customer: true,
    });
  }, [loginEmailId, true]);

  useEffect(() => {
if(id === "individual"){
setIsCustomer(true);
}else{
  setIsCustomer(false);
}
  }, []);

  const forgotPasswordApi = (email) => {
    setForgotPassState(true);
    ForgotPasswordService.forgotPassword({ email, customer: isCustomer })
      .then((response) => {
        if (response.data.status === "Success") {
          setForgotPassState(false);
          dispatch(openModal());
        }
      })
      .catch((error) => {
        setForgotPassState(false);
        if (error.message === "Network Error") {
          setErrorMsg("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              //console.log("error.response", error.response);
              setErrorMsg(error.response.data.failedReason);
              // setErrorCode(401)
              break;
            case 403:
              //console.log("error.response1111", error.response);
              setErrorMsg(error.response.data.failedReason);
              break;
            case 500:
              //console.log("error.response", error.response);
              setErrorMsg(error.response.data.failedReason);
              break;
            case 404:
              //console.log("error.response", error.response);
              setErrorMsg(error.response.data.failedReason);
              break;
            case 406:
              //console.log("error.response", error.response);
              setErrorMsg(error.response.data.failedReason);
              break;
            case 412:
              //console.log("error.response", error.response);
              setErrorMsg(error.response.data.failedReason);
              break;
            default:
              setErrorMsg("Unknown Error");
              setErrorMsg(error.response.data.failedReason);
              break;
          }
        }
      });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-20 lg:px-8">
      <div className="mx-auto w-full max-w-sm md:max-w-md">
        <Link to="/">
          <img className="mx-auto h-[60px] w-auto" src={logo} alt="GKT" />
        </Link>
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text">
            Forgot Password
          </h2>
          <p className="text-lg text-center font-medium mt-4">
            Enter your registered email id to reset password
          </p>
        </div>
      </div>
      <div className="mt-4 mx-auto w-full max-w-sm md:max-w-md">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            forgotPasswordApi(values.email);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form id="forgot-password-form" className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-normal leading-6"
                >
                  Email address*
                </label>
                <div className="mt-2">
                  <Field
                    name="email"
                    type="email"
                    placeholder="Enter your e-mail address"
                    className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-text"
                  />
                </div>
              </div>
              {errorMsg && (
                <div className="error-text text-center">
                  {errorMsg}
                </div>
              )}
              <div>
                <LoaderButton
                type="submit"
                title="Reset Password"
                disabled={forgotPassState}
                />
                {/* <button
                  type="submit"
                  className={`flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 ${forgotPassState ? '': 'btn-disabled'}`}
                >
                  {forgotPassState ? (
                    "Reset Password"
                  ) : (
                    <img
                      width={24}
                      height={24}
                      src={loadingWhiteGif}
                      alt="Loading..."
                    />
                  )}
                </button> */}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <p className="mt-6 text-center text-sm ">
        <span className="text-gray-500">Already have an Account? </span>
        <Link
          to="/login"
          className="font-semibold hover:underline decoration-1 primary-text"
        >
          Login
        </Link>
      </p>
      <ModalComponent
        size="md"
        type="success"
        position="center"
        title="Password Reset Email Sent"
        description=""
        bottomLine={true}
      >
        <div>
          <p>
            An email with instructions to reset your password has been
            successfully sent to your registered email address.
            <br />
            Please proceed with the "Reset Button"
          </p>
          <Button
            handleClick={() => {
              navigate("/");
              dispatch(closeModal());
            }}
            title="Okay"
            type="button"
            style="cta-two w-1/2 mt-4 flex justify-center mx-auto"

          />
        </div>
      </ModalComponent>
      {/* {loader && <Loader />}  */}
    </div>
  );
};

export default ForgotPassword;

import React from "react";
import Footer from "../footer/Footer";
import useScrollToTop from "../customHooks/useScrollToTop";
import Navbar from "../navbar/Navbar";

const ThreeTnC = () => {
  // useScrollToTop()
  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <section className="mx-auto max-w-5xl">
          <>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "27pt",
                marginRight: "30.45pt",
                textAlign: "center",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Terms and Conditions for using the Three Network
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 92,
                  height: 118,
                }}
              >
                <img
                  src="https://www.three.co.uk/content/experience-fragments/threedigital/uk/en/site/header/master/_jcr_content/root/header/top/logo.coreimg.svg/1668177162294/three-logo.svg"
                  width={92}
                  height={118}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "16.1pt",
                marginRight: "30.45pt",
                textAlign: "center",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                for Pay Monthly customers who joined or upgraded on or after 1st
                November 2022.
              </span>
            </h2>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.8pt",
                marginRight: "37.75pt",
                marginTop: "7.75pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                If you’d like a copy of these Terms or other communications
                (other than marketing communications) related to your services
                in an alternative format (e.g. Braille or large print) please
                contact Three Customer Services on 333 from your Three Phone or
                0333 338 1001 from a non-Three phone. For more information on
                Three's accessibility services please see
                Three.co.uk/accessibility
              </span>
            </p>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginRight: "30.45pt",
                textAlign: "center",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Quick Summary
              </span>
            </h2>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.8pt",
                marginRight: "37.75pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Here's a quick summary of some of the key points that we'd like
                you to be aware of. The full terms of your agreement with Three
                are below - it's important that you read and understand them
                before you sign up to Three and start using Three Services. At
                the end of these Terms we've set out definitions of capitalised
                words used in these Terms. If there is any inconsistency between
                this summary, and the full terms set out below, the full terms
                will apply.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.8pt",
                marginRight: "37.75pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Your Minimum Commitment:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                You agree to stay with us for the Minimum Term. The minimum
                price for Three Services provided under this agreement is the
                Monthly Charge for the Minimum Term.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.1849999999999998",
                marginLeft: "5.8pt",
                marginRight: "37.75pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Annual Price Increase:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Each April, your Monthly Charge will increase by a fixed amount
                depending on your plan’s data allowance. Plans 4GB or less &amp;
                Smartwatch Pairing Plans will increase by £1.00 per month. Plans
                from 5GB to 99GB will increase by £1.25 per month. Plans 100GB
                or over will increase by £1.50 per month. All Home Broadband
                plans will increase by £2.00 per month. We’ve shown an example
                in the following table of how increase will work.
              </span>
            </p>
            <p>
              <br />
            </p>
            <div dir="ltr" style={{ marginLeft: "7.45pt" }} align="left">
              <table style={{ border: "none", borderCollapse: "collapse" }}>
                <tbody>
                  <tr style={{ height: "23.9pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 1.5pt",
                        borderRight: "solid #000000 1pt",
                        borderBottom: "solid #000000 1pt",
                        borderTop: "solid #000000 1pt",
                        verticalAlign: "top",
                        backgroundColor: "#edebe0",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "2.3pt",
                          marginTop: "2.6pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Monthly Price until March 2025
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 1pt",
                        borderRight: "solid #000000 1pt",
                        borderBottom: "solid #000000 1pt",
                        borderTop: "solid #000000 1pt",
                        verticalAlign: "top",
                        backgroundColor: "#edebe0",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.1099999999999999",
                          marginLeft: "2.65pt",
                          marginTop: "0.7pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Monthly Price from April 2025 to March 2026
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 1pt",
                        borderRight: "solid #000000 1.5pt",
                        borderBottom: "solid #000000 1pt",
                        borderTop: "solid #000000 1pt",
                        verticalAlign: "top",
                        backgroundColor: "#edebe0",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "2.3pt",
                          marginTop: "1.3pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Monthly Price from April&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          .
                        </span>
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          2026 to
                        </span>
                      </p>
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "0.9599999999999999",
                          marginLeft: "2.3pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          March 2027
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "24.25pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 1.5pt",
                        borderRight: "solid #000000 1pt",
                        borderBottom: "solid #000000 1pt",
                        borderTop: "solid #000000 1pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "3.3pt",
                          marginTop: "4.9pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Price A
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 1pt",
                        borderRight: "solid #000000 1pt",
                        borderBottom: "solid #000000 1pt",
                        borderTop: "solid #000000 1pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.35pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Price A + price increase as set out above (= Price B)
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 1pt",
                        borderRight: "solid #000000 1pt",
                        borderBottom: "solid #000000 1pt",
                        borderTop: "solid #000000 1pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.3pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Price B + price increase as set out above (= Price C)
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "35.75pt" }}>
                    <td
                      colSpan={3}
                      style={{
                        borderLeft: "solid #000000 1.5pt",
                        borderRight: "solid #000000 1.5pt",
                        borderBottom: "solid #000000 1.5pt",
                        borderTop: "solid #000000 1pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "2.45pt",
                          marginTop: "0.2pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          We've set out an example below showing how this would
                          work if your Monthly Charge is £30 and your data
                          allowance is 4GB on a Pay Monthly SIMO plan meaning
                          that your Monthly Charge will increase each April by
                        </span>
                      </p>
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.15",
                          marginLeft: "2.45pt",
                          marginTop: "0.1pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          £1 per month.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "22.25pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 1pt",
                        borderRight: "solid #000000 1pt",
                        borderBottom: "solid #000000 1pt",
                        borderTop: "solid #000000 1.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "2.55pt",
                          marginTop: "8pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          £30.00
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 1pt",
                        borderRight: "solid #000000 1pt",
                        borderBottom: "solid #000000 1pt",
                        borderTop: "solid #000000 1.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.35pt",
                          marginTop: "8.35pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          £30 + £1 = £31
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 1pt",
                        borderRight: "solid #000000 1pt",
                        borderBottom: "solid #000000 1pt",
                        borderTop: "solid #000000 1.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "8.55pt",
                          marginTop: "8.6pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          £31 + £1 = £32
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.9pt",
                marginTop: "8pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To find our more about Price Increases, please visit our
                dedicated page
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.16",
                marginLeft: "5.9pt",
                marginRight: "20.2pt",
                marginTop: "11pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Other Changes:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                If we make any changes to the Charges, the Three Services,
                Outside of Allowance Servicesor the Additional Services and
                those changes give you a right to terminate your Agreement we
                will notify youat least 30 days in advance of such changes being
                made. We may also make changes or amend pricing inrespect of
                Additional Services which will not give you a right to terminate
                this Agreement and those changes
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.105",
                marginLeft: "6pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                will be published on our website. See Section 4 and 10 below.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginRight: "20.2pt",
                marginTop: "11.45pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Our Network:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                We'll try to provide you with a great quality Three Network at
                all times within our coveragearea. However, due to the nature of
                mobile technology, there may be times when Three Services aren't
                continuously available, or the quality is affected. Problems can
                happen if we're carrying out maintenance work, if you're outside
                our coverage area, or if you're in a tunnel for example.
                Throughout 2023 and 2024, we will be making changes to and
                upgrading the Three Network. Following some of these changes,
                you will need a 4G or 5G handset capable of making voice calls
                on the 4G network, or a 4G or 5G router to access the Three
                Services. 3G-only devices, and early 4G handsets that can only
                make 3G voice calls, will no longer work on the Three Network,
                in areas where planned work is carried out. For more information
                about coverage, go to Three.co.uk/coverage. See section 5 below.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginRight: "20.2pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Breaking this Agreement:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                If you don't pay your account on time or we reasonably believe
                that you haven't complied with certain terms of your agreement,
                we may suspend or disconnect Three Services, but you still must
                pay all outstanding Charges (including a Cancellation Fee for
                disconnection). See Sections 9 and 10.3 below.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginRight: "20.2pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Ending this Agreement:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                How you can end your agreement depends on whether you're in your
                Minimum Term or not, and whether we've made any changes to your
                agreement that are likely to impact the Three Services you
                receive. [Please see the below Table 1 for a summary of how and
                when you can end youragreement. The Cancellation Fee will be the
                total of the Monthly Charges remaining during the Minimum Term
                of your agreement less a variable discount, currently 3%. We
                reserve the right to vary the amount of the percentage discount
                from time to time.
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "11pt",
                marginRight: "15.75pt",
                textAlign: "justify",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Your Information:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                You agree that we can process your information which we collect
                and / or which you give to us during any sales or registration
                process, for a number of purposes, including, but not limited
                to, opening and managing an account for Three Services, to
                deliver products and services ordered by you, for credit
                checking and fraud prevention,and for product analysis and
                direct marketing (subject to your preferences).
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "11pt",
                textAlign: "justify",
                marginTop: "0.1pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                See Section 13 below.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "11pt",
                textAlign: "justify",
                marginTop: "11.4pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Table 1
              </span>
            </p>
            <p>
              <br />
            </p>
            <div dir="ltr" style={{ marginLeft: "23.95pt" }} align="left">
              <table style={{ border: "none", borderCollapse: "collapse" }}>
                <tbody>
                  <tr style={{ height: "36.9pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        backgroundColor: "#ecebdf",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.2pt",
                          marginRight: "1.7pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          When you wish to end your agreement
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        backgroundColor: "#ecebdf",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.135",
                          marginLeft: "0.1pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Notice Period
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        backgroundColor: "#ecebdf",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.135",
                          marginLeft: "0.05pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          What to Do?
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        backgroundColor: "#ecebdf",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.135",
                          marginLeft: "0.15pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Charges Payable
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "149.5pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        backgroundColor: "#ecebdf",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.2pt",
                          marginRight: "1.7pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          During the Returns Period (please note, if you buy
                          from a Three Retail store, unless you have purchased
                          Home Broadband, you cannot return your device if you
                          change your mind)
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.1pt",
                          marginRight: "5.25pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Your agreement will end when you notify us, or if you
                          have a Device to return to us, when we receive that
                          Device in in accordance with the Returns Policy
                          (whichever is
                        </span>
                      </p>
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.0550000000000002",
                          marginLeft: "0.1pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          later)
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.05pt",
                          marginRight: "0.6pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Contact Three Customer Services or choose to
                          switch&nbsp;
                        </span>
                        <a
                          href="https://www.three.co.uk/support/switching/leaving-three"
                          style={{ textDecoration: "none" }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              fontFamily: "Arial,sans-serif",
                              color: "#1f477b",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "underline",
                              WebkitTextDecorationSkip: "none",
                              textDecorationSkipInk: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Leave Three - Three
                          </span>
                        </a>
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",
                            color: "#1f477b",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          &nbsp;
                        </span>
                        <a
                          href="https://www.three.co.uk/support/switching/leaving-three"
                          style={{ textDecoration: "none" }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              fontFamily: "Arial,sans-serif",
                              color: "#1f477b",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "underline",
                              WebkitTextDecorationSkip: "none",
                              textDecorationSkipInk: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Customer PAC or STAC
                          </span>
                        </a>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.15pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Charges for Three Services used.
                        </span>
                      </p>
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.15pt",
                          marginRight: "3.05pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Charges for damage or for any loss of value to the
                          goods, as a result of nonessential use, in accordance
                          with the Returns Policy.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "51.45pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        backgroundColor: "#ecebdf",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.2pt",
                          marginRight: "1.7pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          During your Minimum Term (but outside of any Returns
                          Period)
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.1pt",
                          marginRight: "5.25pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You can end your agreement at any time, on 30 days’
                          notice.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.05pt",
                          marginRight: "0.6pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Contact Three Customer Services or choose to
                          switch&nbsp;
                        </span>
                        <a
                          href="https://www.three.co.uk/support/switching/leaving-three"
                          style={{ textDecoration: "none" }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              fontFamily: "Arial,sans-serif",
                              color: "#1f477b",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "underline",
                              WebkitTextDecorationSkip: "none",
                              textDecorationSkipInk: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Leave Three - Three
                          </span>
                        </a>
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",
                            color: "#1f477b",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          &nbsp;
                        </span>
                        <a
                          href="https://www.three.co.uk/support/switching/leaving-three"
                          style={{ textDecoration: "none" }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              fontFamily: "Arial,sans-serif",
                              color: "#1f477b",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "underline",
                              WebkitTextDecorationSkip: "none",
                              textDecorationSkipInk: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Customer PAC or STAC
                          </span>
                        </a>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.15pt",
                          marginRight: "3.05pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          All outstanding Charges payable plus your Cancellation
                          Fee.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "49.65pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        backgroundColor: "#ecebdf",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.2pt",
                          marginRight: "1.7pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Outside of your Minimum Term/if you have no Minimum
                          Term
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.1pt",
                          marginRight: "5.25pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You can end your agreement at any time, on 30 days’
                          notice.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.05pt",
                          marginRight: "0.6pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Contact Three Customer Services or choose to
                          switch&nbsp;
                        </span>
                        <a
                          href="https://www.three.co.uk/support/switching/leaving-three"
                          style={{ textDecoration: "none" }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              fontFamily: "Arial,sans-serif",
                              color: "#1f477b",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "underline",
                              WebkitTextDecorationSkip: "none",
                              textDecorationSkipInk: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Leave Three - Three
                          </span>
                        </a>
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",
                            color: "#1f477b",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          &nbsp;
                        </span>
                        <a
                          href="https://www.three.co.uk/support/switching/leaving-three"
                          style={{ textDecoration: "none" }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              fontFamily: "Arial,sans-serif",
                              color: "#1f477b",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "underline",
                              WebkitTextDecorationSkip: "none",
                              textDecorationSkipInk: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Customer PAC or STAC
                          </span>
                        </a>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.15pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          All outstanding Charges payable
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "72.45pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        backgroundColor: "#ecebdf",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.2pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          We notify you that we are making a change to the Three
                          Services you receive, and that if such changes are not
                          acceptable to you, you may terminate your Agreement.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.1pt",
                          marginRight: "2.65pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You can end your agreement in accordance with the
                          terms of the notice we send you.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.05pt",
                          marginRight: "0.6pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Contact Three Customer Services or choose to
                          switch&nbsp;
                        </span>
                        <a
                          href="https://www.three.co.uk/support/switching/leaving-three"
                          style={{ textDecoration: "none" }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              fontFamily: "Arial,sans-serif",
                              color: "#1f477b",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "underline",
                              WebkitTextDecorationSkip: "none",
                              textDecorationSkipInk: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Leave Three - Three
                          </span>
                        </a>
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",
                            color: "#1f477b",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          &nbsp;
                        </span>
                        <a
                          href="https://www.three.co.uk/support/switching/leaving-three"
                          style={{ textDecoration: "none" }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              fontFamily: "Arial,sans-serif",
                              color: "#1f477b",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "underline",
                              WebkitTextDecorationSkip: "none",
                              textDecorationSkipInk: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Customer PAC or STAC
                          </span>
                        </a>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "0.15pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          All outstanding Charges payable
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <br />
            </p>
            <p>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "3.45pt",
                textAlign: "center",
                marginTop: "8.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Your Pay Monthly Terms
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Who’s who and what’s what
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    When we say:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "4.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    ‘we', ‘us' or ‘our', we mean Hutchison 3G UK Limited,
                    trading as ‘Three', (company number 03885486), with its
                    registered address at 450 Longwater Avenue, Green Park,
                    Reading, Berkshire, RG2 6GF;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "22.4pt",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    ‘you' or ‘your', we mean you, our customer whose name
                    appears on the Welcome Letter;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "5.25pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    ‘agreement', we mean your agreement with us for the supply
                    of Three Services. Section 2.1 explains what terms make up
                    your agreement.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We've set out at the end of these Terms definitions of the
                    capitalised words we use in these Terms.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    About your agreement
                  </span>
                </h2>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Your agreement is made up of these Terms and your Package
                    (as set out in our Price Guide), along with any other terms
                    laid down in additional Three Services. Additional terms may
                    apply to any promotional or special offers.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Your agreement is personal to you. Unless we give you
                    permission, you can't pass your rights or responsibilities
                    to anyone else - even if we give you more than one SIM or
                    eSIM Profile or you give your Device to others. It's your
                    responsibility to make sure the SIMs or eSIM Profiles are
                    only used to access Three Services as allowed under this
                    agreement.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    This agreement doesn't cover:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "13.9pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    any products or services you buy while using Three Services;
                    or
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.65pt",
                  paddingLeft: "17.4pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    the supply of your Device.
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.95pt",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                3. Your Pay Monthly Terms
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                3.1
              </span>
            </p>
            <p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.95pt",
                  marginTop: "3.95pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Arial,sans-serif",

                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  1 Who’s who and what’s what
                </span>
              </p>
              <br />
              <ol
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",
                  paddingLeft: 30,
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <li>
                  1.1 When we say: (a) ‘we', ‘us' or ‘our', we mean Hutchison 3G
                  UK Limited, trading as ‘Three', (company number 03885486),
                  with its registered address at 450 Longwater Avenue, Green
                  Park, Reading, Berkshire, RG2 6GF; supply of Three Services.
                  Section 2.1 explains what terms make up your agreement.
                </li>
                <li>
                  1.2 We've set out at the end of these Terms definitions of the
                  capitalised words we use in these Terms.
                </li>
              </ol>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                3.2
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                3.3
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                4
              </span>
            </h2>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.95pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                4.1
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                4.2
              </span>
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.75pt",
                marginTop: "4.6pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                When your agreement starts
              </span>
            </h2>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.75pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Your agreement for the provision of Three Services, starts when
                we Connect you to Three. By inserting your SIM or by scanning or
                otherwise entering the activation code from your eSIM QR Code
                you're expressly requesting that we provide you with the Three
                Services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.75pt",
                marginRight: "0.45pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                For existing customers who have upgraded with us by signing up
                to a new agreement for a new Minimum Term, your new agreement
                will start on the day we accept your new request for Three
                Services, unless we agree an alternative date with you.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.75pt",
                marginRight: "12.45pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                If you signed up to your new agreement through Three.co.uk or
                Three Telesales or Three Customer Services or if you have
                purchased Three Home Broadband through a Three retail store you
                may cancel your agreement within the Returns Period. If you use
                Three Services before you cancel you will be charged for them.
                Please remember that it can take up to 3 months for some
                international and Premium Services to be applied to your bill.
                Please see our Returns Policy at Three.co.uk/Returns for more
                information.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.75pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                If your Package has a Minimum Term, you agree to
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginRight: "5.55pt",
                textIndent: "0.5999999999999996pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                remain Connected to Three for that Minimum Term. You have
                limited rights to end the agreement during the Minimum Term as
                set
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.95pt",
                marginRight: "5.55pt",
                textIndent: "0.09999999999999964pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                out in Section 10. If your Package doesn't have a Minimum Term,
                or your Minimum Term has ended, we'll supply you with Three
                Services until either of us chooses to end the agreement in any
                of the ways set out in Section 10.
              </span>
            </p>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.75pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Changes to your agreement or prices
              </span>
            </h2>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.16",
                marginLeft: "6.75pt",
                marginRight: "5.55pt",
                marginTop: "11pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Each April, your Monthly Charge will increase by a fixed amount
                depending on your plan’s data allowance. Plans 4GB or less &amp;
                Smartwatch Pairing Plans will increase by £1.00 per month. Plans
                from 5GB to 99GB will increase by £1.25 per month. Plans 100GB
                or over will increase by £1.50 per month. All Home Broadband
                plans will increase by
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.16",
                marginLeft: "6.75pt",
                marginRight: "5.55pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                £2.00 per month. The annual increase will first appear on your
                April bill. An example of how this may impact you is set out in
                the Quick Summary of your Agreement above’
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.75pt",
                marginRight: "0.45pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                In addition to Section 4.1, we may change any of the terms of
                your agreement, including our Packages or Charges, as follows:
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "10.2pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.16",
                    marginRight: "51.55pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we may discontinue or amend your Package or the Three
                    Services in which case we will let you know no less than 30
                    days in advance (unless otherwise
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we may need to change or introduce new Charges in respect of
                    our Outside of Allowance Services, or Ancillary Services;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we may change or introduce new Charges in respect of
                    Additional Services or Add-Ons and will publish any changes
                    on the Three website. If any Add-ons affected have a
                    recurring Charge, we'll let you know at least fourteen (14)
                    days before the Charge changes;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    where permitted or required to comply with law or
                    regulation;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    as a result of any changes to services or increases in
                    charges imposed on us by third parties e.g. changes to
                    roaming costs or international call costs, where such
                    changes are related to the costs/price increases charged to
                    us by wholesale partners or providers; and/or
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={6}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.35pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    where a change is, in our reasonable determination, required
                    to maintain or improve the quality or security of the Three
                    Services.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Other than in respect of:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    changes made in accordance with Section&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.30j0zll"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      4.1;
                    </span>
                  </a>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    changes under Section&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.1fob9te"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      4.2(e)
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;(unless you are materially disadvantaged by such
                    changes and we notify you in accordance with Section&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.2et92p0"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      4.4
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    );
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.95pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    changes to Ancillary Services which you have not opted to
                    receive; or
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.1pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    changes to an Additional Service or Add-on which is offered
                    as an optional service for a minimum term of no more than 30
                    days;
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "42pt",
                marginRight: "2.85pt",
                textAlign: "justify",
                marginTop: "4.15pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                where any proposed changes to the Agreement impact the Three
                Services you receive from us are not:
              </span>
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.25pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "3.95pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    exclusively for your benefit;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.15pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    of a purely administrative nature that has no negative
                    effect on you; or
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.25pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    directly imposed by law e.g. changes to VAT;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "42.1pt",
                marginRight: "33.65pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                we will notify you of such proposed changes at least 30 days in
                advance of the proposed changes taking place.
              </span>
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.9pt",
                    textAlign: "justify",
                    marginTop: "11.35pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Where we make changes under Section&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.1fob9te"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      4.2(e)
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;which, in our reasonable determination are likely to
                    materially disadvantage you, we will notify you of such
                    proposed changes at least 30 days in advance of such
                    proposed changes taking place (which shall be no more
                    frequently than once per month).
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.8pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Subject to Section&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.3dy6vkm"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      7
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;below, where we have provided you with notice under
                    Section&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.3znysh7"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      4.3
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;or&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.2et92p0"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      4.4
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;above, you can, following receipt ofany such notice
                    and if such proposed changes are not acceptable to you,
                    terminate your Agreement by giving notice to Three Customer
                    Services or chose to switch providers within that 30 day
                    notice period.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={6}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you carry on using Three Services after the 30 day notice
                    period provided in accordance with Section&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.3znysh7"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      4.3
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;or&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.2et92p0"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      4.4,
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;you will be deemed to have accepted the change, and
                    will not be able to subsequently terminate the Agreement.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={7}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.75pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you terminate the Agreement in accordance with Section
                    4.5 during the 30 day notice period provided by Three, no
                    Cancellation Fee will be applicable to such termination by
                    you. If you terminate the Agreement for any other reason
                    during the Minimum Term, a Cancellation Fee may be payable
                    (as detailed in Section&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.4d34og8"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      11.2
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    ).
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    What we’ll provide to you
                  </span>
                </h2>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                A Three Phone number and SIM or eSIM Profile
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.55pt",
                    textAlign: "justify",
                    marginTop: "4.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We'll open an account for you and provide you with a SIM or
                    eSIM Profile and a Three phone number.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.1pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We own each SIM and eSIM Profile, which remain our property
                    at all times. You're being allowed to use the SIM or eSIM
                    Profile by us on a limited licence to allow you to access
                    Three Services.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.1pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Each SIM or eSIM Profile can only be used in Devices which
                    are authorised by us for Connection to the Three Network. If
                    you try to use the SIM in another device, it may damage the
                    device and affect your usage (including making emergency
                    calls). In these instances, we're not responsible for any
                    such damage or usage problems.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You can move your existing phone number to Three. First, you
                    need to ask your previous mobile network operator for your
                    PAC, and then you will need to give your PAC to us. Once
                    we've checked the details of your request, we'll confirm the
                    date when your phone number will be moved to Three. If the
                    move is delayed and the delay is our fault, you will receive
                    compensation on your Three account within 30 days. For
                    anything else, just get in touch at three.co.uk/contact_us.
                    Guidance on our switching process is available on our
                    support pages: three.co.uk/support/switching.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You can move your Three phone number to another mobile
                    network operator. First, you need to ask us for your PAC.
                    Once we've checked the details of your request, we'll give
                    you your PAC which must be used within the time specified.
                    Your Three Services will be discontinued once your number
                    has been successfully transferred. If you move your Three
                    phone number to another mobile network operator within your
                    Minimum Term (if you have one), you must pay us all the
                    Charges you owe, plus any Cancellation Fee for your Package.
                    Guidance on our switching process is available on our
                    support pages:three.co.uk/support/switching.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Three Services
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={6}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.25pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Once you're Connected to Three we'll provide you with access
                    to Three Services.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={7}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You may also be able to upload and send your own content
                    using Three Services. You grant us an irrevocable, royalty
                    free, perpetual and worldwide licence to store, transmit or
                    otherwise deal with any content you upload on Three
                    Services.
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={8}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We:
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
                <ol
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  <li
                    dir="ltr"
                    style={{
                      listStyleType: "lower-alpha",
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "8.600000000000009pt",
                      paddingLeft: "17.299999999999997pt",
                    }}
                    aria-level={3}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginRight: "407.4pt",
                        textAlign: "justify",
                        marginTop: "3.95pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Arial,sans-serif",

                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        may change or withdraw some, or part, of Three Services
                        from time to time. This may be because of changing
                        technologies, obsolescence, new or different product
                        features, changing content providersor the need to
                        remove, replace or change content. Depending on the
                        changes that we make, you may have a right to end this
                        agreement, as explained in Section 4.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.60000000000001pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "409.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    may also determine how Three Services are presented and
                    delivered to the Device or are otherwise made available to
                    you. We can change the way they are presented, delivered or
                    otherwise made available to you at any time;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.60000000000001pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "412.25pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    may deploy traffic management measures, known collectively
                    as TrafficSense™, across the Three Network to protect the
                    network and to ensure an enjoyable internet experience for
                    the vast majority of our customers. For details see
                    Three.co.uk/trafficsense. We reserve the right to review and
                    amend any such measures from time to time.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={9}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.149999999999999pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Smartwatch Pairing
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.60000000000001pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "404.75pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    These Terms also apply to any Smartwatch Pairing Plan which
                    may be part of your Package and included in the Monthly
                    Charge or added by you as an Additional Service.
                    Smartwatches can only be paired with compatible Phones which
                    are listed on the manufacturer’s website. The Smartwatch
                    Pairing Plan does not include any calls, texts or data so
                    your Secondary Device must be paired with your Primary
                    Service to enable the Secondary Device to share the
                    allowances included in your Package. Using your Secondary
                    Device will deplete the allowances in your Package. If you
                    exhaust the allowances included in your Package, you will be
                    charged for any Outside of Allowance Services you use at the
                    rates set out in the Price Guide. You need to have an active
                    Primary Service for the duration of the Smartwatch Pairing
                    Plan
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "98.05pt",
                marginRight: "2.05pt",
                textAlign: "justify",
                marginTop: "8.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                to be able to share allowances with your Secondary Device. If
                you cancel your Primary Service you will not be able to use data
                or make or receive calls or texts on your Secondary Device
                unless you pair your Secondary Device with a different Three
                Primary Service. You will need to be in a 3G or 4G coverage area
                to be able to use Smartwatch Pairing to connect to the internet
                from your Smartwatch. You cannot use the Smartwatch Pairing Plan
                to share the allowances from your Primary Plan in destinations
                outside the UK, although you can still use the functionality of
                your Smartwatch over Bluetooth or WIFI.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={10}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Access to the Emergency Services
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You can make free calls to emergency services from your
                    Phone in the UK by calling 999 or 112. When you're outside
                    of Three's coverage area in the UK, your Phone will try to
                    locate another mobile network so that you can try to contact
                    the emergency services (however, neither your mobile phone
                    number nor your Location Data will be transmitted to the
                    emergency services in these circumstances). If you need to
                    contact the emergency services while you're roaming abroad,
                    you will need to call 112 (this number is recognised by most
                    mobile operators worldwide) or the local emergency services
                    number if different (however, neither your phone number nor
                    your Location Data will be transmitted to the emergency
                    services in these circumstances). Emergency service calls
                    can't be made using Skype (or certain other voice over IP
                    services) on your Phone. If you want to call the emergency
                    services, you will need to make a normal voice call from
                    your Phone.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you want to call the emergency services with our WiFi
                    Calling service, please note that your call may be
                    interrupted or end if you have a power cut or your internet
                    connection fails. If you are having problems connecting with
                    WiFi you may wish to use a mobile or fixed network
                    connection (instead of WiFi) and/or register (and keep us up
                    to date) on the address where
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "98pt",
                marginRight: "32.9pt",
                textAlign: "justify",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                you plan to use our WiFi calling services (so we have your
                latest location information to hand for emergency organisations
                in case of an emergency). You may also be asked to confirm or
                provide your location when making an emergency call (to help
                emergency organisations identify the services you need). Please
                also note that emergency service calls can’t be made using Skype
                on your Phone. For instructions on how to make emergency calls
                from your Smartwatch when using Smartwatch Pairing, please see
                the manufacturer’s instructions for your Smartwatch.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you have difficulties hearing or are speech impaired and
                    you need emergency assistance, you can send a text message
                    with details of your location to 999 or
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "98pt",
                marginRight: "32.85pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                112. The text will be converted and passed to the appropriate
                emergency service but you will need to register your Phone
                before you can use this service. Details on how to do this are
                available a
              </span>
              <a
                href="http://www.emergencysms.org.uk/"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Arial,sans-serif",
                    color: "#0000ff",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  t
                </span>
              </a>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",
                  color: "#0000ff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
              <a
                href="http://www.emergencysms.org.uk/"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Arial,sans-serif",
                    color: "#0000ff",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  www.emergencysms.org.uk
                </span>
              </a>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.65pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you have a Device, other than a phone, such as a Tablet,
                    which is capable of making telephone calls, you may be able
                    to use this to make free calls to emergency services in the
                    UK by calling 999 or 112, however your Location Data may not
                    be transmitted to the emergency services in these
                    circumstances.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you have a Device that operates on Android operating
                    system 2.3 and above, on calling 999 or 112 while in the UK
                    and connected via the Three Network, your Location Data may
                    be automatically transmitted to the emergency services to
                    help them locate you in an emergency.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={11}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Coverage and network speeds
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We'll always try to make Three Services available to you.
                    However, there may be areas where you don't have access to
                    all Three Services or where coverage is otherwise limited or
                    unavailable or network speeds are
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "98.05pt",
                marginRight: "4.25pt",
                marginTop: "8.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                slower than expected.
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Throughout 2023 and 2024, we will be making changes to and
                upgrading the Three Network. Following some of these changes,
                you will need a 4G or 5G handset capable of making voice calls
                on the 4G network, or a 4G or 5G router to access the Three
                Services. 3G-only devices, and early 4G handsets that can only
                make 3G voice calls, will no longer work on the Three Network,
                in areas where planned work is carried out. For more information
                about coverage, go to Three.co.uk/coverage.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The data speeds that you'll experience on our network will
                    vary due to a number of factors including your distance from
                    the nearest mast, your location in a building, local
                    geography, congestion and the type of equipment that you're
                    using- for example, you'll only be able to enjoy 4G speeds
                    if you're in a 4G coverage area and using a 4G device.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={12}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Broadband speeds
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.25pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Our advertised and estimated maximum upload and download
                    speeds for our broadband internet services, provided as part
                    of the Three Services, are set out in our estimated
                    broadband speeds table, available at:&nbsp;
                  </span>
                  <a
                    href="http://www.three.co.uk/broadband-speeds"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",
                        color: "#0000ff",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      www.three.co.uk/broadband-
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",
                      color: "#0000ff",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;
                  </span>
                  <a
                    href="http://www.three.co.uk/broadband-speeds"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",
                        color: "#0000ff",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      speeds
                    </span>
                  </a>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We do not guarantee that our broadband service will achieve
                    any specific speeds. The speeds achieved by our broadband
                    service will depend on factors such as your geographic
                    location, the type of walls and windows in your building,
                    the number of people using the network, the external
                    environment, and the correct setup of your hub.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.60000000000001pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "5.95pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may implement measures to manage the traffic across our
                    network in exceptional circumstances to prevent impending
                    network congestion and/or mitigation the effects of any
                    exceptional or temporary network congestion. We may also
                    implement traffic management measures in order to meet our
                    legal
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "98pt",
                marginRight: "32.55pt",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                obligations, preserve the integrity and security of our
                networks, services, or terminal equipment, or to provide you
                with any particular services you have requested.
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                For
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                more
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                information
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                on
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                traffic management
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                on
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                our network, please visit:&nbsp;
              </span>
              <a
                href="http://support.three.co.uk/mobiledocs/Support/Signal_and_coverage/our_network/TrafficSense_facts_document.pdf"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Arial,sans-serif",
                    color: "#0000ff",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  http://support.three.co.uk/mobiledocs/Support/Sig
                </span>
              </a>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial,sans-serif",
                  color: "#0000ff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
              <a
                href="http://support.three.co.uk/mobiledocs/Support/Signal_and_coverage/our_network/TrafficSense_facts_document.pdf"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Arial,sans-serif",
                    color: "#0000ff",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  nal_and_coverage/our_network/Tr
                </span>
              </a>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial,sans-serif",
                  color: "#0000ff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
              <a
                href="http://support.three.co.uk/mobiledocs/Support/Signal_and_coverage/our_network/TrafficSense_facts_document.pdf"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Arial,sans-serif",
                    color: "#0000ff",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  afficSense_facts_document.pdf
                </span>
              </a>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.65pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The variability of the download and upload speeds achieved,
                    and the technology used to access the broadband services,
                    may affect your experience of our services and your ability
                    to access and distribute information and content, and use
                    and provide applications and services.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.9pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    In the event that you experience continuous or regularly
                    recurring disruption to the broadband services you may be
                    entitled to a price reduction based upon the period of the
                    disruption, in accordance with Section 5.13.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={13}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Disruption to Three Services
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.6pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Due to the nature of mobile technology, there may be
                    situations when Three Services aren't always available, or
                    the quality or network speeds are affected and so we cannot
                    guarantee continuous fault-free service. For example:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.8pt",
                marginRight: "33pt",
                textIndent: "1.7999999999999972pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                when we need to perform upgrading, maintenance or other work on
                the Three Network or Three Services;
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 11,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAaCAYAAACzdqxAAAAAAXNSR0IArs4c6QAAAkhJREFUSEu11kuIjnEUx/HPNK6N+y2kCSElyYKNWJCtsiHlWnJJpLBRbLBiI5JLZCxkyV6MUgqLKVJIktwiuYTBuPzP9H+mp3de4zVvzuZ9nv//Od/nPOd/zu+8DbrbNSzAcLzL2wewJF/PLLkcx0YsSj5XyqiGCu5EPMYxbCntnU3ra/J9pc8nfMMo/Ch8yg/1w1vE7zB8LoHPYGW+j/2yrcI5XMbiauCD2Il92FslRT0tRcqGYgKexYNFxP3xHCMqclsrfwNO4CoWlsEtWI1bmFuFNgOj83prlf0hOY2NGIj2IuIvGICt6RCOVnHs6fCKx+OLx+EQdgU40tCed8en8nnRS/ByXMDXCDLAUaO7cxU0/SGptUTcjCfZvxP8K9+8zJ9SjV0LOPL8Pjs3l8FxqpvqiDhcIw1R55fK4MOphbfXCS7qua0MXo/TdYLfYGRRx0WO/xv4SCrybXVG/AGD0VpOxXXMrxMcKtc3qdyeAD9K6jQ5t2RnfnrZIKHfoY5hTQEOkQ7J68h9Hr+VVksdz8HN7NgY4D74nhdiOtztJTg0YkcaEK/ToBhTiNDTrKX7Iz9VwCexLK/HEKhmH5MADcJatBTgKXiY3hib0Zr/alPxIItZVEVHeTQVb+w2GGt4yylEH4QkhDR0TZC4noW2XCXxBbXapJSme2mc/cyzsvO8yhHH9e0kILMxDzdqJMegXYfNiL8DnVY5yiM/0T33k2hPrwE8DXfyvIzIu6wSHBvR1qF0S3HxL/DzKYgVKdKxKeJX5Wd/A3zvdwNQJPX4AAAAAElFTkSuQmCC"
                  width={11}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.8pt",
                marginRight: "33.05pt",
                textIndent: "3.450000000000003pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                when you move outside Three's 3G or 4G service area while you're
                on a call (in this case calls may end) or using data (in this
                case, your connection may be lost);
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 13,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAAAXNSR0IArs4c6QAAAmxJREFUSEu9lk+IzVEUxz9jBmEmJiH5GyaNEIuxwEZJmrCTkthY2LCYZutPrIRENspiJCk7ShY2NpMFWZBoRpQoSRJi/JkZ9/s6p447v/dq5veeU69f3fvu+dzz555zmhgrd4HtQBvwzbbPAduAL8CmcOQ00A18BLaE9QvAEWAD8DAimjLeUuA18BxYFfauAvuBz0B7WL8G7APeAEsyXcPAJPuN+l4ENgM/gBZgKvA7KLgSgHPDeh+w14ArMuABQPt3gB1FwKPpNieBG6akwNvjXlJIZphX5B3cQln3x9RNN0vHrb3gwGHgIvAC6IzA3nSTM3EjO7wOmAX8BB6EvZXAfHN/f5UbeiynAUNuoQd1T0qWmwUHJ5I0ruY9MC9d7DJwSMApdvNocc4sAzwBHAdkaYuAu82qX5adRZ4pA5R1slJSAQ4AHSntXwHLq8ShDFAqRyxBuwX0+D1Kb6arQUC9AL2EsxFYCWqDgN8BZelABOrRK7j1jqH0fU3WtXpWuktPJV8f+5/AWiWtbNKoLqtG35dLPaDPgNUNstAZBwV8DKxP/esTMLtBQH8WiwVUWxm0t6LUrXfS7EoG3TKlzQKqSarsSNRcK20kkzIxvA3stOmhzYv3B2BOqjTq4OrsuVyyzq7LaCpw0dtVwVfHX1vFO0NWMjcD/Q7UOKGkqVVPq+irubzILqM/yZOjDtRXgZWoUaph1kPUIzcC59MA1iOFcaZZAzwB3gEL60BTKdOUp/c32SeKfGp7Cyyw7vGyJPS6zUb/NPUc6M1YN5tZAqjBSQOURs5lUU8O1N7WNOzes8FX34nIU6taYwayv9/BqAoAgQBsAAAAAElFTkSuQmCC"
                  width={13}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.1849999999999998",
                marginLeft: "111.8pt",
                marginRight: "32.8pt",
                textIndent: "9.950000000000003pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                when you're in areas not covered by the Three Network. In this
                case, Three Services relies onother operators' networks, over
                which we have no control;
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 16,
                  height: 13,
                }}
              >
                <img
                  src="blob:https://wordtohtml.net/c7dc0822-e3fa-466c-b30f-f0a19adf1cfd"
                  width={16}
                  height={13}
                  data-fr-old-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAAAXNSR0IArs4c6QAAApNJREFUSEvNl0uIz1EUxz9jvBnGwniW8tp4l6REsWBBkUTIimY1NohIdjQWlDRF2FEshGw8NoomlAXFyisLCeWR8Rb3+++c6fjN77/7/X4zd/X/n/O793zuueeec24DPcdlYDUwDXhq6o3APqALWBimHAOWAR+AJUF+AlgEfAQWB/lJoBWYCTyOphsyHOPSh6+BG8CKoNsNHDaQ4UF+AVgPvAdGB/lVYBXwFhiTsfEdGAT0A/66LoJI8QkYZh/F+buAQwYyKijOBpDxQX4JWGkgEzMgW4HTwBlgWx6Iu60j7bAt58iKFOnIRgItwDst7B6RN/6YpaHAtyKt5qy1AzgC3AKWRhB54Hhy8xNgRs5EuX068Bu4E/RzgWZA5343yLWGYuYn0FlnU9q4HFDbuHvEg2YncDRnYpHB6ss/AyYDe4F2gYywINUHimbtIjvKAGlPnthjAT1WIJsBRb/cPqCOG8sA0e38YvaaBfISmAR8tkjOYykDRHY8JBYIxP/oGuk6VQmiU2gETkUQnZkCp0qQHylRDgReRJCDKU72VwzyNZWOIZ5H/Gj6DEi20EXnlBWsXgCvxKPJVtAqQPw0tgvkIrDW6ovSbZXB6iDzBLIcuN4LCW0NoHZBo1EgsfLOSd55VFGK96ZK2bXJi54aItUcpfotOSAq2wesMZoQ9Gpw1lmHNjXIZUQdnjo0Ve28oVZjMCDP1IJVY5Z54pclmDpzCxV7fPRXLxRbRUFIOB94UKjJnotdM48pgSp/dXdo+j0beAi8AdRElzV0MxUK2rSqverNfyDyzn3ziKrxq5JIzgMb7LbedBvZ54QI1Rg9B6aUAKLqrufKPXv3dJvIgkixKT2uztmD6XbBMN77NIWmqGbiH7GizCTC0YN5AAAAAElFTkSuQmCC"
                />
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.7pt",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                and
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "110.7pt",
                marginRight: "6.1pt",
                textIndent: "-1pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
                padding: "0pt 0pt 0pt 1pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                because of factors outside our control, such as the features or
                functionality of your Device, legal or regulatory requirements,
                lack of capacity, interruptions to Three Services from other
                suppliers, faults in other communication networks, the weather
                or radio interference caused by hills, tunnels or other physical
                obstructions.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 17,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAA0JJREFUSEu1l0mojlEYx3/XeG8yy3SvMRlKyZBxQSmKDTaGy8ICJSEWLJRrRbIwJIWFrmnJyrygzFNKuJIp80xkns7/9pzb8Trfd9/3637P5v065xn+55m/Ev6nA8AUoCfw0K6nA6uAt8DYiEzWoz1AJTASuBAKlyQ09QAeAGeBMcHdcmAD8AromNV6Dv5vQDOgMfDb84SAGgEfgBaAfoe0DFhngMobCNBiYBNwDJgYA7QI2ALsAOY3kNH61LwD2gCtgI9i9h7SV277A3SwXKlPWUPcr3ZKqoDHQLcQkMKxAnhjgJLGugD9AMX9nF2Os+8Tl6B38qDrA1TY/ckIn5wgKpV+76H3QGtARk5FhGJJ/cIS/BowOA+gS8AwQN/hET7lrUK2T5UnQE2AH8aoeIohSTFAM5zX9luo9ZhPEbm2wGsrktGBd0PWWcBe4BfQVIB8uARKZRijGCDxKoSiBcD2iKCvJF3p4TKapM5O/pkdlgnQF4ufQqDLtIDEVw3MAV4CnSKCeqSAHASm5tCtFiM+fQcJkE+qZDMM5XM1RiXrI2PsGrxURwOAm3bXPeCL4fqucAHrQ0Db3EsXZvSQ2L2HNwNLAvndwOw8lRua8jpqQkBbXQKqOWYJmXhXAmuBz1apP03BV3fe3M3DuW4u7sqh1x97QLWN0YesUEBqpJpxIpW1yrs/cMvOclVuzEP/ANIEVoJm9ZD4r1ovOu4G8wTgBDDeJak2h2n1eEfXftBWhx66CIwoEJCG4xEraw1n9TKFqzdwPwUgX41VAnQFGOJyQTEvKxCQxNRjVLprXBpoRomS601MfdiYyyUwFLhsHVe7SSEhk8xGqzIBkx6tFktTeGcgcN34SgVIwr4y2jmlWgmSlGZB88udl9VQvZsC0GlbBjWkK7xLVRGqDJ+UST3qLwqF5pIM5aIa6/YCIs+nIbULpcpkV6WHPCB1WSHMN8/SKM/K0xe4bUK1q2yYdNqFFLJJrtoOZ9VcIP9RaxN1PTAE5JP7OaCFrNjUHpAtVVndJpAsy3tAL1u8fPctFjD/d2ueS+ad3kgSkN9x1NjU8otF+iv1FLihlSM0EmtcM22dHAWcLxKiM+7vjzbIlslN8y8TXrrx32bYrwAAAABJRU5ErkJggg=="
                  width={17}
                  height={13}
                />
              </span>
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "3.95pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    In the event that you experience continuous or regularly
                    recurring disruption to Three Services (such as where your
                    access to Three Services is limited or unavailable) you may
                    be entitled to a price reduction based upon the period of
                    the disruption. This may take the form of a partial or full
                    credit or a refund of your Monthly Charge. To receive a
                    credit or refund you’ll need to report to us a disruption on
                    the Three Network in order that we may investigate your
                    concerns, consider the extent to which you use the Three
                    Services in question and measure the disruption against your
                    typical usage history. An alternative means of accessing the
                    disrupted Three Service (for example, using Three InTouch
                    Wi-Fi Calling or Home Signal) must also be unavailable to
                    you. This is without prejudice to any remedies which may be
                    available to you under consumer law (as explained more fully
                    at Section 12.3) or general contract law, including damages
                    or early termination of this agreement (where the disruption
                    to Three Services is very serious). Please contact us as set
                    out in Section 7 (Your Rights
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "98.05pt",
                marginRight: "1.9pt",
                textAlign: "justify",
                marginTop: "0.15pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                - Complaints) and we’ll work with you to find an appropriate
                resolution for your particular circumstances.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={14}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Goodwill gesture or credit
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "41.4pt",
                marginRight: "3.2pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Unless otherwise stated to you in writing, if we apply a
                monetary goodwill gesture or credit to your account during the
                course of your agreement, such monetary goodwill gesture or
                credit may not be redeemed for cash and may solely be redeemed
                against your use of Three Services.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={6}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    What you’ll do in return - Personal Security
                  </span>
                </h2>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "4pt",
                marginBottom: "0pt",
              }}
            >
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-11.899999999999999pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "28.8pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    As we own the SIM and it remains our property at all times,
                    you must ensure that you keep it safe and secure whilst it's
                    in your possession and you must ensure that you're able to
                    return it to us, if required. We may charge you for any
                    replacement SIM (as set outin our Price Guide).
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.9pt",
                    textAlign: "justify",
                    marginTop: "4.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You must keep all PINs and passwords secure and
                    confidential. You're also responsible for the security of
                    your Device and must make sure that you keep it secure (see
                    the Device manufacturer's user guide for details of how to
                    keep your Device secure). You must also keep any PINs or
                    passwords for any services you access through your Device
                    secure and confidential.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.25pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You should immediately change your PIN or password if you
                    become aware that someone is accessing Three Services on
                    your account without your permission.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Responsible use - How you use the Three Services
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You may only use Three Services:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.25pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    as set out in this agreement; and
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "40.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    for your own personal use. This means you must not resell or
                    commercially exploit any of Three Services or our content.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.8pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You mustn't use Three Services, the SIM, the eSIM Profile or
                    Three phone number or allow anyone else to use Three
                    Services, the SIM, the eSIM Profile or Three phone number
                    for illegal or improper uses. For example:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.25pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    for fraudulent, criminal or other illegal activity;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.25pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    in any way which breaches another person's rights
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "63.05pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    including copyright or other intellectual property rights;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "44.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    to copy, store, modify, publish or distribute Three Services
                    or their content, except where we give you permission;
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "21.8pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    to download, send or upload content of an excessivesize,
                    quantity or frequency. We'll contact you if your use is
                    excessive;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={6}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "21.7pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    in any way which breaches any security or other safeguards
                    or in any other way which harms or interferes with the Three
                    Network, the networks or systems of others or Three
                    Services; or
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={7}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "23.65pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    to use or provide to others any directory or details about
                    Three customers.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={6}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "3.75pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You must always co-operate with us and follow our reasonable
                    instructions to ensure the proper use and security of your
                    account. This includes (but is not limited to) any
                    instructions from us to update the settings on your Device
                    and any instructions regarding the use of your plan, as set
                    out in your Price Guide (available at&nbsp;
                  </span>
                  <a
                    href="http://www.three.co.uk/priceguide"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",
                        color: "#0000ff",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      www.Three.co.uk/priceguide).
                    </span>
                  </a>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={7}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "4pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If anyone makes, or threatens to make, any claim or issue
                    legal proceedings against you relating to your use of Three
                    Services, you will, notify us of this immediately and, at
                    our request, immediately stop the act or acts complained of.
                    If we ask you to, you must confirm the details of the
                    claim(s) in writing.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={8}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "4.35pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We have published an acceptable use policy which provides
                    more details about the rules for use of certain Three
                    Services:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "3.95pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    to ensure that use of Three Services either in the UK or in
                    our Go Roam destinations is not excessive;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.65pt",
                  paddingLeft: "17.4pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    to combat fraud;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.65pt",
                  paddingLeft: "17.4pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    to protect Three’s employees from abuse or harassment; and
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "3.65pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    where Three Services we offer or may introduce require
                    certain rules to ensure they can be enjoyed by all our
                    customers.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "41.4pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                This policy i s available on our website and may bechanged
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "41.5pt",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                from time to time.
              </span>
            </p>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Responsible use - How you use the Messaging and Storage Services
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={9}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-11.899999999999999pt",
                  paddingLeft: "17.15pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    While using the Messaging Services, you must not send or
                    upload:
                  </span>
                </p>
                <ol
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  <li
                    dir="ltr"
                    style={{
                      listStyleType: "lower-alpha",
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "8.700000000000003pt",
                      paddingLeft: "17.299999999999997pt",
                    }}
                    aria-level={3}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginRight: "33.1pt",
                        textAlign: "justify",
                        marginTop: "4.1pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Arial,sans-serif",

                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        anything that is copyright protected, unless you have
                        permission;
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    unsolicited bulk or commercial communications or other
                    unauthorised communications, or knowingly send any viruses;
                    or
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.75pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    anything that is obscene, offensive, abusive, defamatory,
                    menacing, harassing, threatening or is unlawful in any other
                    way.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={10}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may put limits on the use of certain Three Services, such
                    as Messaging Services or Storage Services. For example, we
                    may limit the size of messages or storage space.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={11}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.95pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    While we have no obligation to monitor the Messaging
                    Services or Storage Services, if you exceed our published
                    use limits, or we're made aware of any issues with your use
                    of these Three Services (for example, if we're made aware
                    that you're using Three Services in any of the ways
                    prohibited in Section 6.9 above) we reserve the right to
                    remove or refuse to send or store content on your behalf.
                    You may still be charged for any content which is blocked or
                    removed.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Responsible use - How you use the Age Restricted Services
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={12}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.75pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you're under 18, you're not allowed to access our Age
                    Restricted Services. If you're 18 or over and you access the
                    Age Restricted Services, you mustn't show or send content
                    from the Age Restricted Services to anyone under 18. You
                    must also ensure that you've deactivated any access to Age
                    Restricted Services if you let anyone under 18 use your
                    Device.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={13}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.1pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You accept that we cannot control access to age restricted
                    services obtained over WiFi.
                  </span>
                </p>
              </li>
            </ol>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Responsible use - How you use the Three Services outside the UK
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={14}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you use Three Services from a country outside the UK:
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
                <ol
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  <li
                    dir="ltr"
                    style={{
                      listStyleType: "lower-alpha",
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "8.75pt",
                      paddingLeft: "17.299999999999997pt",
                    }}
                    aria-level={3}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginRight: "13.6pt",
                        textAlign: "justify",
                        marginTop: "8.05pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Arial,sans-serif",

                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        your use of Three Services may be subject to laws and
                        regulations that apply in that other country, and we're
                        not responsible for your failure to comply with those
                        laws or regulations;
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.65pt",
                  paddingLeft: "17.4pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    you will be roaming on another operator's network so:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.35pt",
                marginRight: "0.6pt",
                textIndent: "-0.5pt",
                marginTop: "0pt",
                marginBottom: "0pt",
                padding: "0pt 0pt 0pt 0.5pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                you may not receive the same level of coverage and speed as
                you're used to on the Three network. We have no liability if
                you're unable to access services abroad, or if the quality of
                any other operator's network services differs from those
                provided on the Three network and;
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 11,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAaCAYAAACzdqxAAAAAAXNSR0IArs4c6QAAAkhJREFUSEu11kuIjnEUx/HPNK6N+y2kCSElyYKNWJCtsiHlWnJJpLBRbLBiI5JLZCxkyV6MUgqLKVJIktwiuYTBuPzP9H+mp3de4zVvzuZ9nv//Od/nPOd/zu+8DbrbNSzAcLzL2wewJF/PLLkcx0YsSj5XyqiGCu5EPMYxbCntnU3ra/J9pc8nfMMo/Ch8yg/1w1vE7zB8LoHPYGW+j/2yrcI5XMbiauCD2Il92FslRT0tRcqGYgKexYNFxP3xHCMqclsrfwNO4CoWlsEtWI1bmFuFNgOj83prlf0hOY2NGIj2IuIvGICt6RCOVnHs6fCKx+OLx+EQdgU40tCed8en8nnRS/ByXMDXCDLAUaO7cxU0/SGptUTcjCfZvxP8K9+8zJ9SjV0LOPL8Pjs3l8FxqpvqiDhcIw1R55fK4MOphbfXCS7qua0MXo/TdYLfYGRRx0WO/xv4SCrybXVG/AGD0VpOxXXMrxMcKtc3qdyeAD9K6jQ5t2RnfnrZIKHfoY5hTQEOkQ7J68h9Hr+VVksdz8HN7NgY4D74nhdiOtztJTg0YkcaEK/ToBhTiNDTrKX7Iz9VwCexLK/HEKhmH5MADcJatBTgKXiY3hib0Zr/alPxIItZVEVHeTQVb+w2GGt4yylEH4QkhDR0TZC4noW2XCXxBbXapJSme2mc/cyzsvO8yhHH9e0kILMxDzdqJMegXYfNiL8DnVY5yiM/0T33k2hPrwE8DXfyvIzIu6wSHBvR1qF0S3HxL/DzKYgVKdKxKeJX5Wd/A3zvdwNQJPX4AAAAAElFTkSuQmCC"
                  width={11}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.69999999999999pt",
                marginRight: "0.6pt",
                textIndent: "-0.15000000000000568pt",
                marginTop: "0pt",
                marginBottom: "0pt",
                padding: "0pt 0pt 0pt 0.15000000000000568pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                we accept no responsibility for information processing when it
                leaves our control.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 13,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAAAXNSR0IArs4c6QAAAmxJREFUSEu9lk+IzVEUxz9jBmEmJiH5GyaNEIuxwEZJmrCTkthY2LCYZutPrIRENspiJCk7ShY2NpMFWZBoRpQoSRJi/JkZ9/s6p447v/dq5veeU69f3fvu+dzz555zmhgrd4HtQBvwzbbPAduAL8CmcOQ00A18BLaE9QvAEWAD8DAimjLeUuA18BxYFfauAvuBz0B7WL8G7APeAEsyXcPAJPuN+l4ENgM/gBZgKvA7KLgSgHPDeh+w14ArMuABQPt3gB1FwKPpNieBG6akwNvjXlJIZphX5B3cQln3x9RNN0vHrb3gwGHgIvAC6IzA3nSTM3EjO7wOmAX8BB6EvZXAfHN/f5UbeiynAUNuoQd1T0qWmwUHJ5I0ruY9MC9d7DJwSMApdvNocc4sAzwBHAdkaYuAu82qX5adRZ4pA5R1slJSAQ4AHSntXwHLq8ShDFAqRyxBuwX0+D1Kb6arQUC9AL2EsxFYCWqDgN8BZelABOrRK7j1jqH0fU3WtXpWuktPJV8f+5/AWiWtbNKoLqtG35dLPaDPgNUNstAZBwV8DKxP/esTMLtBQH8WiwVUWxm0t6LUrXfS7EoG3TKlzQKqSarsSNRcK20kkzIxvA3stOmhzYv3B2BOqjTq4OrsuVyyzq7LaCpw0dtVwVfHX1vFO0NWMjcD/Q7UOKGkqVVPq+irubzILqM/yZOjDtRXgZWoUaph1kPUIzcC59MA1iOFcaZZAzwB3gEL60BTKdOUp/c32SeKfGp7Cyyw7vGyJPS6zUb/NPUc6M1YN5tZAqjBSQOURs5lUU8O1N7WNOzes8FX34nIU6taYwayv9/BqAoAgQBsAAAAAElFTkSuQmCC"
                  width={13}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={15}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Use of Three Services in our Go Roam destinations is subject
                    to our fair use policies, as published in your Price Guide,
                    which may be updated from time to time. See&nbsp;
                  </span>
                  <a
                    href="http://www.three.co.uk/priceguide"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      www.Three.co.uk/priceguide f
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    or full details:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.849999999999994pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.355",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you use Three Services in our Go Roam in Europe
                    destinations, you can use your voice minutes, text messages
                    and/or a portion of your data allowance each month subject
                    to paying a daily charge of £2 to unlock your allowance for
                    24 hours in our Go Roam in Europe destinations. Any use in
                    excess of the monthly fair use data limit, but within any
                    available data allowance, will be subject to a surcharge.
                    Fair use policies and the daily roaming charge are set out
                    in more detail in your Price Guide.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.849999999999994pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.355",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "11.3pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you use Three Services in our Go Roam Around the World
                    destinations, you can use a portion of your allowance of
                    voice minutes, texts and data each month subject to paying a
                    daily charge of £5 to unlock your allowance for 24 hours for
                    use in our Go Roam Around the World destinations. Any use in
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.355",
                marginLeft: "105.8pt",
                marginRight: "32.8pt",
                textAlign: "justify",
                marginTop: "4.1pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                excess of your monthly fair use data limit, but within any
                available data allowance, will be subject to a surcharge. If you
                exceed any of the fair use limits for minutes or texts in any
                two months within a rolling 12- month period, we have the right
                to suspend international roaming on your account, meaning you
                will no longer be able to use your device or allowances abroad.
                We’ll let you know in advance if this is likely to happen.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.8pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.355",
                    marginRight: "32.75pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you roam exclusively in one or more of our Go Roam
                    destinations (including both Go Roam in Europe and Go Roam
                    Around the World) for any two complete months in a rolling
                    12-month period, we may suspend international roaming on
                    your account, meaning you will no longer be able to use your
                    device or allowances abroad. We’ll let you know in advance
                    if this is likely to happen. If you spend a full month
                    abroad but some of that time is spent in a destination that
                    isn’t included in Go Roam, this fair use policy won’t apply.
                  </span>
                </p>
              </li>
            </ol>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={16}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.25pt",
                    textAlign: "justify",
                    marginTop: "10.2pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You may accidentally roam if you're in an area close to
                    national borders because your Device picks up a network
                    signal across the border. If this is the case, then you may
                    be charged as if you were roaming on an international
                    network.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Paying your Bills
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={17}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You must pay us all Charges in connection with all Three
                    Services which are accessed using the SIM or eSIM Profile we
                    supply to you. You must pay the Monthly Charge, whether or
                    not your allowance of voice minutes, text messages and/or
                    data are consumed by you or by another person, with or
                    without your permission. You must also pay for all Three
                    Services which don't involve a conversion of voice minutes,
                    text messages and/or data within your allowance and which
                    are accessed using the SIM or eSIM Profile we supply to you
                    or which are accessed using your Device, whether the Three
                    Services are accessed by you or by another person, with or
                    without
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "41.3pt",
                marginRight: "2.35pt",
                textAlign: "justify",
                marginTop: "8.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                your permission. If your SIM and/or Device is lost or stolen you
                remain responsible for all the Charges to your account until you
                tell us what happened and arrange for your SIM, eSIM Profile
                and/or Device to be deactivated.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={18}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We'll send you a bill on a periodic basis and this will
                    usually be done monthly. However, we may change this period,
                    and we would give you at least 14 days' notice of this.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={19}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.95pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Your bill will normally include your Monthly Charge for your
                    Package for the next billing period and any administration
                    fees along with Charges for your use of the Three Services
                    in the UK in the last period and outside the UK in prior
                    periods (if not within your allowance). If your Package
                    includes an allowance, this will be made up of voice
                    minutes, text messages and/or a certain amount of data each
                    month. If you haven't used all of that monthly allowance by
                    the time we bill you, it will expire and your monthly
                    allowance willthen start again on each monthly bill date
                    (which you will see on your bill).
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={20}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Charges on your bill are shown inclusive of VAT (where
                    appropriate), unless you're a business customer, in which
                    case, VAT will be added to your bill where appropriate.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={21}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You must make your payment by the due date and by one of the
                    payment methods stated on your bill. If you choose to pay by
                    a Recurring Payment Method, you will benefit from a monthly
                    discount (as set out in our Price Guide. See also Section
                    6.24). However, we may also submit an interim bill or
                    require an immediate payment if we think you have exceeded a
                    reasonable limit on your account. Your bill will state the
                    amount of the Charges due from you and the due date by which
                    you must make payment. If you fail to pay your account on
                    time, you will be breaking your agreement and we may Suspend
                    or Disconnect you. In this case, you will have to pay any
                    outstanding Charges. We may set a credit limit on your
                    account. If you exceed the credit limit we set, we may
                    Suspend any or all of the Three Services you use until
                    you've made a payment to your account. You shouldn't use the
                    credit limit for budgeting as the amount you owe isn't
                    capped or limited by any credit limit we set.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={22}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.65pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Three will notify you at least 30 days in advance of any
                    change in your payment date.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33pt",
                    textAlign: "justify",
                    marginTop: "3.95pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may need to take legal or other collection action against
                    you for non- payment of Charges. This could mean you have to
                    pay our reasonable costs and expenses, or the reasonable
                    costs and expenses of our assignees, including legal and
                    administration costs. Interest may be added on a daily basis
                    to any unpaid Charges and costs, at 2% per annum above the
                    base rate of HSBC Bank plc, from the date payment is due
                    until it is received.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={24}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.65pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    As a condition of signing up to Three Services, we require
                    you to set up a direct debit to pay your Charges and you
                    will benefit froma discount to your Monthly Charge if you
                    pay by this efficient means.In order to maintain this
                    discount, you must continue to have a valid direct debit or
                    other Recurring Payment Method in place. If you don't have a
                    Recurring Payment Method in place, and pay us by other
                    means, you won't benefit from the discount. Please see the
                    Price Guide for further details of the discount amount and
                    Recurring Payment Methods we accept.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={7}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Your Rights - Complaints
                  </span>
                </h2>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.5pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you're unhappy about any aspect of Three Services, you
                    should contact Three Customer Services.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.9pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We'll investigate any complaint in accordance with our
                    customer complaints code, after which we'll contact you with
                    the results. A copy of our customer complaints code can be
                    viewed on our website at Three.co.uk/complaints or you can
                    request a copy by contacting Three Customer Services. If we
                    are unable to resolve your complaint, you may, depending on
                    the nature of your complaint, be entitled to ask Ombudsman
                    Services: Communications to consider your complaint for you.
                    Their website address is: https://www.ombudsman
                  </span>
                  <a
                    href="https://www.ombudsman-services.org/"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",
                        color: "#0000ff",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      -services.org.
                    </span>
                  </a>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.4pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    See Section 13 for information about data protection and
                    privacy complaints.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={8}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Our Rights - Intellectual Property
                  </span>
                </h2>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    All rights in Three Services and their content, belong to
                    us, or our licensed source, such as a content provider. We
                    reserve all our rights.
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.3pt",
                    textAlign: "justify",
                    marginTop: "8.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The ‘Three' trademark and other related images, logos and
                    names are proprietary marks of our group of companies. We
                    reserve all our rights.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={9}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Suspension of Three Services
                  </span>
                </h2>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.7pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may Suspend any or all of the Three Services you use
                    without notice if:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.849999999999994pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.355",
                    marginRight: "2.65pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we reasonably believe you‘ve provided us with false or
                    misleading details about yourself as set out in Section 13;
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.849999999999994pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.355",
                    marginRight: "2.25pt",
                    textAlign: "justify",
                    marginTop: "11.45pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we advise you that your excessive use of Three Services (as
                    may be defined in accordance with Section 6.5 above) is
                    causing problems for other users, and you're continuing to
                    use Three Services excessively.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.849999999999994pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.34",
                    marginRight: "2.55pt",
                    textAlign: "justify",
                    marginTop: "11.35pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we believe your Device, SIM or eSIM Profile has been lost,
                    stolen or is being used in a way not allowed by this
                    agreement.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.849999999999994pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.355",
                    marginRight: "2.15pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we reasonably believe that you‘ve used Three Services, the
                    SIM(s), the eSIM Profile(s) or a Three phone number for
                    illegal or improper purposes in contravention of our
                    responsible use requirements in Section 6 above;
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.849999999999994pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.355",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "11.35pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we receive a serious complaint against you which we believe
                    to be genuine (for example, if we receive a complaint that
                    you're using Three Services in any of the ways prohibited in
                    Section 6);
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.849999999999994pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.34",
                    marginRight: "2.15pt",
                    textAlign: "justify",
                    marginTop: "11.4pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we're required to Suspend your Three Services by the
                    emergency services or other government authorities; or
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.849999999999994pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.35pt",
                    textAlign: "justify",
                    marginTop: "11pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we reasonably believe that you have abused or harassed
                    Three’s employees in contravention of our
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "105.95pt",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                acceptable use policy.
              </span>
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-11.899999999999999pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "28.8pt",
                    textAlign: "justify",
                    marginTop: "1.4pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      className="Apple-tab-span"
                      style={{ whiteSpace: "pre" }}
                    >
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    In addition to the circumstances set out in Section 9.1, we
                    may also Suspend any or all of the Three Services you use
                    without notice if:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.8pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.34",
                    marginRight: "32.8pt",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    you haven't paid our Charges on time, or if you've
                    previously failed to pay your Charges on time; or
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.8pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.355",
                    marginRight: "33pt",
                    marginTop: "11.4pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    you've exceeded any credit limit that we may have set for
                    you (as set out in Section 6.21 above); or
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "51.8pt",
                  paddingLeft: "18pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.34",
                    marginRight: "33.2pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    you have insufficient credit in your account to cover
                    Charges you agreed to pay in advance.
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "41.25pt",
                marginRight: "33.65pt",
                marginTop: "10.35pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Additionally, we reserve the right to Suspend any other
                account(s) you have with us, if we reasonably believe that you
                will be unable to pay the relevant Charges.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may end any calls that you make that are longer than 2
                    hours' duration in order to prevent you from incurring
                    excessive, inadvertent costs.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may turn off your Messaging Services if they're inactive
                    for an extended period of time - we'll let you know before
                    this happens. If we do turn off your Messaging Services
                    we'll have no obligation to maintain any of the content in
                    your Messaging Services, or to forward any unopened or
                    unsent messages to you, or anyone else.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.75pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If we Suspend any or all of your Three Services, you will
                    still be able to make emergency calls (unless they've been
                    Suspended at the request of the emergency services).
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={6}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.8pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If your Three Services are Suspended, we may agree to
                    re-Connect you if you ask us to do so and there may be a
                    re-Connection Charge for this.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={10}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Ending this agreement and Disconnection of Three Services
                  </span>
                </h2>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You may end this agreement in the following ways:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.95pt",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    in certain circumstances under our Returns Policy which can
                    be found at Three.co.uk/returns. You will
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "98.05pt",
                marginTop: "8.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                need to get in touch with Three Customer Services to arrange
                Disconnection;
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    at any time during your Minimum Term (if you have one) by
                    giving notice to Three Customer Services at least 30 days
                    before the date you want to end the agreement. You must pay
                    us all the Charges you owe, plus any Cancellation Fee for
                    your Package (as set out in the Price Guide);
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    at the end of your Minimum Term or any time after your
                    Minimum Term has expired, or if you don't have a Minimum
                    Term, provided you give notice to Three Customer Services at
                    least 30 days before the date you want to end the agreement.
                    A Cancellation Fee won't be charged; or
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.65pt",
                  paddingLeft: "17.4pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    where entitled to do so under Section&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.tyjcwt"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      4.4.
                    </span>
                  </a>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may end this agreement in the following ways:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.65pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    by giving you at least 30 days' notice if your Package
                    doesn't have a Minimum Term, or the Minimum Term has ended.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.65pt",
                  paddingLeft: "17.4pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    immediately in the following cases:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "2.3pt",
                textIndent: "0.20000000000000284pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                if we have the right to Suspend your Three Services on any of
                the grounds in Section 9 and we believe that the grounds are
                serious and have not been, or are unlikely to be, rectified.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 11,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAaCAYAAACzdqxAAAAAAXNSR0IArs4c6QAAAkhJREFUSEu11kuIjnEUx/HPNK6N+y2kCSElyYKNWJCtsiHlWnJJpLBRbLBiI5JLZCxkyV6MUgqLKVJIktwiuYTBuPzP9H+mp3de4zVvzuZ9nv//Od/nPOd/zu+8DbrbNSzAcLzL2wewJF/PLLkcx0YsSj5XyqiGCu5EPMYxbCntnU3ra/J9pc8nfMMo/Ch8yg/1w1vE7zB8LoHPYGW+j/2yrcI5XMbiauCD2Il92FslRT0tRcqGYgKexYNFxP3xHCMqclsrfwNO4CoWlsEtWI1bmFuFNgOj83prlf0hOY2NGIj2IuIvGICt6RCOVnHs6fCKx+OLx+EQdgU40tCed8en8nnRS/ByXMDXCDLAUaO7cxU0/SGptUTcjCfZvxP8K9+8zJ9SjV0LOPL8Pjs3l8FxqpvqiDhcIw1R55fK4MOphbfXCS7qua0MXo/TdYLfYGRRx0WO/xv4SCrybXVG/AGD0VpOxXXMrxMcKtc3qdyeAD9K6jQ5t2RnfnrZIKHfoY5hTQEOkQ7J68h9Hr+VVksdz8HN7NgY4D74nhdiOtztJTg0YkcaEK/ToBhTiNDTrKX7Iz9VwCexLK/HEKhmH5MADcJatBTgKXiY3hib0Zr/alPxIItZVEVHeTQVb+w2GGt4yylEH4QkhDR0TZC4noW2XCXxBbXapJSme2mc/cyzsvO8yhHH9e0kILMxDzdqJMegXYfNiL8DnVY5yiM/0T33k2hPrwE8DXfyvIzIu6wSHBvR1qF0S3HxL/DzKYgVKdKxKeJX5Wd/A3zvdwNQJPX4AAAAAElFTkSuQmCC"
                  width={11}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "2pt",
                textIndent: "2.25pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                if we believe that your communications with Three Customer
                Services or any of our retailers or agents, or your use of our
                Three Services, are jeopardising the operation of the network,
                or are of an unacceptable nature;
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 13,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAAAXNSR0IArs4c6QAAAmxJREFUSEu9lk+IzVEUxz9jBmEmJiH5GyaNEIuxwEZJmrCTkthY2LCYZutPrIRENspiJCk7ShY2NpMFWZBoRpQoSRJi/JkZ9/s6p447v/dq5veeU69f3fvu+dzz555zmhgrd4HtQBvwzbbPAduAL8CmcOQ00A18BLaE9QvAEWAD8DAimjLeUuA18BxYFfauAvuBz0B7WL8G7APeAEsyXcPAJPuN+l4ENgM/gBZgKvA7KLgSgHPDeh+w14ArMuABQPt3gB1FwKPpNieBG6akwNvjXlJIZphX5B3cQln3x9RNN0vHrb3gwGHgIvAC6IzA3nSTM3EjO7wOmAX8BB6EvZXAfHN/f5UbeiynAUNuoQd1T0qWmwUHJ5I0ruY9MC9d7DJwSMApdvNocc4sAzwBHAdkaYuAu82qX5adRZ4pA5R1slJSAQ4AHSntXwHLq8ShDFAqRyxBuwX0+D1Kb6arQUC9AL2EsxFYCWqDgN8BZelABOrRK7j1jqH0fU3WtXpWuktPJV8f+5/AWiWtbNKoLqtG35dLPaDPgNUNstAZBwV8DKxP/esTMLtBQH8WiwVUWxm0t6LUrXfS7EoG3TKlzQKqSarsSNRcK20kkzIxvA3stOmhzYv3B2BOqjTq4OrsuVyyzq7LaCpw0dtVwVfHX1vFO0NWMjcD/Q7UOKGkqVVPq+irubzILqM/yZOjDtRXgZWoUaph1kPUIzcC59MA1iOFcaZZAzwB3gEL60BTKdOUp/c32SeKfGp7Cyyw7vGyJPS6zUb/NPUc6M1YN5tZAqjBSQOURs5lUU8O1N7WNOzes8FX34nIU6taYwayv9/BqAoAgQBsAAAAAElFTkSuQmCC"
                  width={13}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "1.9pt",
                textIndent: "3.950000000000003pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                if we reasonably believe you won't be able to pay your bill.
                This could result from a failure to pass one of our credit
                assessments; or
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 16,
                  height: 13,
                }}
              >
                <img
                  src="blob:https://wordtohtml.net/2e652743-27b0-4e0b-9610-9db20a73823b"
                  width={16}
                  height={13}
                  data-fr-old-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAAAXNSR0IArs4c6QAAApNJREFUSEvNl0uIz1EUxz9jvBnGwniW8tp4l6REsWBBkUTIimY1NohIdjQWlDRF2FEshGw8NoomlAXFyisLCeWR8Rb3+++c6fjN77/7/X4zd/X/n/O793zuueeec24DPcdlYDUwDXhq6o3APqALWBimHAOWAR+AJUF+AlgEfAQWB/lJoBWYCTyOphsyHOPSh6+BG8CKoNsNHDaQ4UF+AVgPvAdGB/lVYBXwFhiTsfEdGAT0A/66LoJI8QkYZh/F+buAQwYyKijOBpDxQX4JWGkgEzMgW4HTwBlgWx6Iu60j7bAt58iKFOnIRgItwDst7B6RN/6YpaHAtyKt5qy1AzgC3AKWRhB54Hhy8xNgRs5EuX068Bu4E/RzgWZA5343yLWGYuYn0FlnU9q4HFDbuHvEg2YncDRnYpHB6ss/AyYDe4F2gYywINUHimbtIjvKAGlPnthjAT1WIJsBRb/cPqCOG8sA0e38YvaaBfISmAR8tkjOYykDRHY8JBYIxP/oGuk6VQmiU2gETkUQnZkCp0qQHylRDgReRJCDKU72VwzyNZWOIZ5H/Gj6DEi20EXnlBWsXgCvxKPJVtAqQPw0tgvkIrDW6ovSbZXB6iDzBLIcuN4LCW0NoHZBo1EgsfLOSd55VFGK96ZK2bXJi54aItUcpfotOSAq2wesMZoQ9Gpw1lmHNjXIZUQdnjo0Ve28oVZjMCDP1IJVY5Z54pclmDpzCxV7fPRXLxRbRUFIOB94UKjJnotdM48pgSp/dXdo+j0beAi8AdRElzV0MxUK2rSqverNfyDyzn3ziKrxq5JIzgMb7LbedBvZ54QI1Rg9B6aUAKLqrufKPXv3dJvIgkixKT2uztmD6XbBMN77NIWmqGbiH7GizCTC0YN5AAAAAElFTkSuQmCC"
                />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.95pt",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                in the event of your bankruptcy or insolvency;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "76.65pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                and, in any of these circumstances, you have to pay all the
                Charges you owe up until we Disconnect you.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 17,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAA0JJREFUSEu1l0mojlEYx3/XeG8yy3SvMRlKyZBxQSmKDTaGy8ICJSEWLJRrRbIwJIWFrmnJyrygzFNKuJIp80xkns7/9pzb8Trfd9/3637P5v065xn+55m/Ev6nA8AUoCfw0K6nA6uAt8DYiEzWoz1AJTASuBAKlyQ09QAeAGeBMcHdcmAD8AromNV6Dv5vQDOgMfDb84SAGgEfgBaAfoe0DFhngMobCNBiYBNwDJgYA7QI2ALsAOY3kNH61LwD2gCtgI9i9h7SV277A3SwXKlPWUPcr3ZKqoDHQLcQkMKxAnhjgJLGugD9AMX9nF2Os+8Tl6B38qDrA1TY/ckIn5wgKpV+76H3QGtARk5FhGJJ/cIS/BowOA+gS8AwQN/hET7lrUK2T5UnQE2AH8aoeIohSTFAM5zX9luo9ZhPEbm2wGsrktGBd0PWWcBe4BfQVIB8uARKZRijGCDxKoSiBcD2iKCvJF3p4TKapM5O/pkdlgnQF4ufQqDLtIDEVw3MAV4CnSKCeqSAHASm5tCtFiM+fQcJkE+qZDMM5XM1RiXrI2PsGrxURwOAm3bXPeCL4fqucAHrQ0Db3EsXZvSQ2L2HNwNLAvndwOw8lRua8jpqQkBbXQKqOWYJmXhXAmuBz1apP03BV3fe3M3DuW4u7sqh1x97QLWN0YesUEBqpJpxIpW1yrs/cMvOclVuzEP/ANIEVoJm9ZD4r1ovOu4G8wTgBDDeJak2h2n1eEfXftBWhx66CIwoEJCG4xEraw1n9TKFqzdwPwUgX41VAnQFGOJyQTEvKxCQxNRjVLprXBpoRomS601MfdiYyyUwFLhsHVe7SSEhk8xGqzIBkx6tFktTeGcgcN34SgVIwr4y2jmlWgmSlGZB88udl9VQvZsC0GlbBjWkK7xLVRGqDJ+UST3qLwqF5pIM5aIa6/YCIs+nIbULpcpkV6WHPCB1WSHMN8/SKM/K0xe4bUK1q2yYdNqFFLJJrtoOZ9VcIP9RaxN1PTAE5JP7OaCFrNjUHpAtVVndJpAsy3tAL1u8fPctFjD/d2ueS+ad3kgSkN9x1NjU8otF+iv1FLihlSM0EmtcM22dHAWcLxKiM+7vjzbIlslN8y8TXrrx32bYrwAAAABJRU5ErkJggg=="
                  width={17}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.95pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    if we no longer have access to other operators' networks
                    which we need to provide Three Services, or if we're no
                    longer able to provide Three Services due to factors beyond
                    our control or because we cease business. If reasonably
                    possible under these circumstances, we will endeavour to
                    provide you with such notice as is practical.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If your Device is dual SIM compatible and your SIM or eSIM
                    Profile is Suspended or Disconnected by us because you
                    haven’t complied with certain terms of your agreement, we
                    may also Suspend or Disconnect the other SIM or eSIM you are
                    using in your Device.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={11}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Effect of this agreement ending
                  </span>
                </h2>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If this agreement ends, we'll close your account and
                    Disconnect you and you won't be able to use Three Services
                    or make emergency calls.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You must immediately pay all Charges you owe up to the date
                    the agreement ends. If we end the agreement due to your
                    conduct, the Charges will include a Cancellation Fee. Where
                    you have terminated the agreement within the Minimum Term
                    (except as set out in Section&nbsp;
                  </span>
                  <a
                    href="https://docs.google.com/document/d/1hUq60p3JqJv5F5f5HwSNjwsM-FCROZOzSsGGxc2eTpo/edit#heading=h.3dy6vkm"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",

                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      5
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    ), the Charges will include a Cancellation Fee.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may bill you up to 4 months following the Disconnection
                    of your account, in respect of Charges that were incurred
                    during your agreement with us, but not billed prior to the
                    date of Disconnection. In accordance with Section 6.17, you
                    must pay us all Charges in connection with all Three
                    Services which are accessed using the SIM or eSIM Profile we
                    supplied to you.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.8pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Following termination of your agreement, in the event that
                    your account has a credit balance (not including any
                    balances detailed in Section 5.14) of fifty pence or less
                    and that balance remains unclaimed for a period of 3 months
                    we reserve the right to donate that amount to a charity we
                    designate. In the event that your
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "41.3pt",
                marginRight: "2.05pt",
                textAlign: "justify",
                marginTop: "8.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                terminated account has a higher credit balance after a
                substantive period of time, we reserve the right to donate such
                amount to charity if we have tried to refund you and/or contact
                you to return the unclaimed credit balance and have failed to do
                so having made all reasonable efforts.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.1pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You need to have an active Primary Service for the duration
                    of the Smartwatch Pairing Plan to be able to share
                    allowances with your Secondary Device. If you have purchased
                    the Smartwatch Pairing Plan as an Additional Service, this
                    will be cancelled at the same time as your agreement is
                    terminated.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={12}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "2.455",
                    marginRight: "233.5pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Liability Limits on our liability
                  </span>
                </h2>
                <ol
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  <li
                    dir="ltr"
                    style={{
                      listStyleType: "decimal",
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                      marginLeft: "-48pt",
                      paddingLeft: "17.299999999999997pt",
                    }}
                    aria-level={2}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginRight: "1.9pt",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          fontFamily: "Arial,sans-serif",

                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        All of our obligations to you relating to Three Services
                        are set out in your agreement. If you wish to make any
                        change to this agreement or rely on any other term, you
                        must obtain our agreement to the change of term in
                        writing.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Except as set out in Section 12.3:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "12.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    all other terms, conditions and warranties relating to Three
                    Services are excluded.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.7pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    our entire liability to you for something we do or don't do
                    will be limited to £3,000 for one claim or a series of
                    related claims.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "4.95pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we're not liable for any loss of income, business or
                    profits, or for any loss or corruption of data in connection
                    with the use of Three Services. We're not liable for any
                    loss or damage that was not reasonably foreseeable when you
                    entered into the agreement; and
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "12.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we're not liable for any service, goods and content you may
                    take from third parties, including if they are defective or
                    deficient, and any dispute with a third party will not
                    affect your obligations to us.
                  </span>
                </p>
              </li>
            </ol>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.85pt",
                    textAlign: "justify",
                    marginTop: "3.95pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Nothing in this agreement removes or limits our liability
                    for fraud, death or personal injury caused by our negligence
                    or for any liability which can't be limited or excluded by
                    law. If you're a consumer, you also have other legal rights
                    and remedies that apply in addition to any provided to you
                    under Section 5.13(b) of this agreement or at common law.
                    Some of the key legal rights you have as a consumer are
                    contained in the Consumer Rights Act 2015, which provides
                    legal remedies to you where we have, for example, not
                    exercised reasonable care and skill in providing Three
                    Services, or where goods or digital content we supply to you
                    are faulty or not as described. These remedies may include,
                    for example, the right to ask us to fix the problem or to a
                    price reduction. Consumer law also gives you rights if we
                    provide you with misleading information that leads you to
                    enter into a contract with us. For more information on your
                    legal rights (also known as your statutory rights), contact
                    your local authority Trading Standards Department or
                    Citizen's Advice Bureau.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Three Services - Area where we have no responsibility
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.25pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We'll try to ensure the accuracy, quality and timely
                    delivery of Three Services. However:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.1pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    we accept no responsibility for any use of, or reliance on,
                    Three Services, or for any disruptions to, or any failures
                    or delays in, Three Services. This includes, without
                    limitation, any alert services or virus detection services;
                    and
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.8pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    subject to Section 12.3 we don't make any representations as
                    to the accuracy, comprehensiveness, completeness, quality,
                    error free nature, compatibility, security or fitness for
                    purpose of Three Services. They are provided to you on an
                    ‘as is' basis.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We won't be liable:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.95pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    for any loss you may incur as a result of someone using your
                    PINs or passwords with, or without, your knowledge; or
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.5pt",
                    textAlign: "justify",
                    marginTop: "8.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    if we cannot carry out our duties, or provide Three
                    Services, because of something beyond our control.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Others’ content and Services - Areas where we have no
                responsibility.
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={6}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You may be able to use Three Services:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.65pt",
                  paddingLeft: "17.4pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    to upload, email or transmit content; and
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    to access content which is branded or provided by others and
                    to obtain goods and services from others, which may be in a
                    digital form. Where we provide you with such access, all we
                    do is transmit the content to you and we don't exercise
                    control over the content, goods or services. We're not
                    responsible or liable in any way for, and don't endorse, any
                    of this content, goods or services, including any content,
                    goods and/or services that you may pay for using Three
                    Services. You also accept that we have no responsibility for
                    information you supply to third parties who provide content,
                    goods or services on the Three Network.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={7}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    This Section 12 will apply even after this agreement has
                    ended.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={13}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Privacy Notice and Your Information
                  </span>
                </h2>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We are the data controller of Your Information (as defined
                    in Section 13.5) collected through your use of the Three
                    Services for the purpose of UK data protection law. We'll
                    only use Your Information in accordance with this notice and
                    applicable UK data protection and privacy laws. Please read
                    all of this notice and feel free to contact us at the
                    address in Section 13.9 below with any questions.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.5pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Whenever you provide us with personal information about
                    yourself, you agree that it will be true, complete and
                    accurate. You must tell us if this information changes.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you provide us with information about another individual
                    or register a Device in the name of another individual you
                    must have their agreement to do so or be acting with legal
                    authority.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.1pt",
                    textAlign: "justify",
                    marginTop: "3.95pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If we reasonably believe that you have supplied us with
                    false or inaccurate information, or if we suspect fraud, we
                    may delay your Connection or suspend your access to Three
                    Services until an investigation has been completed to our
                    satisfaction.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.5pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    In order to supply you with Three Services under this
                    agreement, we may process Your Information. By “Your
                    Information” we mean personally identifiable information:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.75pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    that you give us or that we obtain about you as a result of
                    any application or registration for, and use of, Three
                    Services. It may include your name, current and previous
                    address(es), date of birth, phone and fax numbers, gender,
                    email address, employment and lifestyle information, bank
                    and credit or debit card information, and information
                    obtained from credit reference and fraud prevention
                    agencies, marketing organisations and those who provide
                    services to us, and may include information from other
                    countries, and.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.85pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    acquired and processed about your use of Three Services
                    while you're a customer of Three, including Location Data,
                    your Communications Data, dynamic IP addresses, your phone
                    number, the unique code identifying your Phone and SIM or
                    eSIM Profile, and your account information, including
                    contact history notes.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={6}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.4pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Some of Your Information may be classified as “sensitive”
                    (such as visual or hearing impairments) and we'll ask your
                    permission if we wish to use or share this information.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={7}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    When you make a call, the calling line identity (“CLI”) of
                    your phone (your Three phone number) will be displayed on
                    the phone of the person you call. If you don't wish your CLI
                    to be displayed and/or transmitted you should check your
                    user guide or contact Three Customer Services. Your CLI
                    cannot be blocked when calling the emergency services, or
                    when sending a text, or MMS.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={8}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You must keep any passwords and PIN numbers relating to your
                    Three account and Three Services safe and secure. You must
                    not share them with anyone else. If you find or suspect that
                    anyone else
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "41.3pt",
                marginRight: "2.5pt",
                textAlign: "justify",
                marginTop: "8.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                knows your passwords or PIN numbers, or can guess them, you must
                contact us immediately and ask us to change them. This is your
                responsibility.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={9}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you have any questions about this notice or the way in
                    which Your Information is processed, please contact our Data
                    Protection and Privacy Officer, by writing to Hutchison 3G
                    UK Ltd, 450 Longwater Avenue, Green Park, Reading,
                    Berkshire, RG2 6GF or by sending an email to&nbsp;
                  </span>
                  <a
                    href="mailto:DPA.Officer@three.co.uk"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",
                        color: "#0000ff",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      DPA.Officer@three.co.uk
                    </span>
                  </a>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={10}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may be required to process Your Information to comply
                    with our legal requirements, to enable us to fulfil the
                    terms of our contract with you or in preparation of us
                    entering into a contract with you. If you do not provide the
                    relevant information to us, we may not be able to provide
                    the service to you.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Your Information received from other sources
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={11}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.1pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may receive personal data about you from credit reference
                    agencies, fraud prevention agencies, marketing partners, the
                    electoral register and other commercial partners who may
                    deliver services to us.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Use of Your Information.
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={12}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.1pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Your Information may be used by us, our employees, service
                    providers and disclosed to third parties for the purposes
                    set out below. For each of these purposes, we have set out
                    the legal basis on which we use Your Information.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.1pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Credit Referencing, Identity Checks and Fraud Prevention
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "2pt",
                textIndent: "0.20000000000000284pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                In order to process your application, we will perform credit and
                identity checks on you with one or more credit reference
                agencies (“CRAs”) such as Call Credit, Experian and Equifax. To
                do this, we will supply your personal information to CRAs and
                they will give us information about you. This will include
                information from your credit application and about your
                financial situation and financial history. CRAs will supply to
                us both public (including the electoral
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 11,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAaCAYAAACzdqxAAAAAAXNSR0IArs4c6QAAAkhJREFUSEu11kuIjnEUx/HPNK6N+y2kCSElyYKNWJCtsiHlWnJJpLBRbLBiI5JLZCxkyV6MUgqLKVJIktwiuYTBuPzP9H+mp3de4zVvzuZ9nv//Od/nPOd/zu+8DbrbNSzAcLzL2wewJF/PLLkcx0YsSj5XyqiGCu5EPMYxbCntnU3ra/J9pc8nfMMo/Ch8yg/1w1vE7zB8LoHPYGW+j/2yrcI5XMbiauCD2Il92FslRT0tRcqGYgKexYNFxP3xHCMqclsrfwNO4CoWlsEtWI1bmFuFNgOj83prlf0hOY2NGIj2IuIvGICt6RCOVnHs6fCKx+OLx+EQdgU40tCed8en8nnRS/ByXMDXCDLAUaO7cxU0/SGptUTcjCfZvxP8K9+8zJ9SjV0LOPL8Pjs3l8FxqpvqiDhcIw1R55fK4MOphbfXCS7qua0MXo/TdYLfYGRRx0WO/xv4SCrybXVG/AGD0VpOxXXMrxMcKtc3qdyeAD9K6jQ5t2RnfnrZIKHfoY5hTQEOkQ7J68h9Hr+VVksdz8HN7NgY4D74nhdiOtztJTg0YkcaEK/ToBhTiNDTrKX7Iz9VwCexLK/HEKhmH5MADcJatBTgKXiY3hib0Zr/alPxIItZVEVHeTQVb+w2GGt4yylEH4QkhDR0TZC4noW2XCXxBbXapJSme2mc/cyzsvO8yhHH9e0kILMxDzdqJMegXYfNiL8DnVY5yiM/0T33k2hPrwE8DXfyvIzIu6wSHBvR1qF0S3HxL/DzKYgVKdKxKeJX5Wd/A3zvdwNQJPX4AAAAAElFTkSuQmCC"
                  width={11}
                  height={13}
                />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5.95pt",
                marginRight: "33.25pt",
                textAlign: "justify",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                register) and shared credit, financial situation and financial
                history information and fraud prevention information. We will
                use this information to:
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "8.149999999999999pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.25pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Assess your creditworthiness and whether you can afford to
                    take the product;
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "8.149999999999999pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.16",
                    marginRight: "33.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Verify the accuracy of the data you have provided to us;
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "8.149999999999999pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.16",
                    marginRight: "33.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Prevent criminal activity, fraud and money laundering;
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "8pt",
                  paddingLeft: "0.14999999999999858pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Manage your account(s);
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "8pt",
                  paddingLeft: "0.14999999999999858pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Trace and recover debts; and
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "8.149999999999999pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Ensure any offers provided to you are appropriate to your
                    circumstances.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "8.149999999999999pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.75pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We will continue to exchange information about you with CRAs
                    while you have a relationship with us. We will also inform
                    the CRAs about your settled accounts. If you borrow and do
                    not repay in full and on time, CRAs will record the
                    outstanding debt. This information may be supplied to other
                    organisations by CRAs. When CRAs receive a search from us
                    they will place a search footprint on your credit file that
                    may be seen by other lenders.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "8.149999999999999pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.8pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you are making a joint application, or tell us that you
                    have a spouse or financial associate, we will link your
                    records together, so you should make sure you discuss this
                    with them, and share with them this information, before
                    lodging the application. CRAs will also link yourrecords
                    together and these links will remain on your and their files
                    until such
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
            </ul>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "150pt",
                marginRight: "2.2pt",
                textAlign: "justify",
                marginTop: "8.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                time as you or your partner successfully files for a
                disassociation with the CRAs to break that link.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "114pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The identities of the CRAs, their role also as fraud
                    prevention agencies, the data they hold, the ways in which
                    they use and share personal information, data retention
                    periods and your data protection rights with the CRAs are
                    explained in more detail in the Credit Reference Agency
                    Information Notice
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      className="Apple-tab-span"
                      style={{ whiteSpace: "pre" }}
                    >
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    (“CRAIN”).
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      className="Apple-tab-span"
                      style={{ whiteSpace: "pre" }}
                    >
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    CRAIN
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      className="Apple-tab-span"
                      style={{ whiteSpace: "pre" }}
                    >
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    is
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      className="Apple-tab-span"
                      style={{ whiteSpace: "pre" }}
                    >
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    also accessible from each of the three CRAs – clicking on
                    any of these three links will also take you to the same
                    CRAIN document: Callcredit
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      className="Apple-tab-span"
                      style={{ whiteSpace: "pre" }}
                    >
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      className="Apple-tab-span"
                      style={{ whiteSpace: "pre" }}
                    >
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </span>
                  <a
                    href="http://www.callcredit.co.uk/crain%3B"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",
                        color: "#0000ff",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      www.transunion.co.uk/crain;
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",
                      color: "#0000ff",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Equifax
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      className="Apple-tab-span"
                      style={{ whiteSpace: "pre" }}
                    >
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      className="Apple-tab-span"
                      style={{ whiteSpace: "pre" }}
                    >
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      className="Apple-tab-span"
                      style={{ whiteSpace: "pre" }}
                    >
                      &nbsp; &nbsp;&nbsp;
                    </span>
                  </span>
                  <a
                    href="http://www.equifax.co.uk/crain%3B"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",
                        color: "#0000ff",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      www.equifax.co.uk/crain;
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",
                      color: "#0000ff",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Experian&nbsp;
                  </span>
                  <a
                    href="http://www.experian.co.uk/crain"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",
                        color: "#0000ff",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      www.experian.co.uk/crain.
                    </span>
                  </a>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "114pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.1749999999999998",
                    marginRight: "2.1pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We'll use a combination of credit scoring and/or automated
                    decision making systems when assessing your application.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "10pt",
                  fontFamily: '"Noto Sans Symbols",sans-serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "114pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The legal basis that we rely on to process Your Information
                    for the above purpose is for performance of a contract
                    between you and us or in order for us to take steps prior to
                    entering into a contract with you or our legitimate business
                    interests in order for us to manage our relationship with
                    you.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "2pt",
                textIndent: "4.299999999999997pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                We'll also disclose details of your agreement with us, the
                payments you make under it, account balances and information
                about any default, dispute, and debts to CRAs. We'll also
                disclose details of any change of address reported to us or
                which we become aware of. Credit searches and the information
                supplied by us and held by CRAs is used by us and other
                organisations to help make decisions about other credit
                applications by you or other members of your household with whom
                you're linked financially to trace debtors, recover debts, to
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 13,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAAAXNSR0IArs4c6QAAAmxJREFUSEu9lk+IzVEUxz9jBmEmJiH5GyaNEIuxwEZJmrCTkthY2LCYZutPrIRENspiJCk7ShY2NpMFWZBoRpQoSRJi/JkZ9/s6p447v/dq5veeU69f3fvu+dzz555zmhgrd4HtQBvwzbbPAduAL8CmcOQ00A18BLaE9QvAEWAD8DAimjLeUuA18BxYFfauAvuBz0B7WL8G7APeAEsyXcPAJPuN+l4ENgM/gBZgKvA7KLgSgHPDeh+w14ArMuABQPt3gB1FwKPpNieBG6akwNvjXlJIZphX5B3cQln3x9RNN0vHrb3gwGHgIvAC6IzA3nSTM3EjO7wOmAX8BB6EvZXAfHN/f5UbeiynAUNuoQd1T0qWmwUHJ5I0ruY9MC9d7DJwSMApdvNocc4sAzwBHAdkaYuAu82qX5adRZ4pA5R1slJSAQ4AHSntXwHLq8ShDFAqRyxBuwX0+D1Kb6arQUC9AL2EsxFYCWqDgN8BZelABOrRK7j1jqH0fU3WtXpWuktPJV8f+5/AWiWtbNKoLqtG35dLPaDPgNUNstAZBwV8DKxP/esTMLtBQH8WiwVUWxm0t6LUrXfS7EoG3TKlzQKqSarsSNRcK20kkzIxvA3stOmhzYv3B2BOqjTq4OrsuVyyzq7LaCpw0dtVwVfHX1vFO0NWMjcD/Q7UOKGkqVVPq+irubzILqM/yZOjDtRXgZWoUaph1kPUIzcC59MA1iOFcaZZAzwB3gEL60BTKdOUp/c32SeKfGp7Cyyw7vGyJPS6zUb/NPUc6M1YN5tZAqjBSQOURs5lUU8O1N7WNOzes8FX34nIU6taYwayv9/BqAoAgQBsAAAAAElFTkSuQmCC"
                  width={13}
                  height={13}
                />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.9pt",
                marginRight: "32.75pt",
                textAlign: "justify",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                prevent and detect fraud and to manage your account. The legal
                basis that we rely on to process Your Information for the above
                purpose is our legitimate business interests in order for us to
                manage our relationship with you.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "77.2pt",
                marginRight: "32.65pt",
                textIndent: "-34.7pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
                padding: "0pt 0pt 0pt 34.7pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span
                  style={{
                    border: "none",
                    display: "inline-block",
                    overflow: "hidden",
                    width: 16,
                    height: 13,
                  }}
                >
                  <img
                    src="blob:https://wordtohtml.net/66429b83-81b1-44d2-87da-46c8efefb9e0"
                    width={16}
                    height={13}
                    data-fr-old-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAAAXNSR0IArs4c6QAAApNJREFUSEvNl0uIz1EUxz9jvBnGwniW8tp4l6REsWBBkUTIimY1NohIdjQWlDRF2FEshGw8NoomlAXFyisLCeWR8Rb3+++c6fjN77/7/X4zd/X/n/O793zuueeec24DPcdlYDUwDXhq6o3APqALWBimHAOWAR+AJUF+AlgEfAQWB/lJoBWYCTyOphsyHOPSh6+BG8CKoNsNHDaQ4UF+AVgPvAdGB/lVYBXwFhiTsfEdGAT0A/66LoJI8QkYZh/F+buAQwYyKijOBpDxQX4JWGkgEzMgW4HTwBlgWx6Iu60j7bAt58iKFOnIRgItwDst7B6RN/6YpaHAtyKt5qy1AzgC3AKWRhB54Hhy8xNgRs5EuX068Bu4E/RzgWZA5343yLWGYuYn0FlnU9q4HFDbuHvEg2YncDRnYpHB6ss/AyYDe4F2gYywINUHimbtIjvKAGlPnthjAT1WIJsBRb/cPqCOG8sA0e38YvaaBfISmAR8tkjOYykDRHY8JBYIxP/oGuk6VQmiU2gETkUQnZkCp0qQHylRDgReRJCDKU72VwzyNZWOIZ5H/Gj6DEi20EXnlBWsXgCvxKPJVtAqQPw0tgvkIrDW6ovSbZXB6iDzBLIcuN4LCW0NoHZBo1EgsfLOSd55VFGK96ZK2bXJi54aItUcpfotOSAq2wesMZoQ9Gpw1lmHNjXIZUQdnjo0Ve28oVZjMCDP1IJVY5Z54pclmDpzCxV7fPRXLxRbRUFIOB94UKjJnotdM48pgSp/dXdo+j0beAi8AdRElzV0MxUK2rSqverNfyDyzn3ziKrxq5JIzgMb7LbedBvZ54QI1Rg9B6aUAKLqrufKPXv3dJvIgkixKT2uztmD6XbBMN77NIWmqGbiH7GizCTC0YN5AAAAAElFTkSuQmCC"
                  />
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: '"Times New Roman",serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                We may also check and share your details with fraud prevention
                agencies such as Action Fraud and CIFAS and we'll record (and
                pass to the fraud prevention agencies) details of any false or
                inaccurate information provided by you or where we suspect
                fraud. Records held by fraud prevention agencies will also be
                used by us and other organisations to help prevent fraud and
                money laundering, for example, when checking details on
                applications for credit or other facilities, managing credit and
                credit-related accounts or facilities, recovering debt, checking
                details on proposals and claims for all types of insurance and
                checking job applications and employees. Those fraud prevention
                agencies may disclose information to law enforcement agencies
                where requested and necessary for the investigation of crime. We
                and other organisations may access and use (from a country other
                than the UK) the information recorded by fraud prevention
                agencies. The legal basis that we rely on to process Your
                Information for theabove purpose is the performance of a
                contract between you and us or in order for us to take
                stepsprior to entering into a contract with you or our
                legitimate business interests in order for us to manage our
                relationship with you.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.9pt",
                marginRight: "32.85pt",
                textIndent: "7.799999999999997pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                We may also use and share your details for the collection of any
                debts owed by you. This may include the use of debt collection
                agencies to collect debts on our behalf or may include the
                assignment of debts to a third party company. The assignment of
                debts will involve the sale of your debt and account information
                to a third party company - this information may include your
                name, address and contact information, year of birth, debts
                owed,
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 17,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAA0JJREFUSEu1l0mojlEYx3/XeG8yy3SvMRlKyZBxQSmKDTaGy8ICJSEWLJRrRbIwJIWFrmnJyrygzFNKuJIp80xkns7/9pzb8Trfd9/3637P5v065xn+55m/Ev6nA8AUoCfw0K6nA6uAt8DYiEzWoz1AJTASuBAKlyQ09QAeAGeBMcHdcmAD8AromNV6Dv5vQDOgMfDb84SAGgEfgBaAfoe0DFhngMobCNBiYBNwDJgYA7QI2ALsAOY3kNH61LwD2gCtgI9i9h7SV277A3SwXKlPWUPcr3ZKqoDHQLcQkMKxAnhjgJLGugD9AMX9nF2Os+8Tl6B38qDrA1TY/ckIn5wgKpV+76H3QGtARk5FhGJJ/cIS/BowOA+gS8AwQN/hET7lrUK2T5UnQE2AH8aoeIohSTFAM5zX9luo9ZhPEbm2wGsrktGBd0PWWcBe4BfQVIB8uARKZRijGCDxKoSiBcD2iKCvJF3p4TKapM5O/pkdlgnQF4ufQqDLtIDEVw3MAV4CnSKCeqSAHASm5tCtFiM+fQcJkE+qZDMM5XM1RiXrI2PsGrxURwOAm3bXPeCL4fqucAHrQ0Db3EsXZvSQ2L2HNwNLAvndwOw8lRua8jpqQkBbXQKqOWYJmXhXAmuBz1apP03BV3fe3M3DuW4u7sqh1x97QLWN0YesUEBqpJpxIpW1yrs/cMvOclVuzEP/ANIEVoJm9ZD4r1ovOu4G8wTgBDDeJak2h2n1eEfXftBWhx66CIwoEJCG4xEraw1n9TKFqzdwPwUgX41VAnQFGOJyQTEvKxCQxNRjVLprXBpoRomS601MfdiYyyUwFLhsHVe7SSEhk8xGqzIBkx6tFktTeGcgcN34SgVIwr4y2jmlWgmSlGZB88udl9VQvZsC0GlbBjWkK7xLVRGqDJ+UST3qLwqF5pIM5aIa6/YCIs+nIbULpcpkV6WHPCB1WSHMN8/SKM/K0xe4bUK1q2yYdNqFFLJJrtoOZ9VcIP9RaxN1PTAE5JP7OaCFrNjUHpAtVVndJpAsy3tAL1u8fPctFjD/d2ueS+ad3kgSkN9x1NjU8otF+iv1FLihlSM0EmtcM22dHAWcLxKiM+7vjzbIlslN8y8TXrrx32bYrwAAAABJRU5ErkJggg=="
                  width={17}
                  height={13}
                />
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "2.1pt",
                textAlign: "justify",
                marginTop: "8.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                payment history and other information necessary to help recover
                the debt. The legal basis that we rely on to process Your
                Information for the above purpose is legitimate interest in
                order to manage our relationship with you.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "2.25pt",
                textIndent: "3.5999999999999943pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                We may also pass and share information to other communications
                service providers and network operators for the detection and
                prevention of theft and fraud. The legal basis that we rely on
                to process Your Information for the above purpose is legitimate
                interest in order to manage our relationship with you.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 14,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAupJREFUSEutllmIjlEYx39jGtm3QpSl0BCJhCQ7xQUu3EmJoqS5mGSJuLBEkSQXZLngwp1MyRLZky0uFBdIoURkN3bOf3oene/1zvt+X/M+Nc33nvOc5/+s/3Oq+F9OArOAjsCnlP1KlrYCa4AJwNX4YFXCSn/gCXAZmFQJQobuN6A1UA38dr0YWBuNgNa6AJ8LAp4LHAdOWyabzMbAu4E64ACwpCBQN6Mg2gGdgQ8xcCvgl2l1A94WDLwU2AdcAibHwB7tI2BQBKqMeK1fAfczHKoNxnvZ/sUUPdVX9hR5o37oTxEqDbOBE4lDD4DBwAugdwbwTWA0IAd7pui9BHqE/tkF1Au0Bvhuisku1/JY4HpOGdpHozc1ZOlCCvB6YKPjCGhbCH018MPaPnlG3a6R0P/NoRdkICnrbE/rJWMTKXYAPtp3jYBV1wHWbUp3muwBlltUIpakvAc6AduBVRnlUAOrkUcJ+I8pXgEmNnOoD/C0mXR3t7pqu1+kl2ZKJVVp98fAhwNrLczw9gvQFjgKzI/0joSsLbAG1ShmiQiqDXAvBt4UKG1DxqlFwKHAvV/NAVd1Y/XWsVnA4n41YtMoearzgKXvukNtpscEGrxhSGkTkXQiFfhYSMG8nFSdA6YF9hFBTAH8W7MvDsgTvzAa4oivAeNzTo4DpJfM1kDgcR4q8NPGrU7Ad4CRYQzUPE35zxCNgjpTs6p7Vhyg2dQo5Ul8H9QKWN4+rKBOa8NIbLFLRQ7sCFfoyjxUzS5w2/SqBRx70hV4l2NEfCvedekLPCsD+Gx4hUz3AL0T/SLYGVK3ogwjeqXIyefAsDL0paKAxIwz1JQOPAK4a8UXsxQtIpY3nmY9geLZc77VbN4qGPlUuOFmWjaV1ZKnj+byPPAaEP8WKU48evTpFiwB1reaRqB6bfql0FIHDgZbi4PtZcBeN5akOX8UFBW1eEEl1JNpeBxBGr/OCYTSYDU508JwBTjEiEkE9U/+ApnEj/8zEem4AAAAAElFTkSuQmCC"
                  width={14}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.65pt",
                  paddingLeft: "17.4pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Account and Service Management
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "1.9pt",
                textIndent: "11.400000000000006pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To process applications, registrations or orders made by you, to
                create and administer accounts, to calculate and charge for
                Three Services, to produce any necessary invoices or billing
                statements, and to provide to Three Customer Services, including
                for the management of any complaints or queries. The legal basis
                that we rely on to process Your Information for the above
                purpose is for performance of a contract between you and us or
                in order for us to take steps prior to entering into a contract
                with you.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 11,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAAAXNSR0IArs4c6QAAAmxJREFUSEu9lk+IzVEUxz9jBmEmJiH5GyaNEIuxwEZJmrCTkthY2LCYZutPrIRENspiJCk7ShY2NpMFWZBoRpQoSRJi/JkZ9/s6p447v/dq5veeU69f3fvu+dzz555zmhgrd4HtQBvwzbbPAduAL8CmcOQ00A18BLaE9QvAEWAD8DAimjLeUuA18BxYFfauAvuBz0B7WL8G7APeAEsyXcPAJPuN+l4ENgM/gBZgKvA7KLgSgHPDeh+w14ArMuABQPt3gB1FwKPpNieBG6akwNvjXlJIZphX5B3cQln3x9RNN0vHrb3gwGHgIvAC6IzA3nSTM3EjO7wOmAX8BB6EvZXAfHN/f5UbeiynAUNuoQd1T0qWmwUHJ5I0ruY9MC9d7DJwSMApdvNocc4sAzwBHAdkaYuAu82qX5adRZ4pA5R1slJSAQ4AHSntXwHLq8ShDFAqRyxBuwX0+D1Kb6arQUC9AL2EsxFYCWqDgN8BZelABOrRK7j1jqH0fU3WtXpWuktPJV8f+5/AWiWtbNKoLqtG35dLPaDPgNUNstAZBwV8DKxP/esTMLtBQH8WiwVUWxm0t6LUrXfS7EoG3TKlzQKqSarsSNRcK20kkzIxvA3stOmhzYv3B2BOqjTq4OrsuVyyzq7LaCpw0dtVwVfHX1vFO0NWMjcD/Q7UOKGkqVVPq+irubzILqM/yZOjDtRXgZWoUaph1kPUIzcC59MA1iOFcaZZAzwB3gEL60BTKdOUp/c32SeKfGp7Cyyw7vGyJPS6zUb/NPUc6M1YN5tZAqjBSQOURs5lUU8O1N7WNOzes8FX34nIU6taYwayv9/BqAoAgQBsAAAAAElFTkSuQmCC"
                  height={26}
                  width={28}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "2.05pt",
                textIndent: "9.349999999999994pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To supply any products, services or information requested by you
                and/or which we may provide. The legal basis that we rely on to
                process Your Information for the above purpose is for
                performance of a contract between you and us or in order for us
                to take steps prior to entering into a contract with you.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 13,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAAAXNSR0IArs4c6QAAAmxJREFUSEu9lk+IzVEUxz9jBmEmJiH5GyaNEIuxwEZJmrCTkthY2LCYZutPrIRENspiJCk7ShY2NpMFWZBoRpQoSRJi/JkZ9/s6p447v/dq5veeU69f3fvu+dzz555zmhgrd4HtQBvwzbbPAduAL8CmcOQ00A18BLaE9QvAEWAD8DAimjLeUuA18BxYFfauAvuBz0B7WL8G7APeAEsyXcPAJPuN+l4ENgM/gBZgKvA7KLgSgHPDeh+w14ArMuABQPt3gB1FwKPpNieBG6akwNvjXlJIZphX5B3cQln3x9RNN0vHrb3gwGHgIvAC6IzA3nSTM3EjO7wOmAX8BB6EvZXAfHN/f5UbeiynAUNuoQd1T0qWmwUHJ5I0ruY9MC9d7DJwSMApdvNocc4sAzwBHAdkaYuAu82qX5adRZ4pA5R1slJSAQ4AHSntXwHLq8ShDFAqRyxBuwX0+D1Kb6arQUC9AL2EsxFYCWqDgN8BZelABOrRK7j1jqH0fU3WtXpWuktPJV8f+5/AWiWtbNKoLqtG35dLPaDPgNUNstAZBwV8DKxP/esTMLtBQH8WiwVUWxm0t6LUrXfS7EoG3TKlzQKqSarsSNRcK20kkzIxvA3stOmhzYv3B2BOqjTq4OrsuVyyzq7LaCpw0dtVwVfHX1vFO0NWMjcD/Q7UOKGkqVVPq+irubzILqM/yZOjDtRXgZWoUaph1kPUIzcC59MA1iOFcaZZAzwB3gEL60BTKdOUp/c32SeKfGp7Cyyw7vGyJPS6zUb/NPUc6M1YN5tZAqjBSQOURs5lUU8O1N7WNOzes8FX34nIU6taYwayv9/BqAoAgQBsAAAAAElFTkSuQmCC"
                  width={13}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "2.25pt",
                textIndent: "11.75pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                For traffic and billing management, which may involve the use of
                Your Information. We deploy a balance of technical, logical and
                security controls to protect the processing of Your Information
                on the Three network. The legal basis that we rely on to process
                Your Information for the above purpose is
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 16,
                  height: 13,
                }}
              >
                <img
                  src="blob:https://wordtohtml.net/0972d791-21a2-422c-b091-0b197cdd1311"
                  width={16}
                  height={13}
                  data-fr-old-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAAAXNSR0IArs4c6QAAApNJREFUSEvNl0uIz1EUxz9jvBnGwniW8tp4l6REsWBBkUTIimY1NohIdjQWlDRF2FEshGw8NoomlAXFyisLCeWR8Rb3+++c6fjN77/7/X4zd/X/n/O793zuueeec24DPcdlYDUwDXhq6o3APqALWBimHAOWAR+AJUF+AlgEfAQWB/lJoBWYCTyOphsyHOPSh6+BG8CKoNsNHDaQ4UF+AVgPvAdGB/lVYBXwFhiTsfEdGAT0A/66LoJI8QkYZh/F+buAQwYyKijOBpDxQX4JWGkgEzMgW4HTwBlgWx6Iu60j7bAt58iKFOnIRgItwDst7B6RN/6YpaHAtyKt5qy1AzgC3AKWRhB54Hhy8xNgRs5EuX068Bu4E/RzgWZA5343yLWGYuYn0FlnU9q4HFDbuHvEg2YncDRnYpHB6ss/AyYDe4F2gYywINUHimbtIjvKAGlPnthjAT1WIJsBRb/cPqCOG8sA0e38YvaaBfISmAR8tkjOYykDRHY8JBYIxP/oGuk6VQmiU2gETkUQnZkCp0qQHylRDgReRJCDKU72VwzyNZWOIZ5H/Gj6DEi20EXnlBWsXgCvxKPJVtAqQPw0tgvkIrDW6ovSbZXB6iDzBLIcuN4LCW0NoHZBo1EgsfLOSd55VFGK96ZK2bXJi54aItUcpfotOSAq2wesMZoQ9Gpw1lmHNjXIZUQdnjo0Ve28oVZjMCDP1IJVY5Z54pclmDpzCxV7fPRXLxRbRUFIOB94UKjJnotdM48pgSp/dXdo+j0beAi8AdRElzV0MxUK2rSqverNfyDyzn3ziKrxq5JIzgMb7LbedBvZ54QI1Rg9B6aUAKLqrufKPXv3dJvIgkixKT2uztmD6XbBMN77NIWmqGbiH7GizCTC0YN5AAAAAElFTkSuQmCC"
                />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "97.3pt",
                marginRight: "33.1pt",
                textAlign: "justify",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                legitimate interest and/or to enable us to improve and develop
                our business operations and the services.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "97.3pt",
                marginRight: "32.95pt",
                textIndent: "1.6500000000000057pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To ensure the accuracy and performance of Three Services. This
                may involve the use of Your Information in a live test
                environment. The legal basis that we rely on to process Your
                Information for the above purpose is legitimate interest and/or
                to enable us to improve and develop our business operations and
                services.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 17,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAA0JJREFUSEu1l0mojlEYx3/XeG8yy3SvMRlKyZBxQSmKDTaGy8ICJSEWLJRrRbIwJIWFrmnJyrygzFNKuJIp80xkns7/9pzb8Trfd9/3637P5v065xn+55m/Ev6nA8AUoCfw0K6nA6uAt8DYiEzWoz1AJTASuBAKlyQ09QAeAGeBMcHdcmAD8AromNV6Dv5vQDOgMfDb84SAGgEfgBaAfoe0DFhngMobCNBiYBNwDJgYA7QI2ALsAOY3kNH61LwD2gCtgI9i9h7SV277A3SwXKlPWUPcr3ZKqoDHQLcQkMKxAnhjgJLGugD9AMX9nF2Os+8Tl6B38qDrA1TY/ckIn5wgKpV+76H3QGtARk5FhGJJ/cIS/BowOA+gS8AwQN/hET7lrUK2T5UnQE2AH8aoeIohSTFAM5zX9luo9ZhPEbm2wGsrktGBd0PWWcBe4BfQVIB8uARKZRijGCDxKoSiBcD2iKCvJF3p4TKapM5O/pkdlgnQF4ufQqDLtIDEVw3MAV4CnSKCeqSAHASm5tCtFiM+fQcJkE+qZDMM5XM1RiXrI2PsGrxURwOAm3bXPeCL4fqucAHrQ0Db3EsXZvSQ2L2HNwNLAvndwOw8lRua8jpqQkBbXQKqOWYJmXhXAmuBz1apP03BV3fe3M3DuW4u7sqh1x97QLWN0YesUEBqpJpxIpW1yrs/cMvOclVuzEP/ANIEVoJm9ZD4r1ovOu4G8wTgBDDeJak2h2n1eEfXftBWhx66CIwoEJCG4xEraw1n9TKFqzdwPwUgX41VAnQFGOJyQTEvKxCQxNRjVLprXBpoRomS601MfdiYyyUwFLhsHVe7SSEhk8xGqzIBkx6tFktTeGcgcN34SgVIwr4y2jmlWgmSlGZB88udl9VQvZsC0GlbBjWkK7xLVRGqDJ+UST3qLwqF5pIM5aIa6/YCIs+nIbULpcpkV6WHPCB1WSHMN8/SKM/K0xe4bUK1q2yYdNqFFLJJrtoOZ9VcIP9RaxN1PTAE5JP7OaCFrNjUHpAtVVndJpAsy3tAL1u8fPctFjD/d2ueS+ad3kgSkN9x1NjU8otF+iv1FLihlSM0EmtcM22dHAWcLxKiM+7vjzbIlslN8y8TXrrx32bYrwAAAABJRU5ErkJggg=="
                  width={17}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "97.3pt",
                marginRight: "32.9pt",
                textIndent: "2.25pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To update your Device remotely “over the air” with software
                updates and to investigate and resolve any Service related
                queries made by you. The legalbasis that we rely on to process
                Your Information for the above purpose is performance of our
                contract with you.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 14,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAA0JJREFUSEu1l0mojlEYx3/XeG8yy3SvMRlKyZBxQSmKDTaGy8ICJSEWLJRrRbIwJIWFrmnJyrygzFNKuJIp80xkns7/9pzb8Trfd9/3637P5v065xn+55m/Ev6nA8AUoCfw0K6nA6uAt8DYiEzWoz1AJTASuBAKlyQ09QAeAGeBMcHdcmAD8AromNV6Dv5vQDOgMfDb84SAGgEfgBaAfoe0DFhngMobCNBiYBNwDJgYA7QI2ALsAOY3kNH61LwD2gCtgI9i9h7SV277A3SwXKlPWUPcr3ZKqoDHQLcQkMKxAnhjgJLGugD9AMX9nF2Os+8Tl6B38qDrA1TY/ckIn5wgKpV+76H3QGtARk5FhGJJ/cIS/BowOA+gS8AwQN/hET7lrUK2T5UnQE2AH8aoeIohSTFAM5zX9luo9ZhPEbm2wGsrktGBd0PWWcBe4BfQVIB8uARKZRijGCDxKoSiBcD2iKCvJF3p4TKapM5O/pkdlgnQF4ufQqDLtIDEVw3MAV4CnSKCeqSAHASm5tCtFiM+fQcJkE+qZDMM5XM1RiXrI2PsGrxURwOAm3bXPeCL4fqucAHrQ0Db3EsXZvSQ2L2HNwNLAvndwOw8lRua8jpqQkBbXQKqOWYJmXhXAmuBz1apP03BV3fe3M3DuW4u7sqh1x97QLWN0YesUEBqpJpxIpW1yrs/cMvOclVuzEP/ANIEVoJm9ZD4r1ovOu4G8wTgBDDeJak2h2n1eEfXftBWhx66CIwoEJCG4xEraw1n9TKFqzdwPwUgX41VAnQFGOJyQTEvKxCQxNRjVLprXBpoRomS601MfdiYyyUwFLhsHVe7SSEhk8xGqzIBkx6tFktTeGcgcN34SgVIwr4y2jmlWgmSlGZB88udl9VQvZsC0GlbBjWkK7xLVRGqDJ+UST3qLwqF5pIM5aIa6/YCIs+nIbULpcpkV6WHPCB1WSHMN8/SKM/K0xe4bUK1q2yYdNqFFLJJrtoOZ9VcIP9RaxN1PTAE5JP7OaCFrNjUHpAtVVndJpAsy3tAL1u8fPctFjD/d2ueS+ad3kgSkN9x1NjU8otF+iv1FLihlSM0EmtcM22dHAWcLxKiM+7vjzbIlslN8y8TXrrx32bYrwAAAABJRU5ErkJggg=="
                  height={26}
                  width={36}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "97.3pt",
                marginRight: "33pt",
                textIndent: "2.75pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To process data revealing the geographic location of your Device
                in order to provide location based services requested by you and
                which may be provided by Three or by third parties on behalf of
                Three, or where you request location based services directly
                from third parties. We may share your Location Data with the
                emergency services if you call 999 or 112 using a Device with
                Android operating system 2.3 and above within the UK. This is to
                help the emergency services more accurately locate you in the
                event of an emergency where you may not know, or be able to
                communicate, your location. The legal basis that we rely on to
                process Your Information for the above purpose is for
                performance of contractual obligations between us and us.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 17,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAAzFJREFUSEu1l0eMTlEUx3+DYSZqtOhtoccoiR6xIlESltrCgoUIiY3ExlhIRGKBiAULUZeEIFggStQVYljoNXrU0e//y7mT63nvffebcpKXV+455/7v6a+M/+kgMAfoBzxMWS/l0x5gJPAYmBEI7gUWAOOBy6HCsoT2vsAD4CIwqZSdM3jPm577wIAETy3QEmgO/PZrIaBmwAegNaDnxqCzwAQ75MCEwhXAZuAkMD0N0HJgK7ADWNoYaCJ0vAM6AO2Aj+L3FtJdZvsDdAbeRihrDJa1Tkk18AToHQLaAKwG3hggv5lcN8VeFFu6skgx0scWz9h9DNAW+AxczRCUEUQVQK230HugPTAVkN9DugkMA9ICM+RTtowNTwvkBbWXVdzKZfuVeQLUAvhhq/KnGEKaDRw2l3Y1K6Yd1p9U6SzlohhA84F9wC+gXIC8uwRKaZi32RZgZQrDemCNu74DrYL1GEDdgOcmUylAX81/LwEtptEmYBXwGuiSwvAM6A4cAHRiTzGAFKcyhu5VAuRNnVcM+wP3bJchQE2w6aDgXc93SwQkdlm2HNgYAtoOLMuwkPhUJ1Q0FfQKfk9qDwuBR4CA11XdyBiSHu+lmhDQNucSFccsUi86asGnRPDkLbzY9b9dCeEYl4WACoXRKywGSMH6zTZUbToHDAVu2bdkX9TnBgFSB16UYyEtnTZ3CYxAHQFmATeAESmysYB8o90dWugKMK4IoMlmGbGpS6t2iOa6MeNQAwApyxQG1QJ03RW+0eaOyiKAtOyF1YfWWVtokyEXY6GwMPcUIPWba5YdOnUx8mOD58vLzhhAw83l0lchQALx07R3dJVYI0EeiUdN2JPayasGWMiDfgr08plxGxjsprtTblqcVsxENlEqw9RwR+Xwa/hSw9UoXJXB9wVQqMx0vMc8oB6AEOb1swicJbNoirxjUoVRNqwdcoPcoQJ4vGTV9RM4YR6pq4EhIB/cL6xR1m+LeKlOgPZSlukqlJBkdVUDVT/KC9T4LfM5/e/WEhfMOz1rEpDmIVVNDWka1pqKdGCNLGo7/wR7Wv+ZZxOffl8uNRGiC+73Z6LN25/CPf4CTz248SSrj8MAAAAASUVORK5CYII="
                  width={17}
                  height={13}
                />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "97.3pt",
                marginRight: "33pt",
                textIndent: "5.75pt",
                textAlign: "justify",
                marginTop: "9.35pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                We may monitor and record calls and messages between you and
                Three Customer Services for training and quality purposes. The
                legal basis that we rely on to process Your Information for
                theabove purpose is legitimate interest and/or to
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 20,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAaCAYAAADBuc72AAAAAXNSR0IArs4c6QAAA2dJREFUWEfFl1mojVEUx3/XcEkypMxTmUlkKJlFpiKiPBGFcr2IlMiLB0riCaEbRVG8GF8Ml4RQ5sxkvCkSIbNr/29r1z7b+c7p+s7prLqd7+699t7/vYb/WruMf2UnsAhoBnzKMl+Xoa3AaOAdMC5YuAmYBNwF5gTjG4GVQA/gcXhQWXRqb7fwHnDeDqgLqGy6x4GpwEugc6BwEJgN3AQGRgu/Ao2BhsAvPxcCrQf8tr8WwOe0KIEjwGQD2i3Ybz8wC7gFDInOWQzsAA4DM7IBXQusA/YBcwsAMs0WX4AmQEvggzbyFtXvH6DGgZVlSy2KU8XrI6BnCHQbsCScMKS6wBj7fgE8zXGDjs4b3W3+rP0q/hRGP4ELwdpezr3tzFo3EvaU4XR+I+CHt6gsKVF87IoW3ge08QNAyZYkd4B+gPT7mNL/JJPfX4bpBGwHKgS0vmVXktsnACctLARErBCLrPYWaABMcwlyrABAVzlrbvCeFdBlwBaLUYGORVQhypBIb3kWHYWNwkcSMkkaizb3iSQDaNPvQLlze7Vze4cEvx4wYn5l7ojVXgPtzfITg8k0QLWNj9O2AurjMxv5+jO7AM/sH/FhmFThnGJKl/GSFqh4XSxUGQLdAyxIsKj0BK5rTMSAymSFFQiVXX9xbZUW6Ecr5ddDoJXOGgsTgGpYleSQqMIow6uqjrdyybQ0iNNCWdQDrQ18b4F8QMPgHgxcs+bhoaFSJfFJV1SgpwFRUS6RRWXZy8AwVwyqgLEGWuBjSet6X0qrQouqrVJ7lUvGA6dMQbT1zb7VPKiJKDRQn0yrBdRXHhF26zxA1Xq9B5oan242CpHbRXOFBurpqVxA1WZdzUH48eFqelcEg3sdI8xLuGAa16vRPmP7lglo2DmJflRjc0kb4E2goDXPiwBUzcqAEKi+1fMpqy+66jIiD1BN66aDrNsamkNfyacEVYffP9Db7br7mS4xbwOjEtbrGaQQU7Ws9nVZGXzJ4kxJUmrR5cUswqfKVBM2zrKqKovo5lyJkXraW+962TXCEnY6usUVa+P6lhioHnXq5OR6cWkGUIF+YvV8pMVrKfAqfufb89q/FDKACpTvPZPauWID13NGDKKk1hNIPFor8bteY1OAE64sTgeOFhtZtL/eVcPtBZrRN/wF63ze2UUHqNkAAAAASUVORK5CYII="
                  width={20}
                  height={13}
                />
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginTop: "8pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                enable us to improve and develop our business operations and
                services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginTop: "4.15pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                purpose is our legitimate interests and/or your consent.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "77.19999999999999pt",
                marginRight: "1.9pt",
                textIndent: "-34.650000000000006pt",
                textAlign: "justify",
                marginTop: "4.6pt",
                marginBottom: "0pt",
                padding: "0pt 0pt 0pt 34.650000000000006pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span
                  style={{
                    border: "none",
                    display: "inline-block",
                    overflow: "hidden",
                    width: 23,
                    height: 13,
                  }}
                >
                  <img
                    src="blob:https://wordtohtml.net/07ce75f3-f499-446d-9218-3495bb0dd29e"
                    width={23}
                    height={13}
                    data-fr-old-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAaCAYAAADxNd/XAAAAAXNSR0IArs4c6QAAA7pJREFUWEe9mGmoTVEUx3888yzzEDI8ETIl81yUSIZCmSMf+EBKSil8IOIDJYoylClCkiFDkcyzknnMTOZ52H+tre049+py7l31eu+dvc9e67/X9F+nAH/KZmAAUA+4EbOeyaMFQC/gDtAneHEF0Aa4arr80hqgOXAL6BvsnwtMA/oD20IDCkSsqQHccxvPuo0tMrE0xd6NwGDgCtAw2LMf6AacM4P90mGgA3ANaBA58yPwBqgIfPdrIQD9/QooBhROwHgdsQ4YaACaBGfuBbq42z8PtA6eHwTaA9eBRhEbJgBLgfXA0DgAcvUuYDUwMiEASR/zxDxQE7ivw70H8oAvpq0C8DxpzQmdNw5YDuwGeocAlgHjLWmVvKGH5GqJckOxmUoUmz5MFAqSpoAu5INTeDR4UflVFngHHI95rlg/GaNI77ywi5e+Z94Dj4DKQE9gXwSAKkItqyS10wA4BbQELgfxm2QSe9UvgTLAWpcrwwWgEPA5Tfio3B2zENNtKtHj5C1Qwq1Pcbe7yDZkA8BE59XFwAOghgBMdogWAipTqkBxIoACqh4xKGbDaOellcBrux2/JRsAdLYvo4UEQNlcHXgIVEsBQMbJyKdApZg9Z6ye74g0oGwB+GZ50EUAVHHKu3p9AWiWAoBiX7kgiVYp5cdtW1O4nQjOyDaArQLw3kJnlTNyVAoA2qcKVNcl6AaXqEOCfVOB+dZ86kfezxYARYIu8owM8/GkMBmbpsqMAcRh5D71DS+K+1Iuh6a7ixBnCSVbAB77UM4EQDmrwTJQXXsPUMd17ZtmcUmr6zkH4EPoANA9jQe05JP5ENDZyJjy5qI1rejr2fKAGmBxZ8P+MIlFncMuHIelHXAE+AQUNXaom/ceyRWAr0BBV9rnCMBdQOTomRGlvzjhZ/sX+nnG0UUT9H+cZMsDvowWEQDxb/FwSXQ+iDNqtuvKM4KFJQ78pBwCCMt2ngyWK+QSSRVAGZ5OxJnEnbyIz4sH5coDovwKWU1z+f7GT9sEFu2kqYBstyRWBUo3uWm26Gc9JBxcpKejNc9OgRIZ19ZYsYhhnChklX8alLZ4AF3dhKQqlI4P/cUxOVlu7EbUS6ZJ3OyrB6Df4tni28NsFMyJRRkq2WRkUg1TjfO3pG1lQ4S+IKTj/RnqTHS7pkaxAP2oEv1RdeQeDdM9LKQS1f6fh4mrjXBFZ5ajPzP9WdGyWcTyQFWm6n8qTPp1VUp9rcgPD46r+/qopcFFH6J2Jm3FP54nKq+wLm3d/9cxPwBH3AEub8jFaQAAAABJRU5ErkJggg=="
                  />
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: '"Times New Roman",serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Please be aware that when you call ThreeCustomer Services, your
                phone number will automatically be presented to Three Customer
                Services so that we're able to provide you with integrated
                customer services and for security purposes. The legal basis
                that we rely on to processYour Information for the above purpose
                is legitimateinterest and/or to enable us to improve and
                developour business operations and services.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.65pt",
                  paddingLeft: "17.4pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Marketing and keeping you informed
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "2.1pt",
                textIndent: "0.09999999999999432pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To carry out analysis of your information, in order to develop
                our relationship with you, to develop and personalise Three
                Services and to present and deliver these to your Device. The
                legal basis that
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 11,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAaCAYAAACzdqxAAAAAAXNSR0IArs4c6QAAAkhJREFUSEu11kuIjnEUx/HPNK6N+y2kCSElyYKNWJCtsiHlWnJJpLBRbLBiI5JLZCxkyV6MUgqLKVJIktwiuYTBuPzP9H+mp3de4zVvzuZ9nv//Od/nPOd/zu+8DbrbNSzAcLzL2wewJF/PLLkcx0YsSj5XyqiGCu5EPMYxbCntnU3ra/J9pc8nfMMo/Ch8yg/1w1vE7zB8LoHPYGW+j/2yrcI5XMbiauCD2Il92FslRT0tRcqGYgKexYNFxP3xHCMqclsrfwNO4CoWlsEtWI1bmFuFNgOj83prlf0hOY2NGIj2IuIvGICt6RCOVnHs6fCKx+OLx+EQdgU40tCed8en8nnRS/ByXMDXCDLAUaO7cxU0/SGptUTcjCfZvxP8K9+8zJ9SjV0LOPL8Pjs3l8FxqpvqiDhcIw1R55fK4MOphbfXCS7qua0MXo/TdYLfYGRRx0WO/xv4SCrybXVG/AGD0VpOxXXMrxMcKtc3qdyeAD9K6jQ5t2RnfnrZIKHfoY5hTQEOkQ7J68h9Hr+VVksdz8HN7NgY4D74nhdiOtztJTg0YkcaEK/ToBhTiNDTrKX7Iz9VwCexLK/HEKhmH5MADcJatBTgKXiY3hib0Zr/alPxIItZVEVHeTQVb+w2GGt4yylEH4QkhDR0TZC4noW2XCXxBbXapJSme2mc/cyzsvO8yhHH9e0kILMxDzdqJMegXYfNiL8DnVY5yiM/0T33k2hPrwE8DXfyvIzIu6wSHBvR1qF0S3HxL/DzKYgVKdKxKeJX5Wd/A3zvdwNQJPX4AAAAAElFTkSuQmCC"
                  width={11}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "1.95pt",
                textIndent: "13.200000000000003pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To keep you informed about Three's services, developments,
                pricing tariffs, special offers, andany discounts or awards
                which we believe may be of personal interest to you, or which
                you may be entitled to. We may keep you up to date directly to
                your Device, and by post, phone and by electronic messaging such
                as phone, text and MMS, email voice, and audio, subject to any
                preferences indicated by you. You can contact us at any time to
                ask us not to use your location or Communications Data for
                marketing purposes or if you would prefer not to receive direct
                marketing information, or simply to update your preferences by
                writing to or calling Three Customer Services, by sending an
                email to&nbsp;
              </span>
              <a
                href="mailto:DPA.Officer@three.co.uk"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Arial,sans-serif",
                    color: "#0000ff",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  DPA.Officer@three.co.uk
                </span>
              </a>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",
                  color: "#0000ff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                or by updating your marketing preferences directly from your
                Device or online using My3. The legal basis that we rely on to
                process Your Information for the above
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 13,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAAAXNSR0IArs4c6QAAAmxJREFUSEu9lk+IzVEUxz9jBmEmJiH5GyaNEIuxwEZJmrCTkthY2LCYZutPrIRENspiJCk7ShY2NpMFWZBoRpQoSRJi/JkZ9/s6p447v/dq5veeU69f3fvu+dzz555zmhgrd4HtQBvwzbbPAduAL8CmcOQ00A18BLaE9QvAEWAD8DAimjLeUuA18BxYFfauAvuBz0B7WL8G7APeAEsyXcPAJPuN+l4ENgM/gBZgKvA7KLgSgHPDeh+w14ArMuABQPt3gB1FwKPpNieBG6akwNvjXlJIZphX5B3cQln3x9RNN0vHrb3gwGHgIvAC6IzA3nSTM3EjO7wOmAX8BB6EvZXAfHN/f5UbeiynAUNuoQd1T0qWmwUHJ5I0ruY9MC9d7DJwSMApdvNocc4sAzwBHAdkaYuAu82qX5adRZ4pA5R1slJSAQ4AHSntXwHLq8ShDFAqRyxBuwX0+D1Kb6arQUC9AL2EsxFYCWqDgN8BZelABOrRK7j1jqH0fU3WtXpWuktPJV8f+5/AWiWtbNKoLqtG35dLPaDPgNUNstAZBwV8DKxP/esTMLtBQH8WiwVUWxm0t6LUrXfS7EoG3TKlzQKqSarsSNRcK20kkzIxvA3stOmhzYv3B2BOqjTq4OrsuVyyzq7LaCpw0dtVwVfHX1vFO0NWMjcD/Q7UOKGkqVVPq+irubzILqM/yZOjDtRXgZWoUaph1kPUIzcC59MA1iOFcaZZAzwB3gEL60BTKdOUp/c32SeKfGp7Cyyw7vGyJPS6zUb/NPUc6M1YN5tZAqjBSQOURs5lUU8O1N7WNOzes8FX34nIU6taYwayv9/BqAoAgQBsAAAAAElFTkSuQmCC"
                  width={13}
                  height={13}
                />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.8pt",
                marginRight: "28.8pt",
                textAlign: "justify",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                we rely onto process Your Information for the abovepurpose is
                our legitimate interests and/or your consent.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "62.599999999999994pt",
                marginRight: "32.7pt",
                textIndent: "-34.7pt",
                textAlign: "justify",
                marginTop: "4.75pt",
                marginBottom: "0pt",
                padding: "0pt 0pt 0pt 34.7pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span
                  style={{
                    border: "none",
                    display: "inline-block",
                    overflow: "hidden",
                    width: 16,
                    height: 13,
                  }}
                >
                  <img
                    src="blob:https://wordtohtml.net/61d13113-f6de-477e-bbd1-92f45173ee01"
                    width={16}
                    height={13}
                    data-fr-old-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAAAXNSR0IArs4c6QAAApNJREFUSEvNl0uIz1EUxz9jvBnGwniW8tp4l6REsWBBkUTIimY1NohIdjQWlDRF2FEshGw8NoomlAXFyisLCeWR8Rb3+++c6fjN77/7/X4zd/X/n/O793zuueeec24DPcdlYDUwDXhq6o3APqALWBimHAOWAR+AJUF+AlgEfAQWB/lJoBWYCTyOphsyHOPSh6+BG8CKoNsNHDaQ4UF+AVgPvAdGB/lVYBXwFhiTsfEdGAT0A/66LoJI8QkYZh/F+buAQwYyKijOBpDxQX4JWGkgEzMgW4HTwBlgWx6Iu60j7bAt58iKFOnIRgItwDst7B6RN/6YpaHAtyKt5qy1AzgC3AKWRhB54Hhy8xNgRs5EuX068Bu4E/RzgWZA5343yLWGYuYn0FlnU9q4HFDbuHvEg2YncDRnYpHB6ss/AyYDe4F2gYywINUHimbtIjvKAGlPnthjAT1WIJsBRb/cPqCOG8sA0e38YvaaBfISmAR8tkjOYykDRHY8JBYIxP/oGuk6VQmiU2gETkUQnZkCp0qQHylRDgReRJCDKU72VwzyNZWOIZ5H/Gj6DEi20EXnlBWsXgCvxKPJVtAqQPw0tgvkIrDW6ovSbZXB6iDzBLIcuN4LCW0NoHZBo1EgsfLOSd55VFGK96ZK2bXJi54aItUcpfotOSAq2wesMZoQ9Gpw1lmHNjXIZUQdnjo0Ve28oVZjMCDP1IJVY5Z54pclmDpzCxV7fPRXLxRbRUFIOB94UKjJnotdM48pgSp/dXdo+j0beAi8AdRElzV0MxUK2rSqverNfyDyzn3ziKrxq5JIzgMb7LbedBvZ54QI1Rg9B6aUAKLqrufKPXv3dJvIgkixKT2uztmD6XbBMN77NIWmqGbiH7GizCTC0YN5AAAAAElFTkSuQmCC"
                  />
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: '"Times New Roman",serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To tell you about the products and specialpromotions of
                carefully selected partners (subject to your preferences) and
                allow you to receive advertising and marketing information from
                them but without passing control of your information to the
                third party concerned. We may also share depersonalised or
                aggregated data with third parties for analysis and insight in
                relation to the useof the Three network and its services. You
                can opt out by sending an email to&nbsp;
              </span>
              <a
                href="mailto:optout.bigdata@three.co.uk"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Arial,sans-serif",

                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  optout.bigdata@three.co.uk
                </span>
              </a>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;The legal basis that we rely on to process Your
                Information for the above purpose is our legitimate interests
                and/or your consent.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "62.599999999999994pt",
                marginRight: "32.7pt",
                textIndent: "-34.7pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
                padding: "0pt 0pt 0pt 34.7pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span
                  style={{
                    border: "none",
                    display: "inline-block",
                    overflow: "hidden",
                    width: 17,
                    height: 13,
                  }}
                >
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAA0JJREFUSEu1l0mojlEYx3/XeG8yy3SvMRlKyZBxQSmKDTaGy8ICJSEWLJRrRbIwJIWFrmnJyrygzFNKuJIp80xkns7/9pzb8Trfd9/3637P5v065xn+55m/Ev6nA8AUoCfw0K6nA6uAt8DYiEzWoz1AJTASuBAKlyQ09QAeAGeBMcHdcmAD8AromNV6Dv5vQDOgMfDb84SAGgEfgBaAfoe0DFhngMobCNBiYBNwDJgYA7QI2ALsAOY3kNH61LwD2gCtgI9i9h7SV277A3SwXKlPWUPcr3ZKqoDHQLcQkMKxAnhjgJLGugD9AMX9nF2Os+8Tl6B38qDrA1TY/ckIn5wgKpV+76H3QGtARk5FhGJJ/cIS/BowOA+gS8AwQN/hET7lrUK2T5UnQE2AH8aoeIohSTFAM5zX9luo9ZhPEbm2wGsrktGBd0PWWcBe4BfQVIB8uARKZRijGCDxKoSiBcD2iKCvJF3p4TKapM5O/pkdlgnQF4ufQqDLtIDEVw3MAV4CnSKCeqSAHASm5tCtFiM+fQcJkE+qZDMM5XM1RiXrI2PsGrxURwOAm3bXPeCL4fqucAHrQ0Db3EsXZvSQ2L2HNwNLAvndwOw8lRua8jpqQkBbXQKqOWYJmXhXAmuBz1apP03BV3fe3M3DuW4u7sqh1x97QLWN0YesUEBqpJpxIpW1yrs/cMvOclVuzEP/ANIEVoJm9ZD4r1ovOu4G8wTgBDDeJak2h2n1eEfXftBWhx66CIwoEJCG4xEraw1n9TKFqzdwPwUgX41VAnQFGOJyQTEvKxCQxNRjVLprXBpoRomS601MfdiYyyUwFLhsHVe7SSEhk8xGqzIBkx6tFktTeGcgcN34SgVIwr4y2jmlWgmSlGZB88udl9VQvZsC0GlbBjWkK7xLVRGqDJ+UST3qLwqF5pIM5aIa6/YCIs+nIbULpcpkV6WHPCB1WSHMN8/SKM/K0xe4bUK1q2yYdNqFFLJJrtoOZ9VcIP9RaxN1PTAE5JP7OaCFrNjUHpAtVVndJpAsy3tAL1u8fPctFjD/d2ueS+ad3kgSkN9x1NjU8otF+iv1FLihlSM0EmtcM22dHAWcLxKiM+7vjzbIlslN8y8TXrrx32bYrwAAAABJRU5ErkJggg=="
                    width={17}
                    height={13}
                  />
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: '"Times New Roman",serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                  &nbsp; &nbsp;&nbsp;
                </span>
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To carry out market research and surveys. The legal basis that
                we rely on to process Your Information for the above purpose is
                our legitimate interests and/or your consent.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "97.3pt",
                marginRight: "33pt",
                textIndent: "4.049999999999997pt",
                textAlign: "justify",
                marginTop: "4.8pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To carry out activities necessary to the running of our
                business, including system testing, network monitoring, staff
                training, quality control and any legal proceedings. The legal
                basis that we rely on to process Your Information for the above
                purpose is for our legitimate interests in order to conduct and
                manage our business; for the performance of our contract between
                you and us; or in connection with legal proceedings (i.e. the
                establishment, exercise or defence of legal claims).
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 14,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAupJREFUSEutllmIjlEYx39jGtm3QpSl0BCJhCQ7xQUu3EmJoqS5mGSJuLBEkSQXZLngwp1MyRLZky0uFBdIoURkN3bOf3oene/1zvt+X/M+Nc33nvOc5/+s/3Oq+F9OArOAjsCnlP1KlrYCa4AJwNX4YFXCSn/gCXAZmFQJQobuN6A1UA38dr0YWBuNgNa6AJ8LAp4LHAdOWyabzMbAu4E64ACwpCBQN6Mg2gGdgQ8xcCvgl2l1A94WDLwU2AdcAibHwB7tI2BQBKqMeK1fAfczHKoNxnvZ/sUUPdVX9hR5o37oTxEqDbOBE4lDD4DBwAugdwbwTWA0IAd7pui9BHqE/tkF1Au0Bvhuisku1/JY4HpOGdpHozc1ZOlCCvB6YKPjCGhbCH018MPaPnlG3a6R0P/NoRdkICnrbE/rJWMTKXYAPtp3jYBV1wHWbUp3muwBlltUIpakvAc6AduBVRnlUAOrkUcJ+I8pXgEmNnOoD/C0mXR3t7pqu1+kl2ZKJVVp98fAhwNrLczw9gvQFjgKzI/0joSsLbAG1ShmiQiqDXAvBt4UKG1DxqlFwKHAvV/NAVd1Y/XWsVnA4n41YtMoearzgKXvukNtpscEGrxhSGkTkXQiFfhYSMG8nFSdA6YF9hFBTAH8W7MvDsgTvzAa4oivAeNzTo4DpJfM1kDgcR4q8NPGrU7Ad4CRYQzUPE35zxCNgjpTs6p7Vhyg2dQo5Ul8H9QKWN4+rKBOa8NIbLFLRQ7sCFfoyjxUzS5w2/SqBRx70hV4l2NEfCvedekLPCsD+Gx4hUz3AL0T/SLYGVK3ogwjeqXIyefAsDL0paKAxIwz1JQOPAK4a8UXsxQtIpY3nmY9geLZc77VbN4qGPlUuOFmWjaV1ZKnj+byPPAaEP8WKU48evTpFiwB1reaRqB6bfql0FIHDgZbi4PtZcBeN5akOX8UFBW1eEEl1JNpeBxBGr/OCYTSYDU508JwBTjEiEkE9U/+ApnEj/8zEem4AAAAAElFTkSuQmCC"
                  width={14}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "97.3pt",
                marginRight: "33.1pt",
                textIndent: "1.0499999999999972pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                To carry out any activities or disclosures to comply with any
                regulatory, government or legal requirement. The legal basis
                that we rely on to process Your Information because the
                processing is necessary for compliance with a legal obligation.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 17,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAaCAYAAADfcP5FAAAAAXNSR0IArs4c6QAAAzFJREFUSEu1l0eMTlEUx3+DYSZqtOhtoccoiR6xIlESltrCgoUIiY3ExlhIRGKBiAULUZeEIFggStQVYljoNXrU0e//y7mT63nvffebcpKXV+455/7v6a+M/+kgMAfoBzxMWS/l0x5gJPAYmBEI7gUWAOOBy6HCsoT2vsAD4CIwqZSdM3jPm577wIAETy3QEmgO/PZrIaBmwAegNaDnxqCzwAQ75MCEwhXAZuAkMD0N0HJgK7ADWNoYaCJ0vAM6AO2Aj+L3FtJdZvsDdAbeRihrDJa1Tkk18AToHQLaAKwG3hggv5lcN8VeFFu6skgx0scWz9h9DNAW+AxczRCUEUQVQK230HugPTAVkN9DugkMA9ICM+RTtowNTwvkBbWXVdzKZfuVeQLUAvhhq/KnGEKaDRw2l3Y1K6Yd1p9U6SzlohhA84F9wC+gXIC8uwRKaZi32RZgZQrDemCNu74DrYL1GEDdgOcmUylAX81/LwEtptEmYBXwGuiSwvAM6A4cAHRiTzGAFKcyhu5VAuRNnVcM+wP3bJchQE2w6aDgXc93SwQkdlm2HNgYAtoOLMuwkPhUJ1Q0FfQKfk9qDwuBR4CA11XdyBiSHu+lmhDQNucSFccsUi86asGnRPDkLbzY9b9dCeEYl4WACoXRKywGSMH6zTZUbToHDAVu2bdkX9TnBgFSB16UYyEtnTZ3CYxAHQFmATeAESmysYB8o90dWugKMK4IoMlmGbGpS6t2iOa6MeNQAwApyxQG1QJ03RW+0eaOyiKAtOyF1YfWWVtokyEXY6GwMPcUIPWba5YdOnUx8mOD58vLzhhAw83l0lchQALx07R3dJVYI0EeiUdN2JPayasGWMiDfgr08plxGxjsprtTblqcVsxENlEqw9RwR+Xwa/hSw9UoXJXB9wVQqMx0vMc8oB6AEOb1swicJbNoirxjUoVRNqwdcoPcoQJ4vGTV9RM4YR6pq4EhIB/cL6xR1m+LeKlOgPZSlukqlJBkdVUDVT/KC9T4LfM5/e/WEhfMOz1rEpDmIVVNDWka1pqKdGCNLGo7/wR7Wv+ZZxOffl8uNRGiC+73Z6LN25/CPf4CTz248SSrj8MAAAAASUVORK5CYII="
                  width={17}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "97.3pt",
                marginRight: "2.35pt",
                textIndent: "10.900000000000006pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                We may enter your name, address and phone number in a publicly
                available directory enquiry
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 20,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAaCAYAAADBuc72AAAAAXNSR0IArs4c6QAAA2dJREFUWEfFl1mojVEUx3/XcEkypMxTmUlkKJlFpiKiPBGFcr2IlMiLB0riCaEbRVG8GF8Ml4RQ5sxkvCkSIbNr/29r1z7b+c7p+s7prLqd7+699t7/vYb/WruMf2UnsAhoBnzKMl+Xoa3AaOAdMC5YuAmYBNwF5gTjG4GVQA/gcXhQWXRqb7fwHnDeDqgLqGy6x4GpwEugc6BwEJgN3AQGRgu/Ao2BhsAvPxcCrQf8tr8WwOe0KIEjwGQD2i3Ybz8wC7gFDInOWQzsAA4DM7IBXQusA/YBcwsAMs0WX4AmQEvggzbyFtXvH6DGgZVlSy2KU8XrI6BnCHQbsCScMKS6wBj7fgE8zXGDjs4b3W3+rP0q/hRGP4ELwdpezr3tzFo3EvaU4XR+I+CHt6gsKVF87IoW3ge08QNAyZYkd4B+gPT7mNL/JJPfX4bpBGwHKgS0vmVXktsnACctLARErBCLrPYWaABMcwlyrABAVzlrbvCeFdBlwBaLUYGORVQhypBIb3kWHYWNwkcSMkkaizb3iSQDaNPvQLlze7Vze4cEvx4wYn5l7ojVXgPtzfITg8k0QLWNj9O2AurjMxv5+jO7AM/sH/FhmFThnGJKl/GSFqh4XSxUGQLdAyxIsKj0BK5rTMSAymSFFQiVXX9xbZUW6Ecr5ddDoJXOGgsTgGpYleSQqMIow6uqjrdyybQ0iNNCWdQDrQ18b4F8QMPgHgxcs+bhoaFSJfFJV1SgpwFRUS6RRWXZy8AwVwyqgLEGWuBjSet6X0qrQouqrVJ7lUvGA6dMQbT1zb7VPKiJKDRQn0yrBdRXHhF26zxA1Xq9B5oan242CpHbRXOFBurpqVxA1WZdzUH48eFqelcEg3sdI8xLuGAa16vRPmP7lglo2DmJflRjc0kb4E2goDXPiwBUzcqAEKi+1fMpqy+66jIiD1BN66aDrNsamkNfyacEVYffP9Db7br7mS4xbwOjEtbrGaQQU7Ws9nVZGXzJ4kxJUmrR5cUswqfKVBM2zrKqKovo5lyJkXraW+962TXCEnY6usUVa+P6lhioHnXq5OR6cWkGUIF+YvV8pMVrKfAqfufb89q/FDKACpTvPZPauWID13NGDKKk1hNIPFor8bteY1OAE64sTgeOFhtZtL/eVcPtBZrRN/wF63ze2UUHqNkAAAAASUVORK5CYII="
                  width={20}
                  height={13}
                />
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.85pt",
                marginRight: "1.9pt",
                textAlign: "justify",
                marginTop: "8.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                service and directories operated by us or by a licensed third
                party operator such as BT, subject to your preferences and only
                where you have given us permission. The legal basis that we rely
                on to process Your Information for the above purpose is your
                consent.
              </span>
            </p>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Other third parties that we may disclosure Your Information to
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={13}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.050000000000004pt",
                  paddingLeft: "17.200000000000003pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Your Information may also be processed by:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    our business partners, suppliers and sub-contractors for the
                    performance of any contract we enter into with you, for
                    example we engage third parties to process applications, to
                    carry out surveys and to provide insurance for your Device.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.65pt",
                  paddingLeft: "17.4pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    other members of our Group.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    other professional advisers (including accountants and
                    lawyers) that assist us in carrying out our business
                    activities.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.15pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    police and other law enforcement agencies in connection with
                    the prevention and detection of crime.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    other external agencies and organisations (including the
                    National Crime Agency) for the purpose of preventing and
                    detecting fraud (including fraudulent transactions), money
                    laundering and criminal activity; and
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={6}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    third parties if we are under a duty to disclose or share
                    Your Information in order to comply with any legal
                    obligation or instructions of a regulatory body (including
                    in connection with a court order), or in order to enforce or
                    apply the terms of any agreements we have with or otherwise
                    concerning you (including agreements between you and us) or
                    to protect our rights, property or safety of our customers,
                    employees or other third parties.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.7pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.05pt",
                    marginTop: "3.95pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may also disclose Your Information to other third
                    parties, for example:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.8pt",
                marginRight: "32.95pt",
                textIndent: "9pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                in the event that we sell or buy any business or assets we will
                disclose Your Information to the prospective seller or buyer of
                such business or assets.
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 11,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAaCAYAAACzdqxAAAAAAXNSR0IArs4c6QAAAkhJREFUSEu11kuIjnEUx/HPNK6N+y2kCSElyYKNWJCtsiHlWnJJpLBRbLBiI5JLZCxkyV6MUgqLKVJIktwiuYTBuPzP9H+mp3de4zVvzuZ9nv//Od/nPOd/zu+8DbrbNSzAcLzL2wewJF/PLLkcx0YsSj5XyqiGCu5EPMYxbCntnU3ra/J9pc8nfMMo/Ch8yg/1w1vE7zB8LoHPYGW+j/2yrcI5XMbiauCD2Il92FslRT0tRcqGYgKexYNFxP3xHCMqclsrfwNO4CoWlsEtWI1bmFuFNgOj83prlf0hOY2NGIj2IuIvGICt6RCOVnHs6fCKx+OLx+EQdgU40tCed8en8nnRS/ByXMDXCDLAUaO7cxU0/SGptUTcjCfZvxP8K9+8zJ9SjV0LOPL8Pjs3l8FxqpvqiDhcIw1R55fK4MOphbfXCS7qua0MXo/TdYLfYGRRx0WO/xv4SCrybXVG/AGD0VpOxXXMrxMcKtc3qdyeAD9K6jQ5t2RnfnrZIKHfoY5hTQEOkQ7J68h9Hr+VVksdz8HN7NgY4D74nhdiOtztJTg0YkcaEK/ToBhTiNDTrKX7Iz9VwCexLK/HEKhmH5MADcJatBTgKXiY3hib0Zr/alPxIItZVEVHeTQVb+w2GGt4yylEH4QkhDR0TZC4noW2XCXxBbXapJSme2mc/cyzsvO8yhHH9e0kILMxDzdqJMegXYfNiL8DnVY5yiM/0T33k2hPrwE8DXfyvIzIu6wSHBvR1qF0S3HxL/DzKYgVKdKxKeJX5Wd/A3zvdwNQJPX4AAAAAElFTkSuQmCC"
                  width={11}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.1849999999999998",
                marginLeft: "111.8pt",
                marginRight: "33.05pt",
                textIndent: "2.4000000000000057pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                if we or substantially all of our assets are acquired by a third
                party (or are subject to a reorganisation within our Group),
                Your Information held by us will be one of the transferred
                assets; and
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 13,
                  height: 13,
                }}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAAAXNSR0IArs4c6QAAAmxJREFUSEu9lk+IzVEUxz9jBmEmJiH5GyaNEIuxwEZJmrCTkthY2LCYZutPrIRENspiJCk7ShY2NpMFWZBoRpQoSRJi/JkZ9/s6p447v/dq5veeU69f3fvu+dzz555zmhgrd4HtQBvwzbbPAduAL8CmcOQ00A18BLaE9QvAEWAD8DAimjLeUuA18BxYFfauAvuBz0B7WL8G7APeAEsyXcPAJPuN+l4ENgM/gBZgKvA7KLgSgHPDeh+w14ArMuABQPt3gB1FwKPpNieBG6akwNvjXlJIZphX5B3cQln3x9RNN0vHrb3gwGHgIvAC6IzA3nSTM3EjO7wOmAX8BB6EvZXAfHN/f5UbeiynAUNuoQd1T0qWmwUHJ5I0ruY9MC9d7DJwSMApdvNocc4sAzwBHAdkaYuAu82qX5adRZ4pA5R1slJSAQ4AHSntXwHLq8ShDFAqRyxBuwX0+D1Kb6arQUC9AL2EsxFYCWqDgN8BZelABOrRK7j1jqH0fU3WtXpWuktPJV8f+5/AWiWtbNKoLqtG35dLPaDPgNUNstAZBwV8DKxP/esTMLtBQH8WiwVUWxm0t6LUrXfS7EoG3TKlzQKqSarsSNRcK20kkzIxvA3stOmhzYv3B2BOqjTq4OrsuVyyzq7LaCpw0dtVwVfHX1vFO0NWMjcD/Q7UOKGkqVVPq+irubzILqM/yZOjDtRXgZWoUaph1kPUIzcC59MA1iOFcaZZAzwB3gEL60BTKdOUp/c32SeKfGp7Cyyw7vGyJPS6zUb/NPUc6M1YN5tZAqjBSQOURs5lUU8O1N7WNOzes8FX34nIU6taYwayv9/BqAoAgQBsAAAAAElFTkSuQmCC"
                  width={13}
                  height={13}
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "111.8pt",
                marginRight: "33pt",
                textIndent: "7.299999999999997pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                if we are under a duty to disclose or share Your Information in
                order to comply with any legal obligation, or in order to
                enforce or apply the agreements concerning you (including
                agreements between you and us).
              </span>
              <span
                style={{
                  border: "none",
                  display: "inline-block",
                  overflow: "hidden",
                  width: 16,
                  height: 13,
                }}
              >
                <img
                  src="blob:https://wordtohtml.net/8d7589a3-4575-48b7-bcb9-436c05fe0d1e"
                  width={16}
                  height={13}
                  data-fr-old-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAAAXNSR0IArs4c6QAAApNJREFUSEvNl0uIz1EUxz9jvBnGwniW8tp4l6REsWBBkUTIimY1NohIdjQWlDRF2FEshGw8NoomlAXFyisLCeWR8Rb3+++c6fjN77/7/X4zd/X/n/O793zuueeec24DPcdlYDUwDXhq6o3APqALWBimHAOWAR+AJUF+AlgEfAQWB/lJoBWYCTyOphsyHOPSh6+BG8CKoNsNHDaQ4UF+AVgPvAdGB/lVYBXwFhiTsfEdGAT0A/66LoJI8QkYZh/F+buAQwYyKijOBpDxQX4JWGkgEzMgW4HTwBlgWx6Iu60j7bAt58iKFOnIRgItwDst7B6RN/6YpaHAtyKt5qy1AzgC3AKWRhB54Hhy8xNgRs5EuX068Bu4E/RzgWZA5343yLWGYuYn0FlnU9q4HFDbuHvEg2YncDRnYpHB6ss/AyYDe4F2gYywINUHimbtIjvKAGlPnthjAT1WIJsBRb/cPqCOG8sA0e38YvaaBfISmAR8tkjOYykDRHY8JBYIxP/oGuk6VQmiU2gETkUQnZkCp0qQHylRDgReRJCDKU72VwzyNZWOIZ5H/Gj6DEi20EXnlBWsXgCvxKPJVtAqQPw0tgvkIrDW6ovSbZXB6iDzBLIcuN4LCW0NoHZBo1EgsfLOSd55VFGK96ZK2bXJi54aItUcpfotOSAq2wesMZoQ9Gpw1lmHNjXIZUQdnjo0Ve28oVZjMCDP1IJVY5Z54pclmDpzCxV7fPRXLxRbRUFIOB94UKjJnotdM48pgSp/dXdo+j0beAi8AdRElzV0MxUK2rSqverNfyDyzn3ziKrxq5JIzgMb7LbedBvZ54QI1Rg9B6aUAKLqrufKPXv3dJvIgkixKT2uztmD6XbBMN77NIWmqGbiH7GizCTC0YN5AAAAAElFTkSuQmCC"
                />
              </span>
            </p>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Fraud Prevention
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={14}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.8pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The personal information we have collected from you will be
                    shared with fraud prevention agencies who will use it to
                    prevent fraud and money-laundering and to verify your
                    identity. If fraud is detected, you could be refused certain
                    services, finance, or employment. Further details of how
                    your information will be used by us and these fraud
                    prevention agencies, and your data protection rights, can be
                    found in the&nbsp;
                  </span>
                  <a
                    href="http://www.three.co.uk/terms-conditions/cifas-data-use-policy"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",
                        color: "#0000ff",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      terms and conditions section
                    </span>
                  </a>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",
                      color: "#0000ff",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    &nbsp;
                  </span>
                  <a
                    href="http://www.three.co.uk/terms-conditions/cifas-data-use-policy"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "10pt",
                        fontFamily: "Arial,sans-serif",
                        color: "#0000ff",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      of our website.
                    </span>
                  </a>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Automated decision making
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={15}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    When you apply for a pay monthly plan, we will obtain
                    information from one or more CRAs, which will be used in an
                    automated decision process to determine whether we can enter
                    a contract with you. If you wish for the decision to be
                    reassessed bya person, you may do so by calling Three
                    Customer Services or by writing to us at: Three Customer
                    Services, Hutchison 3G UK Ltd, PO Box 333, Glasgow G2 9AG.
                    You can also object to a decision being taken solely by
                    automated processing (see heading Your Rights below).
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6.1pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Where will we transfer Your Information
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </h2>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={16}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.95pt",
                    textAlign: "justify",
                    marginTop: "8.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Your Information will be processed both within and outside
                    the European Economic Area (EEA). Where we transfer Your
                    Information outside of the EEA, we will implement
                    appropriate and suitable safeguards to ensure that such
                    personal information will be protected as required by
                    applicable data protection law. as required by applicable
                    data protection law. These measures generally include:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "22.95pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Commercial terms to safeguard the processing of Your
                    Information and;
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "lower-alpha",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "44.75pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={3}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "8.3pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Technical security standards commensurate with the nature of
                    the data being processed.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={17}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    For further information as to the safeguards we implement
                    please contact our Data Protection and Privacy Officer at
                    the contact details set out in Section 13.9.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Retention of data
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={18}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We may retain Your Information for as long as is necessary
                    for the purposes detailed in this notice and until charges
                    for Three Services cannot be lawfully challenged and legal
                    proceedings may no longer be pursued. Generally, we'll keep
                    your communications data for up to one year. Your account
                    information will be kept after your relationship with Three
                    ends to comply with our legal and regulatory obligations.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Your Rights
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={19}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "1.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You have certain rights with respect to Your Information.
                    The rights may only apply in certain circumstances and are
                    subject to certain exemptions. Please see Table 2 below for
                    a summary of your rights. You can exercise these rights
                    using the contact details set out in Section 13.9.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "6pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Notification of changes
              </span>
            </h2>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={20}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If we change this notice we'll post the amended version on
                    our website so you always know how we'll collect, use and
                    disclose your information.
                  </span>
                </p>
              </li>
            </ol>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={14}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "3.95pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Notices
                  </span>
                </h2>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.9pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Our website, Three.co.uk, is a great source of information
                    that you may find useful when using the Three Services -
                    it's the most up to date source of information about Three
                    and Three Services.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.8pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If we need to send any notices under this agreement to you,
                    we'll do this by communicating them to you via phone, text
                    message, electronic messaging, email, or mail, using your
                    most recent contact details given to us (if any).
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={15}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={1}
              >
                <h2
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Other terms
                  </span>
                </h2>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.05pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    This agreement is governed by English Law unless you live in
                    Scotland, in which case it will be governed by Scots Law.
                    Each of us agrees to only bring legal actions about this
                    agreement in a UK court.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.35pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If you, or we, delay, or don't take action to enforce our
                    respective rights under this agreement, this does not stop
                    you, or us, from taking action later.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={3}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "32.75pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If any of the terms in this agreement are not valid or
                    legally enforceable, the other terms won't be affected. We
                    may replace any term that is not legally effective with a
                    similar term that is. We may assign or transfer some or all
                    of our rights and obligations under your agreement to a
                    party who agrees to continue complying with our obligations
                    under this agreement, provided that your rights under the
                    agreement or any guarantees given by us to you are not
                    affected. No other person (other than our assignee(s), if
                    any) may benefit from this agreement. In exceptional
                    circumstances, a government authority may order the
                    reallocation or change of phone numbers, in which case we
                    may have to change your Three phone number.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={4}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.6pt",
                    textAlign: "justify",
                    marginTop: "0.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You confirm that you have full contractual capacity to agree
                    to the agreement and are able to pay the Charges.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={5}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12.049999999999997pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "33.35pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Third parties can't benefit from this agreement under The
                    Contracts (Rights of Third Parties) Act 1999.
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "-12pt",
                  paddingLeft: "17.299999999999997pt",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "400.55pt",
                    textAlign: "justify",
                    marginTop: "4.05pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Our registered company number is 03885486 (England and
                    Wales) and our registered office is at 450 Longwater Avenue,
                    Green Park, Reading, Berkshire, RG2 6GF.
                  </span>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Arial,sans-serif",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <br />
                  </span>
                </p>
              </li>
            </ol>
            <h2
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginTop: "3.9pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Table 2
              </span>
            </h2>
            <p>
              <br />
            </p>
            <div dir="ltr" style={{ marginLeft: "5.95pt" }} align="left">
              <table style={{ border: "none", borderCollapse: "collapse" }}>
                <tbody>
                  <tr style={{ height: "23pt" }}>
                    <td
                      colSpan={2}
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        backgroundColor: "#ecebdf",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.125",
                          marginLeft: "0.55pt",
                          textAlign: "center",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Summary of your Rights
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "22.85pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.1400000000000001",
                          marginLeft: "5.65pt",
                          marginRight: "7.55pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Right of access to your personal information
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.1400000000000001",
                          marginLeft: "5.75pt",
                          marginRight: "6.55pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You have the right to receive a copy of your personal
                          information that we hold about you, subject to certain
                          exemptions.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "28.05pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.65pt",
                          marginRight: "7.55pt",
                          marginTop: "4.05pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Right to rectify your personal information
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.75pt",
                          marginRight: "6.55pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You have the right to ask us to correct your personal
                          information that we hold where it is incorrect or
                          incomplete.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "103.55pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.65pt",
                          marginRight: "7.55pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Right to erasure of your personal information
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.1849999999999998",
                          marginLeft: "5.75pt",
                          marginRight: "6.55pt",
                          marginTop: "0.05pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You have the right to ask that your personal
                          information be deleted in certain circumstances. For
                          example (i) where your personal information is no
                          longer necessary in relation to the purposes for which
                          they were collected or otherwise used; (ii) if you
                          withdraw your consent and there is no other legal
                          ground for which we rely on for the continued use of
                          your personal information; (iii) if you object to the
                          use of your personal information (as set out below);
                          (iv) if we have used your personal information
                          unlawfully; or (v) if your personal information needs
                          to be erased to comply with a legal obligation.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "126.3pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.65pt",
                          marginRight: "7.55pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Right to restrict the use of your personal information
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.75pt",
                          marginRight: "10.5pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You have the right to suspend our use of your personal
                          information in certain circumstances. For example (i)
                          where you think your personal information is
                          inaccurate and only for such period to enable us to
                          verify the accuracy of your personal information; (ii)
                          the use of your personal information is unlawful and
                          you oppose the erasure of your personal information
                          and request that it is suspended instead; (iii) we no
                          longer need your personal information, but your
                          personal information is required by you for the
                          establishment, exercise or defence of legal claims; or
                          (iv) you have objected to the use of your personal
                          information and we are verifying whether our grounds
                          for the use of your personal information
                        </span>
                      </p>
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.04",
                          marginLeft: "5.75pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          override your objection.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "80.5pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.135",
                          marginLeft: "5.65pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Right to data portability
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.75pt",
                          marginRight: "6.55pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You have the right to obtain your personal information
                          in a structured, commonly used and machine-readable
                          format and for it to be transferred to another
                          organisation, where it is technically feasible. The
                          right only applies where the use of your personal
                          information is based on your consent or for the
                          performance of a
                        </span>
                      </p>
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.1400000000000001",
                          marginLeft: "5.75pt",
                          marginRight: "6.55pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          contract, and when the use of your personal
                          information is carried out by automated (i.e.
                          electronic) means.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "80.5pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.65pt",
                          marginRight: "7.55pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Right to object to the use of your personal
                          information
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.75pt",
                          marginRight: "10.5pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You have the right to object to the use of your
                          personal information in certain circumstances. For
                          example (i) where you have grounds relating to your
                          particular situation and we use your personal
                          information for our legitimate interests (or those of
                          a
                        </span>
                      </p>
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.75pt",
                          marginRight: "6.55pt",
                          marginTop: "0.05pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          third party) including for profiling; and (ii) if you
                          object to the use of your personal information for
                          direct marketing purposes,
                        </span>
                      </p>
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.045",
                          marginLeft: "5.75pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          including profiling (to the extent it relates to
                          direct marketing).
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "34.65pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.15",
                          marginLeft: "5.65pt",
                          marginRight: "7.55pt",
                          marginTop: "0.35pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Right to object to decision which is based solely on
                          automated processing
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.15",
                          marginLeft: "5.75pt",
                          marginRight: "6.55pt",
                          marginTop: "0.35pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You have the right in certain circumstances not to be
                          subject to a decision which is based solely on
                          automated processing without human intervention.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "22.9pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.125",
                          marginLeft: "5.65pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Right to withdraw consent
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.1400000000000001",
                          marginLeft: "5.75pt",
                          marginRight: "6.55pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You have the right to withdraw your consent at any
                          time where we rely on consent to use your personal
                          information.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "46.05pt" }}>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.65pt",
                          marginRight: "27.9pt",
                          textAlign: "justify",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Right to complain to the relevant data protection
                          authority
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderLeft: "solid #000000 0.5pt",
                        borderRight: "solid #000000 0.5pt",
                        borderBottom: "solid #000000 0.5pt",
                        borderTop: "solid #000000 0.5pt",
                        verticalAlign: "top",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.2",
                          marginLeft: "5.75pt",
                          marginRight: "6.55pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          You have the right to complain to the relevant data
                          protection authority, which is, in the case of Three,
                          the Information Commissioner's Office, where you think
                          we have not used your personal information in
                        </span>
                      </p>
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.0599999999999998",
                          marginLeft: "5.75pt",
                          marginTop: "0pt",
                          marginBottom: "0pt",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10pt",
                            fontFamily: "Arial,sans-serif",

                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          accordance with data protection law.
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <br />
            </p>
            <p>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginTop: "4.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Definitions
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span
                style={{
                  fontSize: "5.5pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginTop: "4.65pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Where we use these words, they have the following meanings:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "1.85pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Additional Services:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                additional, optional or extra services which you choose to use
                which are not services that are part of your Package or Outside
                of Allowance Services. For example, they may include (but
                they're not limited to) Add-Ons, Premium Services, Ancillary
                Services, international services, services you use whilst
                roaming abroad, directory enquiry services, any other services
                listed in our Price Guide, calls to nongeographic numbers (such
                as calls to 084, 087), content or applications you may buy
                and/or any third- party services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginTop: "0.1pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Add-on:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                additional allowances which you can add to your Package when you
                need them (as detailed in the Price Guide).
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Age Restricted Services:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                any Three Services which are for use only by customers 18 or
                over.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "6.55pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Ancillary Services:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                optional services which we may charge you relating to your use
                of the Three Services as listed in the Price Guide.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Annual Price Change:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                an increase to the Monthly Charge as described in Section 4.1.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "6.55pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Cancellation Fee:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                means a fee charged if we end the agreement due to your conduct
                or if you end your agreement within the Minimum Term. The fee is
                set out in the Price Guide and is calculated as a lump sum
                equivalent to the total of the Monthly Charges remaining during
                the Minimum Term of your agreement less a variable discount,
                currently 3%. We reserve the right to vary the amount of the
                percentage discount from time to time.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "6.55pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Charges:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                charges for access to, and use of, Three Services as set out in
                the Price Guide. These charges may cover (but are not limited
                to) fixed periodic charges, including your Monthly Charge, usage
                charges (for example, charges for Outside of Allowance Services
                or Additional Services), account administration fees, fees for
                Connection and re- Connection and any costs incurred in
                collecting outstanding payments from you.&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Communications Data:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                information about the routing of services, calls and messages
                you make and receive, the date, time, duration and cost of
                these, and information about the identity of your Device and SIM
                or eSIM Profile.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Connection:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the procedure by which we give you access to Three Services.
                ‘Connect', ‘Connecting', and ‘reConnection' have corresponding
                meanings.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "6.55pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                CPI Rate:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the December Consumer Price Index annual percentage change
                published by the Office for National Statistics in January each
                year. We may also refer to this as the “
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                December CPI Rate”.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "6.35pt",
                marginBottom: "0pt",
              }}
            >
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Device:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                any device you use to connect to Three Services, including a
                Phone, Dongle, Mobile Wi-Fi, smartwatch, Tablet or Laptop that
                is authorised by us for Connection to the Three Network.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Disconnection:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the procedure by which we stop your access to Three Services.
                ‘Disconnect,' ‘Disconnected' and ‘Disconnecting' have
                corresponding meanings.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Dongle:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the USB modem that's authorised by us for Connection to the
                Three Network which is used to access Three Services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                eSIM:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                a chip embedded in your Device to which an eSIM Profile can be
                downloaded.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                eSIM Profile
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                : contains your Three phone number and enables you to access
                Three Services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                eSIM QR Code:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                a QR or activation code which allows us to install and activate
                an eSIM Profile on the eSIM in your Device.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "10.7pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Group:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                means CK Hutchison Holdings Limited. Laptop: a laptop which is
                used in conjunction with a Dongle and/or Mobile WiFi and/or SIM
                to access Three Services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "10.7pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Location Data:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                data indicating the geographical location of your Device when
                using Three Services or when your Device is switched on.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Messaging Services:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                any email, voicemail, text (SMS) and multimedia messaging
                services (MMS), personal information management and other
                message or communication facilities which let you communicate
                with others.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Minimum Term:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the minimum fixed term for the supply of Three Services as laid
                out in your Package.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Mobile Wi-Fi:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the wireless mobile device which is authorised by us for
                connection to the Three Network and is used to access Three
                Services.&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Monthly Charge:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                is the monthly fixed charge payable by you for your Package
                (asset out in the Price Guide), including any Annual Price
                Change.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "10.7pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Outside of Allowance Services:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                any standard Three Services (i.e. calls and texts to standard UK
                mobiles and UK landlines you use when you have used up your
                monthly allowance (whether voice minutes or text messages or
                data, as applicable) which may be included in your Package (if
                any) or, if you don't have any inclusive allowances with your
                Package, any standard Services you may use.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.1400000000000001",
                marginLeft: "5pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                PAC:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                a Porting Authorisation Code
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0.15pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Packages:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                our current Packages available for you to select that are set
                out in the Price Guide as well as any other Packages we may
                introduce in the future.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "10.7pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                ‘Your Package'&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                means the Package you have chosen, details of which are set out
                in the Welcome Letter, including your Minimum Term. There
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "2.5pt",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                may be more than one Package available for you to choose from
                and if so, you will need to select one before you're Connected
                to Three. Depending on the Package you choose, you may receive
                an allowance made up of a specified number of voice minutes,
                text messages and/or data - details of these are set out in the
                Price Guide. The Packages we offer may be changed or withdrawn
                from time to time and can be viewed at Three.co.uk or details
                requested from Three Customer Services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "2.5pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Phone:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                a mobile phone that you use to access Three Services using your
                SIM or eSIM Profile.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "15.2pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Premium Services:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                any Three Services which are charged at premium rates. You can
                only access these Three Services, such as international calling
                and international roaming, with our approval.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "2.5pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Price Guide:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the document that sets out the Packages available to you, our
                current Charges and relevant terms (including any Minimum Term
                and payment commitments). It can be viewed at
                Three.co.uk/priceguide&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Primary Service:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                means the eligible Pay Monthly Phone or SIM Only plan to which
                the voice, text and data allowances included in your Package
                have been allocated.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "2.5pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Recurring Payment Method:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                a means of automated payment, preauthorised by you, such as by
                Direct Debit or a recurring credit or debit card payment,
                details of which are available in our Price Guide.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "2.5pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Returns Period:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the number of days you have to cancel your agreement and/or
                return your Device, as detailed in the Returns Policy, or as
                stated in your Welcome Letter.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "2.5pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Returns Policy:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                our returns policy applicable at the time your agreement begins,
                the current version of which is available at
                Three.co.uk/returns&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Secondary Device:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                means a compatible Smartwatch.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "2.5pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                SIM:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                a card which contains your Three phone number and enables you to
                access Three Services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "1.9pt",
                textAlign: "justify",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Smartwatch Pairing Plan
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                : means the service which enables you to share the monthly
                allowances included in your Primary Service with a Secondary
                Device.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "2.5pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Storage Services:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                any Three Services which offer you storage capacity on the Three
                Network for storage of content which you access from Three
                Services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "2.5pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Suspension:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the procedure by which we temporarily Disconnect your access to
                the Three Services. ‘Suspend' and ‘Suspended' have a
                corresponding meaning.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "3.45pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Tablet:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                a tablet personal computer which is authorised for connection to
                the Three Network and is used to access Three Services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "2.5pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Terms:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                these Terms and Conditions for using the Three Network and Three
                Services.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <h1
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: '"Times New Roman",serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Updated: 1 Sept 2024
              </span>
            </h1>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7pt",
                marginTop: "3.95pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Three Customer Services:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                our service team who are available to help you with your
                queries. Device customers can call 333 (free) from a Three Phone
                or 0333 338 1001 from any other line (standard call charges
                apply). Mobile Broadband customers can call 500 (free)from a
                Three Phone or 0333 338 1003 from a landline (standard call
                charges apply).
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Three Network:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                means the public telecommunications network owned, operated or
                used by Three.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Three Services:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the services offered by Three, including, but not limited to,
                call services, internet access, Messaging Services, Storage
                Services, Age Restricted Services, Premium Services, and the
                Smartwatch Pairing Plan (where included in your Package or added
                by you as an Additional Service) which we have agreed to provide
                to you.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                TrafficSense™:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Tools and insight we use across the Three Network for the
                intelligent management of data traffic. See
                Three.co.uk/trafficsense for more information.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0.05pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Welcome Letter:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                the letter or email we send to you when you take out a new
                agreement with us – either as a new customer or as an existing,
                upgrading customer.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginLeft: "5pt",
                marginRight: "7.95pt",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Wi-Fi Calling:&nbsp;
              </span>
              <span
                style={{
                  fontSize: "10pt",
                  fontFamily: "Arial,sans-serif",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Wi-Fi Calling is a service supported by some devices that allows
                you to call and text whenever you're on Wi-Fi in the UK, even if
                there’s no mobile signal.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginRight: "5.25pt",
                textAlign: "right",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: '"Times New Roman",serif',

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                25
              </span>
            </p>
          </>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default ThreeTnC;

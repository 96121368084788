import axios from 'axios';
import { API_URL } from '../constant/Constant';
class AddressService {

    addressApi(zipCode) {
        return axios.get(`${API_URL}api/v1/getAddressListByPostalCodeN3Api/${zipCode}`,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              }
        });
    }

    addressSubmit(json){
        return axios.put(`${API_URL}api/v1/updateCreditCheck`,json,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              }
        });
    }
}
export default new AddressService()
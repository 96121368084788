import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DownIcon, UpIcon } from "./Icons";

const CustomSelect = ({ value, options, placeholder, onChange, icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const itemRef = useRef();

  const handleOutsideClick = (e) => {
    if (e.target.contains(dropdownRef.current)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    if (isOpen) {
      const activeItem = itemRef.current.querySelector(".active");
      itemRef.current.scrollTop = activeItem?.offsetTop - 72;
    }
  }, [isOpen]);
  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownBtn
        style={{ borderWidth: "1px" }}
        className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-[#388087] text-left">{value || placeholder}</span>
        {!isOpen ? <DownIcon /> : <UpIcon/>}
      </DropdownBtn>
      {isOpen && (
        <DropdownList
          className="absolute bg-white shadow-lg rounded-lg w-full p-1 mt-[42px] border z-50 max-h-80 overflow-auto"
          ref={itemRef}
        >
          {options.map((opt, index) => (
            <DropdownItem
              key={index}
              onClick={() => {
                onChange(opt);
                setIsOpen(false);
              }}
              className={`${
                opt === value && "active"
              } w-full flex py-3 px-2 hover:bg-teal-600/10 hover:rounded cursor-pointer text-sm text-left`}
            >
              <div>{icon && icon}</div>
              {opt}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

export default CustomSelect;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DropdownBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 244px;
  cursor: pointer;
  svg {
    width: 16px;
  }
`;

const DropdownList = styled.div`
  cursor: pointer;
  overflow-y: auto;
`;

const DropdownItem = styled.button`
  cursor: pointer;
`;

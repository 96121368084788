import axios from "axios";
import { API_URL } from "../../constant/Constant";

export function fetchProduct() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${API_URL}api/v1/plan`);
      resolve({ data: response.data });
    } catch (error) {
      if (error.response) {
        reject({
          message: "Response error",
          status: error.response.status,
          data: error.response.data,
        });
      }
    }
  });
}
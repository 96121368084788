import React, { useEffect, useState } from "react";
import { Transition, TransitionChild } from "@headlessui/react";
import {
  FunnelIcon as FunnelIconOutline,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { FaSearch } from "react-icons/fa";
import brand1 from "../../assets/brand-logo/three_logo.png";
import brand2 from "../../assets/brand-logo/simo-logo.png";
import brand3 from "../../assets/brand-logo/gkt_logo.png";
import brand4 from "../../assets/brand-logo/talkMobile_logo.png";
import brand5 from "../../assets/brand-logo/O2_logo.png";
import brand6 from "../../assets/brand-logo/vodafone_logo.png";
import ProductCard from "./ProductCard";
import MultiRangeSlider from "multi-range-slider-react";
import { useSelector } from "react-redux";
import {
  priceFilter,
  productAsync,
  selectMinMaxValues,
  selectPriceRange,
  selectProduct,
  selectProductStatus,
} from "./productSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import loadingGif from "../../assets/loading.gif";
import { MdOutlineCancel, MdOutlineFilterAltOff } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { VscSearchStop } from "react-icons/vsc";
import { LuSearchX } from "react-icons/lu";
import CreditCheckService from "../../services/CreditCheckService";
import Loader from "../loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import Button from "../button/Button";
import {
  closeModal,
  openModal,
  selectModalContentId,
  selectModalState,
} from "../modalComponent/modalSlice";
import ModalComponent from "../modalComponent/ModalComponent";
import { FaFilterCircleXmark } from "react-icons/fa6";
import { API_URL } from "../../constant/Constant";

const brands = [
  {
    id: 0,
    img: brand1,
  },
  {
    id: 1,
    img: brand2,
  },
  {
    id: 2,
    img: brand3,
  },
  {
    id: 3,
    img: brand2,
  },
];

const filterTabs = [
  {
    id: 0,
    title: "Featured Deals",
  },
  {
    id: 1,
    title: "All Deals",
  },
];

export default function Product() {
  // const[allPlanData,setAllPlanData]=useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [brandIndex, setBrandIndex] = useState(0);
  const dispatch = useDispatch();

  const allPlanData = useSelector(selectProduct);
  const planDataStatus = useSelector(selectProductStatus);
  const [tandCData, setTandCData] = useState();

  // States for filter sliders
  const {
    minPriceValue,
    maxPriceValue,
    minDataValue,
    maxDataValue,
    minContractLengthValue,
    maxContractLengthValue,
  } = useSelector(selectMinMaxValues);
  const [priceFilterValue, setPriceFilterValue] = useState({
    minPr: minPriceValue,
    maxPr: maxPriceValue,
  });
  const [dataFilterValue, setDataFilterValue] = useState({
    minDt: minDataValue,
    maxDt: maxDataValue,
  });
  const [contractLengthValue, setContractLengthValue] = useState({
    minLt: 12,
    maxLt: 24,
  });
  // const [contractLengthValue, setContractLengthValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [allFilteredData, setAllFilteredData] = useState([]);
  const [filterReset, setFilterReset] = useState(false);
  const [priceFilterReset, setPriceFilterReset] = useState(false);
  const [dataFilterReset, setDataFilterReset] = useState(false);
  const [contractFilterReset, setContractFilterReset] = useState(false);
  const [loader, setLoader] = useState(false);
  const modalInitState = useSelector(selectModalState);
  const modalContentId = useSelector(selectModalContentId);
  const navigate = useNavigate();

  //======= ALL STATES END HERE ======//
  //useeffects

  useEffect(() => {
    if (contractLengthValue.minLt == 12 && contractLengthValue.maxLt == 24) {
      setContractFilterReset(false);
    }
  }, [contractLengthValue, contractFilterReset]);
  //funcitons
  const ErrorModal = ({ heading, body }) => {
    return (
      <div>
        <h2 className="text-md font-semibold mb-2">{heading}</h2>
        <p className="text-sm">{body}</p>
        <div className="w-full flex justify-between items-center mt-4">
          <Button
            handleClick={() => {
              navigate("/");
              dispatch(closeModal());
            }}
            title="Ok"
            type="button"
            style="
            rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 text-red-600 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-semibold px-3 py-1.5 text-center
            "
          />
        </div>
      </div>
    );
  };

  const fetchCreditCheck = async (addFetchOrderRequest, plan) => {
    const userRoleId = localStorage.getItem("roleId");
    await CreditCheckService.fetchCreditCheck(addFetchOrderRequest)
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          let tempCreditCheck = response.data;
          tempCreditCheck.plan = plan;
          if (userRoleId === "ROLE_DEALER") {
            localStorage.setItem(
              "creditCheck",
              JSON.stringify({
                creditCheckId: tempCreditCheck.creditCheckId,
                createdBy: tempCreditCheck.createdBy,
                customerIp: tempCreditCheck.customerIp,
                plan: tempCreditCheck.plan,
                affIdOrRefCode: tempCreditCheck.affIdOrRefCode,
              })
            );
          } else {
            localStorage.setItem(
              "creditCheck",
              JSON.stringify(tempCreditCheck)
            );
          }
          //call api
          const emailId = localStorage.getItem("emailId");
          console.log("emailId", emailId);
          if (userRoleId === "ROLE_DEALER") {
            axios
              .get(`${API_URL}api/v1/getTermAndConditionAndContractDetails`, {
                headers: {
                  emailId: emailId,
                },
              })
              .then((response) => {
                setLoader(false);
                if (response.status === 200) {
                  // console.log("res-->", JSON.parse(response.data));
                  const docData = response.data;
                  localStorage.setItem(
                    "DocInfo",
                    JSON.stringify(response.data)
                  );
                  if (
                    docData.termAndCondition.showtoCustomer === true &&
                    docData.dealerContract.showToCustomer === true
                  ) {
                    localStorage.setItem(
                      "tAndcshowtoCustomerId",
                      docData.termAndCondition.termAndConditionId
                    );
                    localStorage.setItem(
                      "dealerContractshowtoCustomerId",
                      docData.dealerContract.dealerContractId
                    );
                    navigate("/updatedDocument");
                  } else if (
                    docData.termAndCondition.showtoCustomer === false &&
                    docData.dealerContract.showToCustomer === true
                  ) {
                    localStorage.setItem(
                      "dealerContractshowtoCustomerId",
                      docData.dealerContract.dealerContractId
                    );
                    navigate("/updatedDocument");
                  } else if (
                    docData.termAndCondition.showtoCustomer === true &&
                    docData.dealerContract.showToCustomer === false
                  ) {
                    localStorage.setItem(
                      "tAndcshowtoCustomerId",
                      docData.termAndCondition.termAndConditionId
                    );
                    navigate("/updatedDocument");
                  }
                  // navigate("/productDetails");
                  // navigate("/updatedDocument");
                } else {
                  navigate("/productDetails");
                }
              })
              .catch((error) => {
                setLoader(false);
                if (error.message === "Network Error") {
                  setLoader(false);
                  dispatch(openModal("network error"));
                  //show network error
                } else {
                  switch (error.response ? error.response.status : "") {
                    case 403:
                      setLoader(false);
                      //show internal error
                      dispatch(openModal("internal error"));
                      break;
                    case 404:
                      setLoader(false);
                      //show internal error
                      dispatch(openModal("internal error"));
                      break;
                    case 500:
                      setLoader(false);
                      //show server error
                      dispatch(openModal("server error"));
                      break;
                    default:
                      setLoader(false);
                      //show unknown error
                      dispatch(openModal("unknown error"));
                      break;
                  }
                }
              });
          }
          navigate("/productDetails");
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.message === "Network Error") {
          setLoader(false);
          dispatch(openModal("network error"));
          //show network error
        } else {
          switch (error.response ? error.response.status : "") {
            case 403:
              setLoader(false);
              //show internal error
              dispatch(openModal("internal error"));
              break;
            case 404:
              setLoader(false);
              //show internal error
              dispatch(openModal("internal error"));
              break;
            case 500:
              setLoader(false);
              //show server error
              dispatch(openModal("server error"));
              break;
            default:
              setLoader(false);
              //show unknown error
              dispatch(openModal("unknown error"));
              break;
          }
        }
      });
  };
  const handleBuyNow = async (plan) => {
    const isUserVerified = localStorage.getItem("isVerified");
    const userRoleId = localStorage.getItem("roleId");
    const userId = localStorage.getItem("userId");
    const loggedIn = localStorage.getItem("loggedIn");
    //find IP and plan
    let userIp = undefined;
    setLoader(true);
    await axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        localStorage.setItem("userIP", data.ip);
        userIp = data.ip;
      })
      .catch((error) => {
        //console.log("error", error);
      });
    let addFetchOrderRequest = {
      planId: plan.planId,
      userId: null,
      userIP: "",
      userLoggedIn: false,
    };
    //if user is logged in then send userid and plan id to add/fetch credit check
    if (loggedIn) {
      if (userRoleId === "ROLE_DEALER" && !isUserVerified) {
        setLoader(false);
        dispatch(openModal("user not verified"));
      } else {
        addFetchOrderRequest = {
          ...addFetchOrderRequest,
          userId: userId,
          userIP: userIp,
          planId: plan.planId,
          userLoggedIn: true,
        };
        //send request and get credit check data
        fetchCreditCheck(addFetchOrderRequest, plan);
      }
    } else {
      //if user is not logged in then send IP and plan id to add/fetch credit check
      addFetchOrderRequest = {
        ...addFetchOrderRequest,
        userIP: userIp,
        planId: plan.planId,
        userLoggedIn: false,
      };
      //send request and get credit check data
      if (userIp && userIp.length > 0) {
        fetchCreditCheck(addFetchOrderRequest, plan);
      } else {
        setLoader(false);
        dispatch(openModal("please login first"));
      }
    }
  };

  useEffect(() => {
    if (
      priceFilterReset === false &&
      dataFilterReset === false &&
      contractFilterReset === false
    ) {
      setFilterReset(false);
    }
    if (
      priceFilterReset === true ||
      dataFilterReset === true ||
      contractFilterReset === true
    ) {
      setFilterReset(true);
    }
  }, [priceFilterReset, dataFilterReset, contractFilterReset]);

  // Filter
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    if (allPlanData.length > 0) {
      console.log(
        allPlanData.filter(
          (product) => product.contractTerm == contractLengthValue
        ),
        contractLengthValue
      );

      const allFilteredData = allPlanData
        .filter(
          (product) =>
            product.finalPrice >= priceFilterValue.minPr &&
            product.finalPrice <= priceFilterValue.maxPr
        )
        // .filter(
        //   (product) =>
        //     product.data >= dataFilterValue.minDt &&
        //     product.data <= dataFilterValue.maxDt
        // )
        .filter(
          (product) =>
            product.contractTerm == contractLengthValue.minLt ||
            product.contractTerm == contractLengthValue.maxLt
        )
        .filter((product) => {
          const matchesComponentDeviceName =
            product.componentDeviceName &&
            product.componentDeviceName.toLowerCase().includes(lowercasedQuery);
          const matchesProductDeviceName =
            product.productDeviceName &&
            product.productDeviceName
              .toLowerCase()
              .includes(lowercasedQuery?.includes("gb" || "GB"));
          const matchesfinalPrice = product.finalPrice
            .toString()
            .includes(lowercasedQuery);
          const matchesData = product.data.toString().includes(lowercasedQuery);
          const matchesContractTerm = product.contractTerm
            .toString()
            .includes(lowercasedQuery);
          const matchesNoMinutes =
            product.noOfMinutes === -1 &&
            "unlimited".startsWith(lowercasedQuery);
          return (
            matchesComponentDeviceName ||
            matchesProductDeviceName ||
            matchesfinalPrice ||
            matchesData ||
            matchesContractTerm ||
            matchesNoMinutes
          );
        });
      if (allFilteredData) {
        setAllFilteredData(allFilteredData);
      }
    }

    // clean up function
    return () => {
      // Optional: Reset filtered data or perform any necessary cleanup
      setAllFilteredData([]); // Example cleanup action
    };
  }, [
    priceFilterValue,
    dataFilterValue,
    contractLengthValue,
    allPlanData,
    searchQuery,
  ]);
  useEffect(() => {
    dispatch(productAsync());
    return () => {
      // Implement cleanup here if needed
    };
  }, []);

  const MultiRangeSliderComponent = () => {
    return (
      <div className=" flex flex-col gap-6">
        <div className="mx-3 mt-2" id="prize-range-filter">
          <div className="flex justify-between items-center flex-wrap">
            <label className="font-semibold" htmlFor="">
              Price Ranges <br />
              <span className="text-lg font-semibold">
                £(
                {priceFilterValue?.minPr == 1
                  ? minPriceValue
                  : priceFilterValue?.minPr}{" "}
                -{" "}
                {priceFilterValue?.maxPr == 1000
                  ? maxPriceValue
                  : priceFilterValue?.maxPr}
                )
              </span>
            </label>
            {priceFilterReset && (
              <p
                onClick={() => {
                  setPriceFilterReset(false);
                  setPriceFilterValue({
                    minPr: minPriceValue,
                    maxPr: maxPriceValue,
                  });
                }}
                className="text-red-500 underline cursor-pointer"
              >
                Reset
              </p>
            )}
          </div>

          {minPriceValue && maxPriceValue && minPriceValue !== maxPriceValue ? (
            <MultiRangeSlider
              min={minPriceValue}
              max={maxPriceValue}
              minValue={priceFilterValue?.minPr}
              maxValue={priceFilterValue?.maxPr}
              step={1}
              onChange={(e) => {
                if (
                  e.maxValue == maxPriceValue &&
                  e.minValue == minPriceValue
                ) {
                  setPriceFilterReset(false);
                } else {
                  setPriceFilterReset(true);
                }
                setPriceFilterValue({
                  minPr: e.minValue,
                  maxPr: e.maxValue,
                });
              }}
            ></MultiRangeSlider>
          ) : null}
        </div>

        {/* {minDataValue && maxDataValue
          ? minDataValue !== maxDataValue && (
              <div className="mx-3" id="data-filter">
                <label htmlFor="">
                  Data:{" "}
                  <span className="text-lg font-semibold">
                    (
                    {dataFilterValue?.minDt == 1
                      ? minDataValue
                      : dataFilterValue?.minDt}{" "}
                    -{" "}
                    {dataFilterValue?.maxDt == 1000
                      ? maxDataValue
                      : dataFilterValue?.maxDt}
                    ) GB
                  </span>
                </label>
                {minDataValue && maxDataValue ? (
                  <MultiRangeSlider
                    min={minDataValue}
                    max={maxDataValue}
                    minValue={dataFilterValue?.minDt}
                    maxValue={dataFilterValue?.maxDt}
                    step={1}
                    onChange={(e) => {
                      if (
                        e.maxValue == maxDataValue &&
                        e.minValue == minDataValue
                      ) {
                        setDataFilterReset(false);
                      } else {
                        setDataFilterReset(true);
                      }
                      setDataFilterValue({
                        minDt: e.minValue,
                        maxDt: e.maxValue,
                      });
                    }}
                  ></MultiRangeSlider>
                ) : null}
              </div>
            )
          : null} */}

        {/* {minContractLengthValue && maxContractLengthValue
          ? minContractLengthValue !== maxContractLengthValue && ( */}
        {minContractLengthValue == 12 && maxContractLengthValue == 24 && (
          <div className="mx-3" id="contract-filter">
            <div className="flex flex-wrap justify-between items-center">
              <label className="font-semibold" htmlFor="">
                Contract length
                {/* <span className="text-lg font-semibold">
                    (
                    {contractLengthValue?.minLt === 1
                      ? minContractLengthValue
                      : contractLengthValue?.minLt}{" "}
                    -{" "}
                    {contractLengthValue?.maxLt === 1000
                      ? maxContractLengthValue
                      : contractLengthValue?.maxLt}
                    ) Months
                  </span> */}
              </label>
              {contractFilterReset && (
                <p
                  onClick={() => {
                    setContractFilterReset(false);
                    setContractLengthValue({
                      minLt: 12,
                      maxLt: 24,
                    });
                  }}
                  className="text-red-500 underline cursor-pointer"
                >
                  Reset
                </p>
              )}
            </div>

            <div className="flex flex-wrap gap-1 mt-2">
              <button
                onClick={() => {
                  setContractLengthValue({
                    minLt: 12,
                    maxLt: 0,
                  });
                  setContractFilterReset(true);
                }}
                className={`rounded text-sm px-2 py-1 lg:px-3 lg:py-2 font-semibold ${
                  contractLengthValue?.minLt === 12 &&
                  contractLengthValue?.maxLt === 0
                    ? "bg-[#5a949a] text-white"
                    : "bg-transparent border border-[#5a949a] text-[#5a949a]"
                }`}
              >
                12 Months
              </button>
              <button
                onClick={() => {
                  setContractLengthValue({
                    minLt: 0,
                    maxLt: 24,
                  });
                  setContractFilterReset(true);
                }}
                className={`rounded text-sm px-2 py-1 lg:px-3 lg:py-2 font-semibold ${
                  contractLengthValue?.minLt === 0 &&
                  contractLengthValue?.maxLt === 24
                    ? "bg-[#5a949a] text-white"
                    : "bg-transparent border border-[#5a949a] text-[#5a949a]"
                }`}
              >
                24 Months
              </button>
            </div>
            {/* {minContractLengthValue && maxContractLengthValue ? (
                  <MultiRangeSlider
                    min={minContractLengthValue}
                    max={maxContractLengthValue}
                    minValue={contractLengthValue?.minLt}
                    maxValue={contractLengthValue?.maxLt}
                    step={1}
                    onChange={(e) => {
                      if (
                        e.maxValue == maxContractLengthValue &&
                        e.minValue == minContractLengthValue
                      ) {
                        setContractFilterReset(false);
                      } else {
                        setContractFilterReset(true);
                      }
                      setContractLengthValue({
                        minLt: e.minValue,
                        maxLt: e.maxValue,
                      });
                    }}
                  ></MultiRangeSlider>
                ) : null} */}
          </div>
        )}
        {/* )
           : null} */}

        <div className="px-4 border-b border-gray-200 mt-4">
          <h2 className="text-lg font-medium">Network</h2>
        </div>
        <div>
          <div className="grid grid-cols-2 gap-2 mx-3">
            {brands.map((item, index) => (
              <div
                key={index}
                // onClick={() => setBrandIndex(item.id)}
                className={`${
                  brandIndex === index ? "filter-active" : ""
                } relative cursor-pointer w-full flex justify-center items-center border custom-border-50 h-16 rounded-lg`}
              >
                {index == 0 ? null : (
                  <div className="bg-[#5a949a]/95 px-3 absolute w-full h-full rounded-[7px] text-white flex justify-center items-center">
                    <p className="text-center text-sm leading-none m-auto font-semibold">
                      Coming soon...
                    </p>
                  </div>
                )}
                <img className="m-auto" src={item.img} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {planDataStatus === "loading" ? (
        <div className="min-h-[100vh] flex flex-col items-center">
          <p className="mt-20 mb-4">Best deals are on the way...</p>
          <img width={60} height={60} src={loadingGif} alt="Loading..." />
        </div>
      ) : planDataStatus === "idle" ? (
        <div className="mt-3">
          {/* Mobile filter dialog */}
          <Transition show={mobileFiltersOpen}>
            <div
              className="relative z-40 lg:hidden"
              onClose={setMobileFiltersOpen}
            >
              <TransitionChild
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </TransitionChild>

              <div className="fixed inset-0 z-40 flex">
                <TransitionChild
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                    <div className="flex items-center justify-between px-4 border-b border-gray-200">
                      <button
                        // onClick={() => {
                        //   setFilterReset(false);
                        //   setPriceFilterValue({
                        //     minPr: minPriceValue,
                        //     maxPr: maxPriceValue,
                        //   });
                        //   setDataFilterValue({
                        //     minDt: minDataValue,
                        //     maxDt: maxDataValue,
                        //   });
                        //   setContractLengthValue({
                        //     minLt: 12,
                        //     maxLt: 24,
                        //   });
                        // }}
                        // ${
                        //   filterReset
                        //     ? "border-2 border-red-500 text-red-500 bg-red-500/20 px-3 py-1 cursor-pointer"
                        //     : ""
                        // }
                        className={`rounded text-lg font-medium flex items-center mb-2`}
                      >
                        Filters
                        {/* {filterReset ? "Reset all" : "Filters"} */}
                        {/* {filterReset && <RxCross2 className="text-xl ml-2" />} */}
                      </button>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-lg-md bg-white p-2"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Filters */}
                    <div className="mt-4 flex flex-col gap-4">
                      {/* Mobile Multi Range Slider */}
                      <MultiRangeSliderComponent />
                      {/* Mobile Multi Range Slider */}
                      {/* <div className="px-4 border-b border-gray-200 mt-4">
                        <h2 className="text-lg font-medium">Brands</h2>
                      </div>
                      <div>
                        <div className="grid grid-cols-2 gap-2 mx-3">
                          {brands.map((item, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                setBrandIndex(item.id);
                                setTimeout(() => {
                                  setMobileFiltersOpen(false);
                                }, 300);
                              }}
                              className={`${
                                brandIndex === index ? "filter-active" : ""
                              } cursor-pointer w-full flex justify-center items-center border custom-border-50 h-16 rounded-lg`}
                            >
                              <img className="m-auto" src={item.img} alt="" />
                            </div>
                          ))}
                        </div>
                      </div> */}
                    </div>
                  </div>
                </TransitionChild>
              </div>
            </div>
          </Transition>
          <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between border-b border-gray-200 pb-3">
              <div className="lg:w-auto w-full flex justify-between items-center">
                <h1 className="text-2xl font-semibold tracking-tight">
                  Best deals
                </h1>
              </div>
              <div className="lg:w-auto w-full flex flex-wrap justify-end gap-8 items-center mt-4">
                <div className="flex items-center">
                  <label className="font-medium" htmlFor="">
                    Search:
                  </label>
                  <div className="relative">
                    <input
                      className="rounded-lg bg-transparent border custom-border-50 focus:border-2 focus:custom-border-50 placeholder:text-teal-800/50 placeholder:text-sm w-52 ml-2 h-10"
                      type="text"
                      placeholder="search '30 GB' ..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <FaSearch className="text-sm absolute right-2 top-1/2 -translate-y-1/2" />
                  </div>
                </div>
                {/* <div className="h-8 flex justify-center tabs border custom-border-50 rounded-lg items-start cursor-pointer">
                  {filterTabs.map((item, index) => (
                    <button
                      key={index}
                      onClick={() => setTabsActive(index)}
                      className={` px-4 py-1 text-sm h-full ${
                        tabsActive === index
                          ? "filter-active rounded-[0.4rem]"
                          : "rounded-lg"
                      }`}
                    >
                      {item.title}
                    </button>
                  ))}
                </div> */}
                <button
                  type="button"
                  className="p-2 primary-text hover:text-teal-800 lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className="sr-only">Filters</span>
                  {filterReset ? (
                    <FaFilterCircleXmark
                      title="Apply filter"
                      className="h-8 w-8 text-red-600"
                      aria-hidden="true"
                    />
                  ) : (
                    <FunnelIconOutline
                      title="Apply filter"
                      className="h-8 w-8"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
            <section aria-labelledby="products-heading" className="pb-24 pt-6">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>
              <div className="grid grid-cols-1 gap-x-4 gap-y-10 lg:grid-cols-4">
                {/* Filters  */}
                <div className="hidden lg:flex relative ml-auto h-full w-full max-w-xs flex-col overflow-y-auto  pb-12 ">
                  <div className="border custom-border-50 rounded-lg py-4">
                    <div className="flex items-center justify-between px-4 border-b border-gray-200">
                      <button
                        // onClick={() => {
                        //   setFilterReset(false);
                        //   setPriceFilterValue({
                        //     minPr: minPriceValue,
                        //     maxPr: maxPriceValue,
                        //   });
                        //   setDataFilterValue({
                        //     minDt: minDataValue,
                        //     maxDt: maxDataValue,
                        //   });
                        //   setContractLengthValue({
                        //     minLt: 12,
                        //     maxLt: 24,
                        //   });
                        // }}
                        // ${
                        //   filterReset
                        //     ? "border-2 border-red-500 bg-red-500/20 text-red-500 px-3 py-1 cursor-pointer"
                        //     : ""
                        // }
                        className={` rounded text-lg font-medium flex items-center mb-2`}
                      >
                        Filter
                        {/* {filterReset ? "Reset all" : "Filter"} */}
                        {/* {filterReset && <RxCross2 className="text-xl ml-2" />} */}
                      </button>
                    </div>
                    {/* Web Multi range slider Filters */}
                    <MultiRangeSliderComponent />
                    {/* Web Multi range slider Filters */}
                  </div>
                </div>
                {/* Product grid */}
                <div className="lg:col-span-3 relative">
                  {/* <div className="absolute bottom-[50px] z-50 w-full h-[200px] bg-grad"></div> */}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    {allFilteredData?.length > 0 &&
                      allFilteredData?.map((item, index) => (
                        <ProductCard
                          key={index}
                          data={item}
                          brandIndex={brandIndex}
                          handleBuyNow={handleBuyNow}
                        />
                      ))}
                  </div>
                  {allFilteredData?.length == 0 && (
                    <div className="w-full min-h-screen flex flex-col items-center mt-24">
                      <LuSearchX className="text-5xl mb-2" />
                      <p>Sorry, we couldn't find any results</p>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </main>
        </div>
      ) : null}
      <ModalComponent
        size="md"
        type="pending"
        position="center"
        description=""
        bottomLine={true}
        title={
          modalContentId === "network error"
            ? "Network Error Occured"
            : modalContentId == "internal error"
            ? "Server Error Occured"
            : modalContentId === "server error"
            ? "Server Error Occured"
            : modalContentId === "unknown error"
            ? "Unknown Error Occured"
            : modalContentId === "please login first"
            ? "Verification pending"
            : modalContentId === "user not verified"
            ? "Verification pending"
            : null
        }
      >
        <div className="flex flex-col">
          {modalContentId === "network error" ? (
            <p>Network Error Occured. Please try again later.</p>
          ) : modalContentId === "internal error" ? (
            <p>Internal Error Occured. Please try again later.</p>
          ) : modalContentId === "server error" ? (
            <p>Server Error Occured. Please try again later.</p>
          ) : modalContentId === "unknown error" ? (
            <p>Unknown Error Occured. Please try again later.</p>
          ) : modalContentId === "please login first" ? (
            <p>Please log in to buy a plan.</p>
          ) : modalContentId === "user not verified" ? (
            <p>
              A verification link has been sent to your registered email
              address, Please click on{" "}
              <span className="font-semibold">"Verify Email"</span> button to
              verify
            </p>
          ) : null}
          <Button
            handleClick={() => {
              dispatch(closeModal());
            }}
            title="Okay"
            type="button"
            style="rounded mx-auto w-1/2 text-sm px-4 lg:px-5 py-2 lg:py-2.5 cta-one rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mt-4 mx-auto font-semibold px-3 py-1.5 text-center"
          />
        </div>
      </ModalComponent>
      {loader && <Loader />}
    </>
  );
}

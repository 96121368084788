import React from "react";
// import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const AccordionComponent = ({ data }) => {
  const AccordionItem = ({ header, ...rest }) => (
    <Item
      {...rest}
      header={({ state: { isEnter } }) => (
        <>
          {header}
          <ChevronDownIcon
            style={{ fontSize: 8, width: "18px", height: "18px" }}
            className={`text-sm ml-auto transition-transform duration-200 ease-out ${
              isEnter && "rotate-180"
            }`}
          />
        </>
      )}
      className="border-b"
      buttonProps={{
        className: ({ isEnter }) =>
          `flex w-full p-4 text-left hover:bg-[#5A949A]/25 rounded ${
            isEnter && "bg-[#5A949A]/20"
          }`,
      }}
      contentProps={{
        className: "transition-height duration-200 ease-out bg-[#5A949A]/10",
      }}
      panelProps={{ className: "p-4" }}
    />
  );
  return (
    <Accordion transition transitionTimeout={200}>
      {data.map((item) => (
        <AccordionItem key={item.id} header={item.question}>
          {item.answer}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default AccordionComponent;

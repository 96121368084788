import axios from 'axios';
import { API_URL } from '../constant/Constant';
class ChangePasswordService {

    updatePassword(json) {
        return axios.post(`${API_URL}authentication/changePassword`,json,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization':`Bearer ${localStorage.getItem('accesstoken')}`
              }
        });
    }
}
export default new ChangePasswordService()
import React, { useEffect, useState } from "react";
import Loader from "../loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ModalComponent from "../modalComponent/ModalComponent";
import { closeModal, openModal } from "../modalComponent/modalSlice";
import Button from "../button/Button";
import VerificationService from "../../services/VerificationService";

const VerificationResponse = () => {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const [failedValue, setFailedValue] = useState("");
  const { id } = useParams();
  const modalInitState = useSelector((state) => state.modal);

  //for dealer verification
  //console.log("id in rgister====", id);
  useEffect(() => {
    if (id !== undefined && id !== null && id !== "") {
      setShowLoader(true);
      //console.log("idd====", id);
      VerificationService.doVerification(id)
        .then((response) => {
          setShowLoader(false);
          //console.log("response=====", response.data);
          //console.log("response tttt=====", response.status);

          if (response.status === 200) {
            localStorage.setItem("accesstoken", response.data.token);
            localStorage.setItem("emailId", response.data.email);
            localStorage.setItem("name", response.data.name);
            localStorage.setItem("userId", response.data.id);
            localStorage.setItem("isVerified", response.data.isVerified);
            localStorage.setItem("roleId", response.data.role.roleId);
            localStorage.setItem(
              "reffralCode",
              response.data.referralCode ? response.data.referralCode : ""
            );
            localStorage.setItem("loggedIn", true);
            localStorage.setItem("customerVerified", 1);
            setFailedValue("");
            setMessage("login successful");
            navigate("/");
          } else if (response.status === 202) {
            setFailedValue(202);
            dispatch(openModal("verification success"));

            localStorage.setItem("dealerId", response.data.dealerId);
            //console.log("rsponse 202=====");
          } else if (
            response.data.failedValue === 39 ||
            response.data.failedValue === 40
          ) {
            //console.log("======failed value ==", response.data.failedValue);
            setFailedValue(response.data.failedValue);
          } else {
            setMessage("");
          }
        })
        .catch((error) => {
          setShowLoader(false);
          if (error.message === "Network Error") {
            setMessage("Network Error");
            dispatch(openModal("Network Error"));
          } else {
            //console.log("errrrroorrr res====", error.response);
            //console.log("errrrroorrr====", error.response.data);

            switch (error.response ? error.response.status : "") {
              case 401:
                setMessage(error.response.data.failedReason);
                break;
              case 403:
                //show internal error
                dispatch(openModal("internal error"));
                break;
              case 404:
                dispatch(openModal("internal error"));
                break;
              case 500:
                setMessage("Server error occured. Please try again later.");
                dispatch(openModal("server error"));
                break;
              case 406:
                setFailedValue(40); // verification link is expired
                dispatch(openModal("link expired"));
                break;
              case 409:
                setFailedValue(39); // email allready verified
                dispatch(openModal("allready verified"));
                break;
              case 412:
                dispatch(openModal("unknown Error"));
                break;
              default:
                dispatch(openModal("unknown Error"));
                break;
            }
          }
        });
      // .finally(() => {
      //   setShowLoader(false);
      // });
    }
  }, []);

  return (
    <>
      <ModalComponent
        size="md"
        type={
          modalInitState.contentId === "verification success" ||
          "allready verified"
            ? "success"
            : "failed"
        }
        crossButton={
          modalInitState.contentId === "verification success" ||
          "allready verified"
            ? false
            : true
        }
        position="center"
        title={
          modalInitState.contentId === "network error" ||
          modalInitState.contentId === "internal error" ||
          modalInitState.contentId === "server error" ||
          modalInitState.contentId === "unknown error" ||
          modalInitState.contentId === "link expired" ||
          modalInitState.contentId === "allready verified"
            ? "Verification Successful"
            : modalInitState.contentId === "verification success"
            ? "Verification Successful"
            : "Error Occurred"
        }
        description=""
        bottomLine={true}
      >
        <div>
          {modalInitState.contentId === "network error" ? (
            <p>Network Error Occurred. Please try again later.</p>
          ) : modalInitState.contentId === "internal error" ? (
            <p>Internal Error Occurred. Please try again later.</p>
          ) : modalInitState.contentId === "server error" ? (
            <p>Server Error Occurred. Please try again later.</p>
          ) : modalInitState.contentId === "unknown error" ? (
            <p>Unknown Error Occurred. Please try again later.</p>
          ) : modalInitState.contentId === "link expired" ? (
            <p>Verification link is expired!</p>
          ) : modalInitState.contentId === "allready verified" ? (
            <div className="flex flex-col">
              <p>Verification link is Allready verified.</p>
              <Button
                handleClick={() => {
                  navigate("/");
                  dispatch(closeModal());
                }}
                title="Go to Homepage"
                type="button"
                style="rounded mx-auto w-1/2 text-sm px-4 lg:px-5 py-2 lg:py-2.5 cta-one rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mt-4 mx-auto font-semibold px-3 py-1.5 text-center"
              />
            </div>
          ) : modalInitState.contentId === "verification success" ? (
            <div className="flex flex-col">
              <p>Verification Done Successfully.</p>
              <Button
                handleClick={() => {
                  navigate("/");
                  dispatch(closeModal());
                }}
                title="Go to Homepage"
                type="button"
                style="rounded mx-auto w-1/2 text-sm px-4 lg:px-5 py-2 lg:py-2.5 cta-one rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mt-4 mx-auto font-semibold px-3 py-1.5 text-center"
              />
            </div>
          ) : null}
        </div>
      </ModalComponent>

      {showLoader && <Loader />}
    </>
  );
};

export default VerificationResponse;

import React, { useEffect, useState } from "react";
import { Transition, TransitionChild } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { closeDocumentModal } from "./DocumentModalSlice";
import PDFViewer from "pdf-viewer-reactjs";

import useScrollToTop from "../../customHooks/useScrollToTop";
import { GrDocumentText } from "react-icons/gr";
import DynamicComponent from "./DynamicComponent";

export default function DocumentModal({
  description,
  bottomLine,
  crossButton,
  children,
}) {
  const dispatch = useDispatch();
  const isDocumentOpen = useSelector(
    (state) => state.documentModal.isDocumentOpen
  );
  const documentContentId = useSelector(
    (state) => state.documentModal.contentId
  );
  const [pdfDocument, setPdfDocument] = useState(null);
  useScrollToTop();
  return (
    <>
      <Transition appear show={isDocumentOpen}>
        {" "}
        <div className="relative z-[99] focus:outline-none document-modal">
          <div className="fixed inset-0 z-10 w-screen">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 transform-[scale(95%)]"
              enterTo="opacity-100 transform-[scale(100%)]"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 transform-[scale(100%)]"
              leaveTo="opacity-0 transform-[scale(95%)]"
            >
              <div
                className={`bg-teal-800/50 flex min-h-screen justify-center p-4`}
              >
                <div
                  className={`w-full bg-secondary top-0 border rounded-lg max-w-6xl max-h-[95vh] p-4 backdrop-blur-2xl overflow-y-hidden`}
                >
                  <div
                    className={`w-full h-10 flex flex-col justify-between items-center ${
                      bottomLine ? "border-b-2" : ""
                    }`}
                  >
                    <div className="w-full flex justify-between items-center pb-2 relative">
                      {documentContentId && (
                        <div className="flex items-center gap-2">
                          <GrDocumentText className="text-[20px]" />
                          <h3 className={`text-lg font-semibold`}>
                            {documentContentId == 1
                              ? "Partner C8 Info"
                              : documentContentId == 2
                              ? "Three Mobile T&C"
                              : documentContentId == 3
                              ? "GKT Telecom Ltd Privacy policy"
                              : ""}
                          </h3>
                        </div>
                      )}
                      {crossButton !== false && (
                        <button
                          onClick={() => dispatch(closeDocumentModal())}
                          className="absolute right-1 top-1 inline-flex items-center gap-2 rounded-md hover:bg-slate-500/20 transition p-0"
                        >
                          <XMarkIcon className="w-5" />
                        </button>
                      )}
                      {description && <p>{description}</p>}
                    </div>
                  </div>
                  <div className="h-full overflow-y-auto pb-12">
                    <div className="w-full flex flex-col justify-center items-center">
                      <DynamicComponent documentId={documentContentId} />
                      <button
                        onClick={() => dispatch(closeDocumentModal())}
                        className="px-8 mx-auto mt-4 text-lg tracking-tight text-white capitalize bg-primary hover:bg-primary-dark transition p-2 rounded-lg"
                      >
                        Viewed & Close Document
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Transition>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import RegisterService from "../../services/RegisterService";
import ModalComponent from "../modalComponent/ModalComponent";
import { closeModal, openModal } from "../modalComponent/modalSlice";
import Button from "../button/Button";
import Loader from "../loader/Loader";

const SignupContract = () => {
 const [showLoader, setShowLoader] = useState(false);
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const [message, setMessage] = useState();
 const { id } = useParams();
 const modalInitState = useSelector((state) => state.modal);
 const sigCanvas = useRef(null);
 const [error, setError] = useState(""); // Error state for validation
 const [contractData, setContractData] = useState(null); // State to hold contract data
 const [signupContractData, setSignupContractData] = useState({
 userId: "",
 signature: "",
 });

 useEffect(() => {
 // Fetch contract data on component mount
 setShowLoader(true);
 RegisterService.getSignupContractById(id)
 .then((response) => {
 setShowLoader(false);
 console.log("response.data---",response.data);
 if (response.status === 200) {
 setContractData(response.data); // Set contract data to state
 } else {
 setError("Failed to fetch contract data");
 }
 })
 .catch((error) => {
 console.log("inside catchhhh")
 setShowLoader(false);
 if (error.message === "Network Error") {
 setMessage("Network Error");
 } else {
 switch (error.response ? error.response.status : "") {
 case 401:
 setMessage(error.response.data.failedReason);
 break;
 case 403:
 //show internal error
 dispatch(openModal("internal error"));
 break;
 case 404:
 dispatch(openModal("internal error"));
 break;
 case 500:
 setMessage("Server error occured. Please try again later.");
 dispatch(openModal("server error"));
 break;
 case 406:
 dispatch(openModal("allready acknowledge"));
 break;
 case 409:
 dispatch(openModal("allready verified"));
 break;
 case 412:
 dispatch(openModal("unknown Error"));
 break;
 default:
 dispatch(openModal("unknown Error"));
 break;
 }
 }
 });
 }, [id]);


 // Clear the signature pad
 const clear = () => {
 sigCanvas.current.clear();
 setError(""); // Reset error on clear
 };

 // Save the signature with validation
 const save = () => {
 if (sigCanvas.current.isEmpty()) {
 setError("Signature is required!"); // Show error if signature pad is empty
 } else {
 setShowLoader(true);
 const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
 console.log(signature); // This gives you the signature as a base64 URL
 setError(""); 
 const signupContractData = { userId: id, signature: signature };
 setSignupContractData(signupContractData);
 RegisterService.signupContractAcknowledge(signupContractData)
 .then((response) => {
 setShowLoader(false);

 if (response.status == 200) {
 console.log("success--",response)
 dispatch(openModal("acknowledgement success"));
 } else {
 if (response.data && response.data.failedReason) {
 setError(response.data.failedReason);
 } else {
 setMessage("acknowlegdement failed for an unknown reason.");
 }
 // setCode(response.status);
 }
 })
 .catch((error) => {
 setShowLoader(false);
 if (error.message === "Network Error") {
 setMessage("Network Error");
 } else {
 switch (error.response ? error.response.status : "") {
 case 401:
 setMessage(error.response.data.failedReason);
 break;
 case 403:
 //show internal error
 dispatch(openModal("internal error"));
 break;
 case 404:
 dispatch(openModal("internal error"));
 break;
 case 500:
 setMessage("Server error occured. Please try again later.");
 dispatch(openModal("server error"));
 break;
 case 406:
 dispatch(openModal("allready acknowledge"));
 break;
 case 409:
 dispatch(openModal("allready verified"));
 break;
 case 412:
 dispatch(openModal("unknown Error"));
 break;
 default:
 dispatch(openModal("unknown Error"));
 break;
 }
 }
 });
 }
 };

 
 return (
 <>
 {showLoader && <Loader />} {/* Show loader when fetching data */}
 
 {!showLoader && id ? ( // Only render the following if loader is not shown
 contractData ? (
 <div className="md:basis-1/2 basis-1 md:mx-2 mx-2">
 <div style={{ padding: '0 20px' }}> {/* Add padding to left and right */}
 <h2>GK Telecom Ltd.</h2>
 
 <p style={{ marginBottom: '1em' }}> {/* Add margin to create space between paragraphs */}
 This agreement (the "Agreement") between {contractData.name}, VAT number of 
 <strong> {contractData.vatNum}</strong>, and <strong>GK Telecom Ltd</strong>, with a VAT number of <strong>GB 322637908</strong>.
 </p>
 
 <h3>Scope of Services:</h3>
 <p style={{ marginBottom: '1em' }}>
 GK Telecom Ltd will provide vinyl installation services to {contractData.name} for the 
 <strong> {contractData.address}</strong> location (the "Project").
 </p>
 
 <h3>Materials:</h3>
 <p style={{ marginBottom: '1em' }}>
 GK Telecom Ltd will supply all necessary vinyl materials, tools, and equipment required for the Project.
 </p>
 
 <h3>Workmanship:</h3>
 <p style={{ marginBottom: '1em' }}>
 GK Telecom Ltd will use best industry practices and standards to complete the Project. The vinyl installation will be done in a professional manner and with great care to ensure that the vinyl is properly installed and durable.
 </p>
 
 <h3>Payment:</h3>
 <p style={{ marginBottom: '1em' }}>
 GK Telecom Ltd will pay {contractData.name} the agreed deal for the vinyl installation services provided. The payment will be made as per the schedule agreed by both parties.
 </p>
 
 <h3>Cancellation:</h3>
 <p style={{ marginBottom: '1em' }}>
 If GK Telecom Ltd decides to cancel the Project within 14 days of entering into the agreement, no penalties will be incurred. However, if the cancellation occurs after the 14-day period, GK Telecom Ltd will be responsible for paying {contractData.name} for any expenses incurred or work completed up to that point, limiting liability to a maximum of £100.
 </p>
 
 <h3>Termination:</h3>
 <p style={{ marginBottom: '1em' }}>
 Either party may terminate this Agreement upon written notice to the other party if there is a breach of any of the terms and conditions of this Agreement.
 </p>
 
 <h3>Governing Law:</h3>
 <p style={{ marginBottom: '1em' }}>
 This Agreement will be governed by the laws of the United Kingdom in which the Project is located.
 </p>
 
 <h3>Entire Agreement:</h3>
 <p style={{ marginBottom: '1em' }}>
 This Agreement contains the entire agreement between the parties and supersedes all prior negotiations, understandings or agreements between the parties relating to the Project.
 </p>
 
 <h3>Amendments:</h3>
 <p style={{ marginBottom: '1em' }}>
 This Agreement may not be amended, altered, or modified except in writing and signed by both parties.
 </p>
 
 <h3>Self-Billing Agreement:</h3>
 <p style={{ marginBottom: '1em' }}>
 This is a contract between GK Telecom Ltd and {contractData.name} regarding self-billing invoices, sales invoices, & display conditions. The key points are:
 </p>
 
 <h4>GK Telecom Ltd agrees:</h4>
 <ul style={{ marginBottom: '1em' }}>
 <li>To provide self-billed invoices that include all the necessary information such as client name, address, VAT registration number, and other details required to form a complete VAT invoice.</li>
 <li>To make a new self-billing agreement in the event that the client VAT registration number changes.</li>
 <li>To inform the client if the issue of self-billed invoices will be outsourced to a third party: If GK Telecom Ltd decides to outsource the issuance of self-billed invoices to a third party, they will notify the client.</li>
 </ul>
 
 <h4>{contractData.name} agrees:</h4>
 <ul style={{ marginBottom: '1em' }}>
 <li>Not to issue sales invoices for the transactions covered by this agreement.</li>
 <li>To display SIM stands supplied by GK Telecom Ltd only.</li>
 <li>To provide all assistance for uninterrupted vinyl installation.</li>
 <li>To maintain the window vinyl throughout the duration of the contract, as failure to do so may result in a charge for the full replacement cost.</li>
 <li>Not to place another poster/vinyl on top of it or obstruct GK Telecom Ltd supplied vinyl with any object.</li>
 <li>To notify GK Telecom Ltd immediately if they change their VAT registration number, cease to be VAT registered, or sell their business or part of their business.</li> 
 </ul>
 
 <p style={{ marginBottom: '1em' }}>
 GK Telecom Ltd <br />
 Digitally signed by GK Telecom Ltd.
 </p>
 
 {/* Signature Pad */}
 <div>
 {error && <p style={{ color: 'red' }}>{error}</p>}

 <SignatureCanvas
 ref={sigCanvas}
 penColor="black"
 canvasProps={{
 width: 500,
 height: 200,
 className: 'sigCanvas',
 style: { border: '2px solid #000', borderRadius: '5px' } // Add border here
 }}
 />
 
 </div>
 
 {/* Buttons for clearing and saving */}
 <div>
 <button style={{ marginRight: '20px' }} onClick={clear}>Clear</button>
 <button onClick={save}>Save</button>
 </div>
 
 {/* Error message if validation fails */}
 
 </div>
 </div>
 ) : (
 <p>No contract data found!</p> // Show a message if contract data is not found
 )
 
 ) : (
 <div>
 <h1>Error</h1>
 <p>No ID provided in the URL!</p>
 </div>
 
 )}

<ModalComponent
 size="md"
 type={
 modalInitState.contentId === "acknowledgement success" ||
 "allready acknowledge"
 ? "success"
 : "failed"
 }
 crossButton={
 modalInitState.contentId === "acknowlegdement success" ||
 "allready acknowledge"
 ? false
 : true
 }
 position="center"
 title={
 modalInitState.contentId === "network error" ||
 modalInitState.contentId === "internal error" ||
 modalInitState.contentId === "server error" ||
 modalInitState.contentId === "unknown error" ||
 modalInitState.contentId === "link expired" ||
 modalInitState.contentId === "allready acknowledge"
 ? "Acknowledgement is Allready Done."
 : modalInitState.contentId === "acknowledgement success"
 ? "Acknowledgement Successful"
 : "Error Occurred"
 }
 description=""
 bottomLine={true}
 >
 <div>
 {modalInitState.contentId === "network error" ? (
 <p>Network Error Occurred. Please try again later.</p>
 ) : modalInitState.contentId === "internal error" ? (
 <p>Internal Error Occurred. Please try again later.</p>
 ) : modalInitState.contentId === "server error" ? (
 <p>Server Error Occurred. Please try again later.</p>
 ) : modalInitState.contentId === "unknown error" ? (
 <p>Unknown Error Occurred. Please try again later.</p>
 ) : modalInitState.contentId === "link expired" ? (
 <p>Verification link is expired!</p>
 ) : modalInitState.contentId === "allready acknowledge" ? (
 <div className="flex flex-col">
 <p>Acknowledement is Allready Done.</p>
 <Button
 handleClick={() => {
 navigate("/");
 dispatch(closeModal());
 }}
 title="Go to Homepage"
 type="button"
 style="rounded mx-auto w-1/2 text-sm px-4 lg:px-5 py-2 lg:py-2.5 cta-one rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mt-4 mx-auto font-semibold px-3 py-1.5 text-center"
 />
 </div>
 ) : modalInitState.contentId === "acknowledgement success" ? (
 <div className="flex flex-col">
 <p>Acknowledgement Done Successfully.</p>
 <Button
 handleClick={() => {
 navigate("/");
 dispatch(closeModal());
 }}
 title="Go to Homepage"
 type="button"
 style="rounded mx-auto w-1/2 text-sm px-4 lg:px-5 py-2 lg:py-2.5 cta-one rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mt-4 mx-auto font-semibold px-3 py-1.5 text-center" 
 />
 </div>
 ) : null}
 </div>
 </ModalComponent>

 {showLoader && <Loader />}
 </>
 );
} 

export default SignupContract;
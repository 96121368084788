import axios from 'axios';
import { API_URL } from '../constant/Constant';

class DashboardService {
  getDashboardDetails(startDate, endDate) {
    return axios.get(`${API_URL}api/v1/getDashboardDetails`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'startDate': startDate,
        'endDate': endDate,
        'Authorization':`Bearer ${localStorage.getItem('accesstoken')}`
      },
    });
  }
}

export default new DashboardService();

import axios from 'axios';
import { API_URL } from '../constant/Constant';
class CreditCheckService{

    async fetchCreditCheck(addFetchOrderRequest) {
        return await axios.get(`${API_URL}api/v1/fetchCreditCheck`,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'planId': addFetchOrderRequest.planId,
                'userId': addFetchOrderRequest.userId,
                'userIP': addFetchOrderRequest.userIP,
                'userLoggedIn': addFetchOrderRequest.userLoggedIn ? 'true' : 'false'
              }
        });
    }
}
export default new CreditCheckService()
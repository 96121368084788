import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import useScrollToTop from "../customHooks/useScrollToTop";

const GKTTnC = () => {
  useScrollToTop();
  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <h1 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text capitalize mb-4">
          IOT Mobile
          <br />
          Terms &amp; Conditions
        </h1>

        <section className="mx-auto max-w-5xl">
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              1. Definitions
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              1.1 In this Agreement, certain words shall have the following
              meanings:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Access Charge" means a rate set in respect of the retail and
              origination of a call to an Unbundled Tariff Number and its
              conveyance up to and including the Assumed Handover Point for the
              purpose of calculating the amount payable for making such a call;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Additional Services" means optional services which you may choose
              to take from us, such as, roaming and international services,
              premium rate services, directory enquiry services, Content and
              third party services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Agreement" means these terms and conditions (together with any
              other terms that are incorporated into or added to it from time to
              time), your Tariff Summary and our Privacy Policy;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Artificially Inflated Traffic" means any situation where the flow
              of calls, SMS, MMS and/or data to any particular revenue share
              service and/or GSM Gateway is, as a result of any activity on or
              on behalf of the party operating that revenue share service or GSM
              Gateway, disproportionate to the flow of calls which would be
              expected from good faith commercial practice and usage of the
              Network;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Assumed Handover Point" means the point of interconnection
              nearest to the origination of a call to an Unbundled Tariff Number
              at which the call may be handed over for conveyance;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Charges" means all the charges associated with the Services and
              Additional Services described in the Tariff and, further in our
              List of Charges including but not limited to any connection fee (a
              once only payment if applicable), monthly (or other periodic) line
              rental charge, call, SMS, MMS, browsing, GPRS and data charges and
              any other charges in respect of the Services provided to you or
              someone else using your Mobile Device; Charges also includes Early
              Termination Fees;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Content" means textual, visual or other information, software,
              photos, video, graphics, music, sound and other material appearing
              on or available through the Services including all information
              supplied by content providers from time to time;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Credit Limit" means an amount we have agreed you may spend on
              Services above your inclusive monthly allowance, which is on top
              of your monthly line rental. Please note that the Credit Limit
              does not act as a cap on Charges and you may be able to incur
              Charges which result in you exceeding your Credit Limit. If this
              happens, you will still be liable to pay for all Charges incurred
              in excess of your Credit Limit;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Early Termination Fees" means the charges made up of the
              remaining time left on your plan if you decide to end your
              services with us during the minimum contracted period, calculated
              by multiplying your Tariff by the remaining Minimum Period;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Eligible Calls" means calls and/or texts to UK standard landline
              or mobile numbers or services starting with 01, 02 and 03
              (excluding calls to the Isle of Man and the Channel Islands) and
              any other numbers that we expressly state are eligible; in the
              EEA, ‘Eligible Calls’ has the equivalent meaning and includes
              calls and/or texts to the visited country’s geographic numbers and
              mobile numbers;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Equipment" means equipment which is used by you in your use of
              the Services, including, your Mobile Device.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "EU Roaming Regulations" means Directive 531/2012 of 13 June 2012,
              Directive 2015/2120 of 25 November 2015 and Commission
              Implementing Regulation of 15 December 2016;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "EEA" means the European Economic Area, being the 27 EU Member
              States (other than the UK), Norway, Iceland, Lichtenstein and the
              Outermost Regions of Martinique, Mayotte, Guadeloupe, French
              Guyana, Réunion, Saint-Martin, Madeira, the Azores and the Canary
              Islands;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Fair Use Policy" means our fair use policy, if any, available on
              our Website at&nbsp;{" "}
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/"
              >
                https://iotmobile.co.uk
              </a>
              .
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "GPRS" means the General Packet Radio Service provided by us,
              which forms part of the Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "GSM Gateway" means a single point of access to a GSM network
              capable of using multiple SIM Cards/eSIM;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "INFO" means switching customer information which may include, but
              it is not limited to, any outstanding and unbilled balances and
              any Early Termination Fees;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "List of Charges" means the list detailing all our Charges
              (including call Charges) which is available on our Website
              at&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/"
              >
                https://iotmobile.co.uk
              </a>
              .
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Minimum Period" means any minimum period for you to receive and
              pay for the Services (where applicable in relation to your
              specific Tariff), which starts from the day on which the Services
              are first provided or from the day on which your Upgrade is
              effective (as applicable);
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "MMS" means Multimedia Messaging Service;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Mobile Device" means a cellular telephone or other device used by
              you to receive or use the Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Mobile Hotspots" are portable devices or features on smartphones
              that provide wireless Internet access for multiple devices at the
              same time (for example a laptop, smartphone, MP3 player, tablet,
              portable gaming device, etc.);
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Network" means the public switched telephone network and/or a
              wireless telegraphy telephony link by means of a cellular radio
              system used by us to provide the services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Order" means any order that you submit to us verbally or in
              writing for any of the Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "No Fee Right to Exit" means an option for you to terminate your
              contract without paying Early Termination Fees; you will have a No
              Fee Right to Exit only where this is set out in this Agreement;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "PAC" means Porting Authorisation Code; this is a unique
              identifier used by mobile network operators to facilitate mobile
              number portability (or MNP) for customers wishing to keep their
              number when they change network;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Payment Method" means the payment details that you provided in
              order to pay for the Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Personal Information" means the details you provide to us and any
              information generated through your dealings with us or use of the
              Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Post Pay" means a tariff where you pay a monthly amount in
              advance for line rental and a bundle of Services and where you are
              charged monthly in arrears for any out-of-bundle Services and
              Additional Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Roam Free Destinations" means Albania, Andorra, Anguilla, Antigua
              and Barbuda, Argentina, Armenia, Aruba (Netherlands Antilles),
              Azerbaijan, Bahamas, Bahrain, Bangladesh, Barbados, Bermuda,
              Bonaire (Netherlands Antilles), Bosnia and Herzegovina, British
              Virgin Islands, Brunei, Burkina Faso, Cambodia, Cameroon, Canada,
              Cayman Islands, China, Curacao (Netherlands Antilles), Cyprus,
              North (Turkey), Côte d’Ivoire (Ivory Coast), Dominica, Dominican
              Republic, Ecuador, Egypt, Faroe Islands, Gabon, Georgia, Ghana,
              Greenland, Grenada, Guinea, Guyana, Haiti, Honduras, India,
              Jamaica, Japan, Jordan, Kazakhstan, Kenya, Kosovo, Kuwait,
              Kyrgyzstan, Liberia, Madagascar, Malawi, Malaysia, Mexico,
              Moldova, Montenegro, Montserrat, Morocco, Myanmar, Niger, Nigeria,
              North Macedonia, Oman, Pakistan, Paraguay, Qatar, Rwanda, Saba
              (Netherlands Antilles), Saint Kitts and Nevis, Saint Lucia, Saint
              Vincent and the Grenadines, Saudi Arabia, Senegal, Serbia,
              Seychelles, Sint Eustatius (Netherlands Antilles), Sint Maarten
              (Netherlands Antilles), South Africa, South Korea, Suriname,
              Taiwan, Tajikistan, Thailand, Trinidad and Tobago, Tunisia,
              Turkey, Turks and Caicos Islands, Uganda, Ukraine, United Arab
              Emirates (UAE), Uzbekistan, Zambia
            </span>
          </p>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Services" means all or part of a telephone service provided
              through the Network and includes any service or facility comprised
              of the Services including the ability to make and receive calls,
              to send and receive data by means of the Network, to send and
              receive e-mails via the Internet, to access information from the
              Internet, GPRS and any additional service or product including
              without limitation, insurance and specified land line or other
              home service supplied to you by us from time to time;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "SIM Card and eSIM" means the Subscriber Identity Module card or
              digital profile containing data (including your identity) used to
              receive the Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "SIM Only" means a “Pay As You Go” or Post Pay tariff in relation
              to which a Mobile Device will not be supplied when you agree to
              purchase the Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "SMS" means Short Messaging Service;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "STAC" means Service Termination Authorisation Code; this is a
              unique identifier used by mobile network operators to facilitate
              auto switching for customers wishing to switch network without a
              port (also known as N-PAC);
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Surcharge" means an additional charge we may levy if your roaming
              use within our Roam Free Destinations exceeds the limit permitted
              by our Fair Usage Policy, but excludes our out of bundle rates
              which you can view on our Website at&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/"
              >
                https://iotmobile.co.uk/
              </a>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Switching Delay Compensation" means compensation to you from
              Network providers where there is a delay to the migration date
              during PAC / STAC process, including extended loss of service;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Tariff" means the price plan you have signed up to;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Tariff Summary" means the summary of your Tariff, as stated on
              the first page of your contract;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "UK" means the United Kingdom excluding Isle of Man and the
              Channel Islands;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Unbundled Tariff Number" means a non-geographic number starting
              084, 087, 090, 091, 098, or 118;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Upgrade" means the issue of a new Mobile Device to you or where
              you opt to change your Tariff on the condition that you enter into
              a minimum term contract with us on our then current terms and
              conditions;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "VAT" means value added tax chargeable under the Value Added Tax
              Act 1994 (or its successor Acts) and any similar replacement or
              additional tax;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "Website" means{" "}
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/"
              >
                https://iotmobile.co.uk/
              </a>
              . and any additional websites linked from such website or such
              other website used by us to promote and support the Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "you" and "your" means the customer with whom we make this
              Agreement, and it includes a person who is acting or using any
              Equipment with your authority or knowledge or who we reasonably
              believe is doing so.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              2. Structure of this Agreement
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              2.1 This Agreement covers the Services. It does not cover anything
              else you might buy from us.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              3. Duration
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              3.1 This Agreement will continue for the Minimum Period of 24
              months/30 days and will continue to apply after that unless and
              until either you or we terminate it in accordance with Clause 8
              below or otherwise in accordance with a right expressly set out in
              this Agreement.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              3.2 If you take an Upgrade from us, this will start a new Minimum
              Period running, and this may end later than the end of the
              previous Minimum Period.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              3.3 The Monthly Charge will stay the same unless GKT notifies you
              of a change, you select a new Package, or your discount expires.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              4. Provision of the Services
            </span>
          </h1>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              If you purchased a SIM Card or eSIM from one of our iot Mobile
              Platform, activation on the Network may take up to 48 hours.
              However, if you get a free PAYG SIM card from iot Mobile online
              Store, you can activate the plan immediately once you received the
              SIM card.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.1 The Services may not be available in all parts of the United
              Kingdom or abroad. You may not be able to use the Services which
              we agree to provide to you to call all countries or all
              international numbers. Unless otherwise agreed, the Services do
              not include call divert or call waiting. We reserve the right to
              add to, substitute, or discontinue any Additional Services at any
              time. We do not guarantee that we will continue to provide any
              particular Additional Services. Not all mobile devices will be
              able to receive the Services. If you buy a SIM Only tariff from
              us, your current device may be restricted to use on another
              network or "locked" and you may need to obtain an unlocking code
              from your current service provider to be able to receive the
              Services. Failure to enter the correct unlocking code may result
              in your device becoming permanently blocked.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.2 We will provide you with roaming services in the EEA in
              accordance with applicable legislation. For Post-Pay Tariffs, when
              your SIM Card/eSIM is connected to the iot Mobile., it will have
              been programmed so that you are able to use overseas networks to
              make calls and send MMS or SMS messages and data roam whilst
              overseas. In accordance with EU Roaming Regulations whilst roaming
              (both within and outside of the EU) you may be capped from
              exceeding the current data roaming spend limit which is £45
              (excluding VAT) per month. If you want to have your data roaming
              spend limit uncapped, then you should visit iot Mobile's website
              for methods of contact -&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
              . Please note: the EU Roaming Regulations do not apply to iot
              Mobile, but we have decided to continue to offer you the benefits
              that they provide anyway.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.2.1 If you use GKT services in our Roam Free Destinations, you
              can use up to 30GB of your monthly data allowance while roaming.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.2.1.1 If you have a plan with less than 30GB monthly data
              allowance, you can use any remaining data from your monthly
              inclusive allowance plus any data rollover and any add-on up to a
              maximum total of 30GB a month. When you exceed this you'll be
              charged an out of bundle rate of £0.10 per Mb (equivalent to
              £102.40 per GB).
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.2.1.2 If you have a plan with 30GB or more monthly data
              allowance (not including unlimited plans), you can use any
              remaining data from your monthly inclusive allowance up to 30GB a
              month. Once you exceed this, you will be surcharged £3 per GB up
              to your monthly inclusive data allowance plus any data rollover
              and add-on. Thereafter, you will be charged at our standard
              roaming out of bundle rate of £0.10Mb (equivalent to £102.40 per
              GB).
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.2.1.3 If you have an unlimited data plan, you can use up to 30GB
              in a month while roaming. Any use that is excess of 30GB, you will
              be surcharged at £3 per GB.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.2.2 Additional Services or add-ons are not part of your monthly
              inclusive allowance. However, subject to our Fair Use Policy
              add-ons may be used when roaming in our Roam Free Destinations.
              For more information, please see our&nbsp;Fair Use Policy.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.3 If you use your SIM Card/eSIM abroad, you will be charged for
              the calls that you receive as well as for those that you make
              except when you roam in the Economic European Area in which case,
              subject to our Fair Usage Policy, your voice and SMS usage will be
              charged at the same rate as the UK rate and your data allowance
              will be calculated in accordance with the EU Roaming Regulations.
              If your EU Roaming Use exceeds the EU Roaming Data Allowance
              (RDA), we may apply a Surcharge of £0.25 per MB of data used
              beyond the RDA, up to the amount your inclusive allowance under
              your Tariff. Use beyond your inclusive allowance will be charged
              under your Tariff. In respect of voice and SMS services, we may
              apply a Surcharge if we think you are roaming permanently,
              regardless of any allowance under your Tariff. Charges incurred
              using your roaming service may take longer to be charged than
              normal Charges. For details on surcharge and our Fair Usage Policy
              please visit&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/help-and-advice/eu-roaming/fair-usage-policy"
              >
                https://iotmobile.co.uk/help-and-advice/eu-roaming/fair-usage-policy
              </a>
              .
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.4 The Services are not guaranteed to be fault free and they may
              be impaired by things such as too many people trying to use the
              Network, geographical, topographical, atmospheric, or other
              conditions (including buildings, underpasses and other causes of
              interference) and/or circumstances beyond our reasonable control.
              Overseas networks may also be limited in quality and coverage.
              Outside the EEA, access to overseas networks will depend upon the
              arrangements between the foreign operators and us, details of
              which are available on the Website and which may change from time
              to time.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.5 The network coverage and the data speed will vary due to a
              number of factors including your location, local geography,
              congestion, your plan and the type of device that you are using.
              For example, you would only be able to use 5G services with a
              5G-ready device, eligible plan and within the 5G coverage area.
              For more information please visit&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="www.three.co.uk/coverage"
              >
                www.three.co.uk/coverage
              </a>
              .
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              The estimated maximum speed that you are likely to experience on
              our network is as follows:
            </span>
          </p>
          <div align="left">
            <table
              style={{
                width: "100%",
                margin: "10px 0",
                border: "none",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <strong>
                        <span style={{ fontSize: "11pt" }}>Network Type</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <strong>
                        <span style={{ fontSize: "11pt" }}>Download</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <strong>
                        <span style={{ fontSize: "11pt" }}>Upload</span>
                      </strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>3G</span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>18 Mbps</span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>2.6 Mbps</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>4G</span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>70 Mbps</span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>22 Mbps</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>5G</span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>597 Mbps</span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>50 Mbps</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Speeds can fluctuate based on several factors, such as your
              distance from the closest mast, your position within a building,
              the surrounding geography, network congestion, and the equipment
              you're using
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Throughout 2023 and 2024, we will be upgrading and modifying our
              Network. After some of these changes, you will need a 4G or 5G
              handset that can make voice calls on the 4G network
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              For more details on coverage, please visit&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="www.three.co.uk/coverage"
              >
                www.three.co.uk/coverage
              </a>
              .
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.6 If you experience continuous or recurring disruption of our
              Services or if there is a technical fault on our network that is
              caused by us, you may be entitled to a partial or full credit of
              your charges based on severity of the disruption caused and the
              number of days your Services remained disrupted. To receive credit
              of your charges, you must report to us the nature of the
              disruption you experienced as soon as reasonably practicable, and
              no later than 6 months after the disruption began. We will then
              investigate the severity and its impact on your Services against
              your typical usage history and take next steps to resolve the
              disruption reported.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.6.1 If the Services are not available to you for 7 days or
              longer you may terminate the agreement. You must contact us to
              confirm that services are not available to you and if we deem that
              the root cause originated with us, then no Early Termination Fees
              will apply.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.6.2 We will not be responsible for any loss of service due to
              something outside of our reasonable control.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.7 We aim to connect your SIM Card/eSIM to the Services within 14
              days of accepting your order. If you place an order for an
              Additional Service or otherwise instruct us to change the Services
              (e.g. to bar calls) we will use reasonable endeavours to complete
              your order as soon as possible, but there may be a delay in your
              order being completed. We will not be liable to you for any costs,
              expenses or claims arising directly or indirectly out of such
              delay.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.8 In the interest of other users, the number and duration of
              messages that can be left on your voicemail service will be
              limited. Please note that the confidentiality of your messages
              cannot be guaranteed. You must not record an abusive greeting
              message. You may not be able to use the voicemail service whilst
              abroad.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.9 You may use the Services to access or link into websites,
              resources and/or networks worldwide. We accept no responsibility
              for the content, services or otherwise in respect of these and you
              agree to confirm to the acceptable use policies of such websites,
              resources and/or networks.
            </span>
          </p>
          <p>
            <span style={{ fontSize: "11pt" }}>
              4.10 By default, billing is provided through your online account
              at&nbsp;iotmobile.co.uk&nbsp;. If you would prefer to receive your
              bill in a paper format (for which we may charge you in accordance
              with the List of Charges) please visit iot Mobile website for
              methods of contact –&nbsp;&nbsp;and we will arrange this for you.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.11 On connection of your SIM Card/eSIM to the Network (or at any
              time later on), we may set a credit limit on the Charges you may
              incur during each calendar month (we call this a "Credit Limit").
              We shall give you notice if we change your Credit Limit. If you
              wish to vary your Credit Limit, you should visit iot Mobile.’s
              website for methods of contact –&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
              . We may agree to vary the Credit Limit after making credit checks
              and we may, pursuant to Clause 4.10, ask you to pay a deposit. If
              you go over your Credit Limit (and it may take us some time to
              check this, particularly if you are using your roaming service),
              we may suspend the Services until payment of all Charges
              (including any reconnection charge) has been made and you will, in
              attempting to make any call, be automatically diverted to our
              customer payments helpline to make a payment.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.12 We may require you to pay a deposit for the Services or
              Additional Services. Such deposit will be reasonable, non-interest
              bearing, retained for a minimum period advised to you or until
              your final invoice has been paid following termination of this
              Agreement and Charges incurred by you may be offset against this
              deposit. If there is a remaining deposit after all Charges are
              recovered, we will refund the balance to you. At any time after
              the minimum period advised for holding your deposit, you may ask
              for a refund of your deposit, but this may result in us
              restricting the use or provision of the Services or reducing your
              Credit Limit.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.13 Please note that the Credit Limit does not act as a cap to
              prevent further Charges arising and you may be able to incur
              Charges which result in you exceeding your Credit Limit, subject
              to clause 4.2 above when roaming. If this happens, you will still
              be liable to pay for all Charges incurred in excess of your Credit
              Limit. We may restrict your ability to purchase Additional
              Services based on your Credit Limit. Upgrades are at the sole
              discretion of iot Mobile and will be limited to the duration of
              your Minimum Period unless we notify you otherwise.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.14 You can use the Service to call the emergency services. Your
              number will be shown to the emergency operator. When making an
              emergency call, your location information will be provided to the
              emergency operator to the extent technically feasible.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              4.16 If the PAC / STAC process is delayed, and the delay is our
              fault, you may be entitled to Switching Delay Compensation in the
              form of one-off reimbursement of a portion of your monthly line
              rental, in the form of an account credit. Your switching delay
              compensation amount will be communicated to you and credit will
              automatically be applied to your account.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              5. Your responsibilities
            </span>
          </h1>

          <p>
            <span style={{ fontSize: "11pt" }}>
              5.1 You are responsible for managing your account by downloading
              the iot Mobile App or registering for MyAccount -&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="www.three.co.uk/myaccount"
              >
                www.three.co.uk/myaccount
              </a>
              . You must provide us with a current email address for billing
              purposes and tell us promptly if you change your email. It is up
              to you to check that your mailbox is in proper working order; we
              are not responsible for errors in sending and receiving email,
              unless caused by our negligence.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.2 You shall pay us the Charges in accordance with Clause 12.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.3 You may use the Services only as set out in this Agreement and
              for your own personal use. This means you must not resell or
              commercially exploit any of the Services or content.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.4 You must not use the Services, the SIM Card/eSIM or an GKT
              phone number or allow anyone else to use the Services, the SIM or
              an GKT phone number for illegal or improper uses, including but
              not limited to:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.4.1 fraudulent or criminal acts;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.4.2 in any way which breaches another person’s rights, including
              rights of privacy or of copyright or other intellectual property
              rights;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.4.3 the copying, storage, modification, publishing or
              distribution of the Services or their content;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.4.4 in any way which breaches any security or other safeguards
              or any other way which harms or interferes with the Network, the
              Services, disruptions to emergency services or the networks or
              systems of others;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.4.5 the use or disclosure of details relating to iot Mobile
              customers or other misuse of personal data;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.4.6 for any immoral, obscene, defamatory, harmful, offensive or
              unlawful purpose or for anything which is racist, offensive,
              abusive, indecent, defamatory, obscene or menacing, a nuisance or
              a hoax;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.4.7 to cause annoyance, inconvenience or needless anxiety as set
              out in the Communications Act 2003 as amended or replaced from
              time to time; or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.4.8 to generate Artificially Inflated Traffic.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Any such use shall give us the right to terminate this Agreement
              and the Services immediately and charge you any Charges due.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.5 You must always co-operate with us and follow our reasonable
              instructions to ensure the proper use and security of the Services
              and account. Unless otherwise agreed, you must not reverse the
              charges on any telephone call or accept a reverse charged call.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.6 The SIM Card/eSIM profile we provide does not belong to you
              but remains our property. You may not sell your physical SIM Card,
              or agree to transfer them (other than through porting it to
              another network in accordance with the standard porting process)
              to anyone else without our consent.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.7 If the SIM Card is lost, stolen, damaged, destroyed or used in
              an unauthorised manner we may charge you the amount specified in
              the List of Charges for reconnection. You will be responsible for
              any Charges incurred as a result of unauthorised use of the
              Services and/or the SIM Card/eSIM or the information contained
              within your SIM Card/eSIM, until you have notified us of the need
              to suspend the Services, e.g. as a result of your loss of your
              physical SIM Card.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>5.8 You must:</span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.8.1 take adequate precautions to prevent loss or theft of your
              physical SIM Card;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.8.2 inform our customer services as soon as possible if you do
              lose your physical SIM Card. Please visit the iot Mobile website
              for methods of contact -&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
              &nbsp;or visit the iot Mobile app if your SIM Card is lost,
              stolen, damaged, destroyed or likely to be used in an unauthorised
              manner and to co-operate with us in our reasonable security and
              other checks.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.9 We are providing the SIM Card/eSIM and the Services solely for
              your own personal use (and not for any business purposes) and you
              may not re-sell or otherwise act as any form of distributor in
              respect of the SIM Card/eSIM, the Content, the Services or your
              number, code or any associated number. You must not connect a GSM
              Gateway to the Network or otherwise establish, install or use a
              GSM Gateway in relation to the Network or the Services without our
              prior written consent, which may be withheld at our absolute
              discretion.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              5.10 You must tell us immediately if anyone makes or threatens to
              make any claim or issues legal proceedings against you relating to
              your use of the Services or the Content and you will, at our
              request, immediately stop the act or acts complained of. If we ask
              you to, you must confirm the details of the claim(s) in writing.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              6. Things we may have to do
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.1 Occasionally we may have to:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.1.1 alter the mobile phone number or any other name, code or
              number or technical specification associated with the Services for
              reasons beyond our control such as where requested to do so by a
              governmental or regulatory body or where we reasonably believe
              that the alteration will enhance your use of the Services. If this
              is the case, we will give you reasonable notice;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.1.2 change your SIM Card/eSIM profile;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.1.3 give you instructions which we believe are necessary for
              reasons of health, safety or the quality of Services to you or any
              other customer;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.1.4 temporarily suspend the Services (or any part of them) for
              operational reasons or in an emergency or for reasons of security;
              and/or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.1.5 bar certain numbers from the Services on a temporary or
              permanent basis in order to prevent fraud or in circumstances
              where we would suffer a loss or damage.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.2 From time to time we may have to migrate your account from one
              billing platform to another. In these circumstances, we will
              notify you if migration of your account will affect the Services
              to your detriment in any way.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.3 If we believe that we may be unable to continue to provide the
              Services on a particular network for any reason, such as planned
              or unplanned incidents impacting Services, we may transfer the
              mobile phone number to another network. On transfer, we will
              attempt to continue to make available to you the Services, or
              similar services.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.4 We reserve the right to monitor your usage. If we reasonably
              determine that your use is in breach of any applicable Fair Usage
              Policy, we may:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.4.1 request you to moderate your use; and/or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.4.2 suspend your Service.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              6.5 We reserve the right to manage your use of the GKT network in
              order to protect it for the use of all our customers. Therefore,
              we may deploy traffic management measures from time to time in an
              event which in any way breaches security measures, harms or
              interferes with our network, systems or services or legally
              required. We reserve the right to review and amend any such
              measures from time to time and details of our current&nbsp;traffic
              management policy&nbsp;can be found on our website.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              7. Our right to bar or disconnect
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              7.1 We can, at our discretion, bar your SIM Card/eSIM from making
              calls (other than to the emergency services), sending MMS and/or
              SMS messages and/or sending or receiving data and/or disconnect
              your SIM Card/eSIM from the Network:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              7.1.1 if you have done anything that would entitle us to terminate
              the Agreement (in which case, if we do this, we can still also
              terminate this Agreement if we wish);
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              7.1.2 in the event of loss or theft or if we have reasonable cause
              to suspect fraudulent use of a Payment Method, your SIM Card/eSIM
              and/or Mobile Device; or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              7.1.3 if you are persistently abusive or make threats or otherwise
              act illegally towards our staff or property, or that of our
              agents.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              7.2 You must pay an unbarring charge, any outstanding Charges
              including those incurred under Clause 4.12 and, if applicable, a
              re-connection charge if the Services are temporarily barred and/or
              your Mobile Device and/or SIM Card/eSIM is disconnected from the
              Network for the reasons stated in Clause 5.4 or 7.1.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              7.3 If we bar your Services the Agreement will still continue. You
              must pay all Charges due from you under this Agreement until the
              Agreement is ended by notice in accordance with Clause 8.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              8. Suspension and termination of the Agreement
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.1 This Agreement may be terminated by:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.1.1 you without having to give a reason, by giving us at least
              30 days’ notice in accordance with Clause 17 below. If you do so,
              you must immediately pay us the Charges including any monthly (or
              other periodic) line rental charges for any remaining Minimum
              Period (less any reduction we make for our avoided costs) then
              outstanding as well as any Charges that apply during the notice
              period; or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.1.2 us without having to give you a reason by giving you at
              least 30 days’ written notice in accordance with Clause 17 below.
              Otherwise, following the same notice period, we may (at our
              option) move you on to a different tariff or a SIM only tariff.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.2 Either party may terminate this Agreement at any time on
              notice (in accordance with Clause 17 below) to the other, without
              any liability, if that other party does any of the following:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.2.1 breaches this Agreement in a material way and does not put
              it right (where it is possible to do so) within a reasonable
              period of a request to do so; or;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.2.2 has bankruptcy or insolvency proceedings brought against it.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.3 In addition to our rights under clause 8.2.1, we may terminate
              this Agreement at any time on notice, without liability, if:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.3.1 we have reasonable cause to suspect fraudulent use of your
              Payment Method (or those of any other person) has occurred to
              purchase the SIM Card/eSIM and/or the Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.3.2 we have reasonable cause to suspect that this Agreement has
              been entered into fraudulently or we are satisfied that fraudulent
              or improper use of your mobile phone number is taking place;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.3.3 you do anything (or allow anything to be done) which we
              reasonably think may damage or affect the operation of the Network
              such as for example creating a GSM Gateway, Artificially Inflated
              Traffic or similar;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.3.4 we have reasonable cause to believe that the Services are
              being used in breach of Clauses 5.3, 5.4, 5.5 or 5.9 (even if you
              are not aware of this use);
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.3.5 you fail to pay the Charges when they are due including any
              deposit we have asked for - unless you put matters right within 7
              days of us asking you to do so.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.4 We may make changes to this Agreement and the Services you
              receive at any time. In such case we will send you a notice
              setting out the changes. In certain circumstances that may entitle
              you to terminate the Agreement if the changes are not acceptable
              to you, if there is a right to terminate we will explain this in
              the notice we send to you. See clause 19 for more details on your
              rights to terminate and when a 'No Fee Right to Exit' will apply.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.5 When this Agreement ends:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.5.1 you will be disconnected and your access to the Services
              will end;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.5.2 you will no longer be entitled to use your mobile number
              unless you have ported this number to another network;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.5.3 you must immediately pay all Charges outstanding at
              disconnection; and
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.5.4 we will repay any deposit you have given us less any unpaid
              sums that you owe us (if any). No interest will be payable on any
              deposit.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.6 If you would like to switch to another provider during or
              after your contracted term, you may request a PAC or STAC at any
              time, and when you use your PAC or STAC it will immediately end
              your Agreement with us. The following terms apply:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.6.1 The PAC process must be used when you wish to switch your
              provider and keep, or port, your number.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.6.2 The STAC process (also known as N-PAC ) must be used by when
              you do not wish to port your number. Using the STAC process means
              that your existing mobile number from the previous provider will
              be terminated and you will not be able to reactivate it.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.6.3 You can request your unique switching codes by text: for
              PAC, text PAC DDMMYY to 65075, for STAC text STAC DDMMYY to 75075,
              or for INFO text INFO DDMMYY to 86075. DDMMYY should be your date
              of birth. You can also request them online in MyAccount via&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/"
              >
                https://iotmobile.co.uk/
              </a>
              /myaccount&nbsp;or by dialling 7777 from your phone.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.6.4 Even if you are ending this Agreement by using the PAC /
              STAC processes, you will still have to pay any outstanding and
              unbilled Charges along with any Early Termination Fee (if there is
              one). But you will not be charged for any notice period after the
              PAC / STAC process has been completed.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.6.5 You may also request a PAC within 30 days after your account
              is terminated. From the date of issue, your PAC will be active for
              30 days and during this time you can port your number to another
              provider. Requesting a PAC and porting your number to another
              provider after termination is free of charge and does not have any
              impact on your final bill. To request a PAC after termination you
              must contact our customer services team. Methods of contact can be
              found on iot Mobile's website;&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
              .
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.6.6 If you wish to cancel this Agreement after 30 days of
              &nbsp;period time then you may do so but you will have to pay an
              Early Termination Fee.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.7 We may suspend the Services where you exceed the Credit Limit
              or where you do anything that would entitle us to terminate this
              Agreement (in which case, if we do this, we can still terminate if
              we wish). We reserve the right not to provide them again until you
              provide adequate confirmation that you will stop breaching this
              Agreement. We will continue to provide access to emergency
              services.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.8 We may also suspend or terminate the provision of the Services
              where this is strictly necessary:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.8.1 for reasons outside of our control; or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.8.2 if any agreement giving us the ability to provide the
              Services is suspended or terminated.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.9 If we suspend the Services, this Agreement will still
              continue. You do not have to pay the Charges where we suspend the
              Services under Clause 8.9, but otherwise you must pay all Charges
              during any period of suspension until the Agreement is ended
              including all monthly (or other periodic) line rental charges for
              any Minimum Period which continues to run.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.10 You must pay a re-connection charge of £10.00 if the Services
              are temporarily suspended for the reasons stated in Clause 8.8
              above.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.11 In addition to the rights in this Clause 8, you may be able
              to terminate this Agreement in accordance with the terms of sale
              (or similar) that apply to you.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              8.12 At the end of your Minimum Period, this Agreement will
              continue on these existing terms until you tell us that you'd like
              to terminate your Agreement. You can do so by giving us at least
              30 days' notice, and you should contact us as set out in Clause
              17.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              9. Internet Access
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              9.1 If you have a Mobile Device, which enables access to the
              Internet ("Mobile Internet Device") the following terms and
              conditions also apply to you.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              9.2 The Internet is not a secure environment. Unwanted programs or
              material may be downloaded without your knowledge, which may give
              unauthorised persons access to your Mobile Internet Device and the
              information stored on your Mobile Internet Device. These programs
              may perform actions that you have not authorised, possibly without
              your knowledge.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              9.3 We or our contractual partners may provide links to other
              websites or resources. We neither accept responsibility for third
              party websites or resources nor endorse their Content. Your
              dealings with, and interest in, promotions, services, or merchants
              found by using your Mobile Internet Device are solely between you
              and the person with whom you are dealing. You are solely
              responsible for evaluating the accuracy and completeness of any of
              the Content and the value and integrity of goods and services
              offered by third parties over the Services. We will not be a party
              to or in any way responsible for any transaction concerning third
              party goods and services or for any losses or damages that may
              arise from any such dealings with third parties except in the case
              of negligence on our part.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              9.4 For Internet access, you understand that all the visual,
              textual or other information published or otherwise made available
              (directly or indirectly) on the Internet using the Services
              ("Information") whether publicly posted or privately transmitted,
              is the sole responsibility of the person from which such
              Information originated. This means that you, and not us, are
              entirely responsible for all Information that you upload, email or
              otherwise transmit via Internet access.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              9.5 We will use reasonable endeavours to maintain the Content but
              it may be incomplete, out of date or inaccurate and is provided on
              an ‘as is’ basis. It is a condition of us allowing you access to
              the Content that you accept that we will not be liable for any
              action you take in reliance on the Content. We may vary the
              Content or the technical specification of the Services from time
              to time.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              9.6 The Services enable access to the Content. You may only use
              the Content in a way that does not infringe the rights of others
              ("Approved Use") and you must comply with all other instructions
              issued by us regarding use of Content. You shall not store,
              modify, transmit, distribute, broadcast, or publish any part of
              Content other than for an Approved Use. The re-selling, copying or
              incorporation into any other work of part or all of the Content in
              any form is prohibited save that you may print or download
              extracts of the Content for your personal use only.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              9.7 For all tariffs, when your Services are connected, your
              internet access provided through the Services will have been
              programmed so that you are barred from accessing Content that we
              deem to be ‘adult’. The ability to use such adult services is
              subject to status and credit checks and us being satisfied that
              you are over 18 years of age. If you want to have your Services
              unbarred, then you should do so through the iot Mobile website for
              methods of contact -&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
              . We may ask you to make a payment by credit or debit card or
              pursuant to Clause 4.12 ask you to pay a deposit.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              9.8 Access to secure financial transactions will be dependent on
              the make and model of your Mobile Internet Device and the third
              party supplier of Content.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              10. Upgrades
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              10.1 If you choose to upgrade with GKT, your new tariff will be
              activated immediately at completion of point of sale. You will
              receive a notification via email that your upgrade has been
              processed and that your new tariff is active.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              10.2 Once the new tariff is active, your previous tariff will have
              an end date of midnight on the day before the date your upgrade
              became active.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              10.3 If you had already begun your bill cycle before your upgrade
              was completed, your first bill will include the monthly recurring
              cost from your previous tariff, pro-rated up until the date your
              previous tariff ended, and the monthly recurring cost from your
              new tariff, pro-rated until the end of your bill cycle.
              Accordingly, your monthly inclusive allowances will also be
              pro-rated.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              11. Special Feature(s)
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.1 Capping: All pay monthly plans purchased after 15th June 2017
              have a cap applied.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.1.1 This means that the out of bundle Charges you pay each
              month shall not be more than the monthly line rental plus the
              capped amount (as specified in your Tariff Summary). For example,
              if your monthly line rental is £10.00 per month and your selected
              capped amount is £5.00, the total Charges you will have to pay
              will never be more than £15.00 each month. Capping does not apply
              to the purchase of certain Services such as add-ons or paper
              billing.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.1.2 Once you have reached the capped amount, you will only be
              able to use your remaining inclusive or add-on allowances.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.1.3 Subject to credit status, you may be able to increase your
              capped amount using our mobile app or through 'iot Mobile' online
              account. You shall be responsible for any increases to your Capped
              Amount, regardless of whether or not you authorised it.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.2 If you finish your inclusive allowance and continue to make
              Eligible Calls or SMS Services whilst roaming, we will charge you
              our standard roaming rates which vary depending on roaming
              destination. For roaming rates visit&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/help-and-advice/international-and-roaming-charges"
              >
                https://iotmobile.co.uk/help-and-advice/international-and-roaming-charges
              </a>
              .
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.2.1 Additional Services or add-ons are not part of your monthly
              inclusive allowance and may not be used whilst roaming as such.
              However, subject to our Fair Use Policy add-ons may be used when
              roaming in our Roam Free Destinations. For more information,
              please see our&nbsp;Fair Use Policy.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.3 Rollover plan: If you have purchased a Rollover tariff or
              have added Rollover to your tariff, your unused monthly inclusive
              data allowance can be rolled over into the next consecutive
              month(s) for a set period, as specified in your Tariff Summary.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.3.1 Rollover may be included as a feature of your tariff or may
              be a chargeable service that can be added to your tariff. In the
              case of the latter, Rollover can only be added to your tariff at
              point of sale.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.3.2 Only your inclusive data allowances are eligible for
              rollover. Any Additional Services or reward allowances that you
              have included in your Tariff are not considered to be part of your
              monthly inclusive allowance. This means that if you purchase any
              Additional Services allowances and receive any reward allowances,
              they will not be eligible for Rollover and will be subject to
              their individual terms and conditions.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.3.3 If your tariff has an unlimited data allowance, it will not
              be eligible for Rollover. If your account is not up-to-date and is
              subject to credit control or your account is suspended you will
              not be eligible for Rollover until your account is up-to-date or
              reinstated.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.3.4 Any unused Rollover data allowances will be lost if you
              migrate to a new tariff. However, if the new tariff is eligible
              for Rollover, you will begin to accrue Rollover allowances again.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.4 Data Pot is a free data Additional Service, valid for 24
              months from date of purchase.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.4.1 You can use all or part of the Data Pot in any month but
              only when your monthly Data allowance (inclusive bundle data and
              other data Additional Service) has been used.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.4.2 The Data Pot is added only once and will be reflected in
              your total data allowance within "Remaining Allowances" in My
              Account or Self Care. It will not be carried forward when you
              upgrade or renew your existing contract.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              11.4.3 If you'd like to cancel this Agreement, you can do so at no
              cost during the cooling-off period, which is 30 days for in-store
              purchases and 30 days for iot Mobile online and. You'll have to
              pay for any line rental, usage, and additional purchases, such as
              add-ons, accrued up to the point of disconnection.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              12. Charges
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.1 The Charges for the Services will be calculated in accordance
              with your Tariff and our List of Charges using the details we have
              recorded. The List of Charges may change from time to time.
            </span>
          </p>
          <p>
            <span style={{ fontSize: "11pt" }}>
              12.2 We will provide Post Pay customers with regular notifications
              prompting customers to access their bill via the free iot Mobile
              app or&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/"
              >
                https://iotmobile.co.uk/
              </a>
              . A full itemisation of the Charges incurred and usage can be
              accessed If you would prefer to receive your bill in a paper
              format (for which we may charge you in accordance with the List of
              Charges) please visit iot Mobile website for methods of contact
              -&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
              .
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.3 You must pay to us all the Charges arising under this
              Agreement whether incurred by you or anyone else using your Mobile
              Device and/or SIM Card/eSIM with or without your knowledge at the
              times and in the manner set out in this Agreement even when such
              Charges exceed any Credit Limit imposed upon your account.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.4 Calls made by Post Pay customers in the UK to eligible UK
              numbers are charged or deducted per second (rounded up to the
              nearest second) with a 60 second minimum charge.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.5 For all customers, calls made in the UK to eligible
              international numbers are charged in one minute increments rounded
              up to the nearest minute.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.6 When roaming in the EEA, charges for calls you make will be
              charged or deducted per second (rounded up to the nearest second)
              with a 30 second minimum charge. When roaming other than in the
              EEA, you will be charged for the calls you make and receive by the
              minute or less and any part minute will be rounded up to the
              nearest whole minute or less. This is dependent on the location
              you are roaming in. Details of these charges will be set out in
              our List of Charges. MMS or SMS messages made outside the EEA may
              be charged other than in whole penny increments. Within the EEA,
              SMS messages will be deducted from your domestic allowance and
              MMS, from your data allowance.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.7 Data usage made by Post Pay customers are charged or deducted
              in one kilobyte (KB) increments and any part KB used will be
              rounded up to the nearest KB. Suggest MB?
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.8 Unless otherwise stated, call prices are quoted by the minute
              and include VAT. Each call is charged excluding VAT and then
              rounded up to the nearest whole penny, each MMS or SMS is charged
              excluding VAT and then rounded up to the nearest tenth of a penny.
              VAT is then added where applicable and then rounded up to the
              nearest whole penny. An invoice including the rate of VAT charged
              and our VAT number can be provided upon written request for Post
              Pay customers (this may be chargeable).
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.9 Your first month's line rental after the beginning of the
              Minimum Period is likely to be less or more than the amount stated
              in your Tariff, as monthly Charges incurred for, and inclusive
              allowances in respect of, periods of less or more than one month
              will be calculated on a pro rata basis and billed to you
              accordingly. Regardless of when the call is commenced, Charges
              will be applied according to your Tariff and our List of Charges
              for each chargeable period the call is active.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.9.1 Initial Bill - Your first bill will be sent to you within
              7-10 days. This bill will be slightly higher than the regular
              Monthly Charge and will include two separate charges. The first
              charge covers the initial 4-9 days, allowing you to begin using
              GKT Services immediately. The second charge is for the first full
              Monthly Charge. Additionally, any charges for Additional Services
              you have used will also be included.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.9.2 Payment by Direct Debit – iot Mobile’s plan prices include
              a discount for paying by direct debit. You can set up a direct
              debit through the iot Mobile app, by mail, or by contacting
              Customer Services, who will assist you with the setup.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Iot Mobile cannot stop the Customer from cancelling a direct
              debit, but if a direct debit is cancelled, the Customer must still
              ensure bills are paid by the due date. For alternative payment
              methods, visit Ways to Pay under the Support section on iot
              Mobile’s website. You will keep the direct debit discount if you
              choose to pay with a major credit or debit card, as long as you
              permit iot Mobile to store your chosen payment method for monthly
              billing. You can update or change these payment details at any
              time and as often as needed.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.10 Any unused inclusive allowances may not be carried forward
              from one month to the next unless expressly stated by us. In this
              context "monthly" or "month" means your monthly billing period,
              which may not equate to a calendar month. Eligible Calls will be
              set against inclusive allowances in the order in which such
              Eligible Calls are made.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              If you've exhausted your monthly included minutes, you have the
              option to buy an Add-on, which will be valid until your monthly
              allowance renews, or you can switch to a price plan with a higher
              data allowance.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              For more details of Customers’ plans, including Outside of
              Allowance Services, and Add-Ons, please view our Price Guide at
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/"
              >
                https://iotmobile.co.uk/
              </a>
              .
            </span>
          </p>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.11 Calls to your voicemail and any of our customer service
              lines will be chargeable in accordance with the Tariff and may be
              deducted from any monthly allowances. When using call return
              (returning a call when directly within your voicemail service),
              you will be charged for both the voicemail call and the call
              return call throughout the duration of the returned call. Call
              return calls will be deducted from any inclusive allowance, where
              appropriate. The call return charge is calculated as the rate for
              that Tariff, as if you had hung up and dialled direct for the
              returned call, less the voicemail charge. The minimum call rate on
              call return is the same as the voicemail rate. For Post Pay
              Tariffs, calls to voicemail 123 are decremented from your
              inclusive allowance.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.12 A standard text message contains up to 160 characters. Some
              Mobile Devices allow you to send messages of more than 160
              characters, in which case the message will be divided into the
              number of messages required to convey the message and each such
              message will be charged at the standard text message rate. Some
              Mobile Devices convert messages of more than 160 characters or
              group messages into MMS and therefore these will be charged at
              standard MMS rates. When including an email address and / or
              special characters including, but not limited to emojis within a
              standard text message, some Mobile Devices convert these messages
              into MMS and therefore these will be charged at standard MMS
              rates. Standard text message prices exclude premium rate,
              international, roaming, reverse charged and long text messages.
              Only standard text messages are included in inclusive SMS
              allowances.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.13 Using MMS you can send long text messages, picture messages
              and video messages. A long text message, picture message and video
              message is limited to a maximum size of 295kb. If a message
              contains multiple media items you will be charged for the most
              expensive item in the message, for example, send a message
              containing a picture and video clip you are charged the video
              message price. Should you wish to prevent incurring charges for
              MMS, you must switch off the MMS function on your device or adjust
              the settings so that you are warned if an MMS is about to be sent.
              Please refer to your device manual for further information.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.14 For Post Pay Tariffs, charges are payable to us by Direct
              Debit on the dates notified to you following acceptance of your
              order. You must pay the charges within 14 days of the date of any
              bill. Failure to pay on time will result in a £5.00 collections
              administration fee (see List of Charges) being applied to your
              account. Should your direct debit fail, you may use an alternative
              payment method to pay; please contact us to arrange this.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.15 If a Direct Debit or other Payment Method is dishonoured or
              cancelled, we shall be entitled to charge you as compensation for
              our losses an amount equal to the costs that we incur as a result
              of such dishonoured or cancelled Direct Debit or other Payment
              Method (which may include third party charges levied on us).
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.16 For any overdue payments we may charge interest (both before
              and after any judgment) on the amount unpaid at the rate of 2%
              above the base lending rate of HSBC Bank Plc as published from
              time to time. Interest is charged on a per annum basis, calculated
              daily. Overdue payments will also be subject to a collections
              administration fee (see List of Charges), regardless of the
              payment method you choose. Should any overdue payment remain
              unpaid, we reserve the right to forward / sell the overdue balance
              to a debt collection agency.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.17 If we advise you of the amount due on your account from time
              to time this is an estimate based on the information we have
              received to date.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.18 We may arrange for bills or invoices to be issued by a third
              party on our behalf. Bills or invoices issued by such third party
              shall be binding on you and payment of such bills or invoices in
              full to the third party where directed to do so will be a valid
              discharge of your liability to pay such bills or invoices under
              this Agreement.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.19 If you change Tariff for any reason e.g. when Upgrading or
              moving to a promotional offer, any unused allowances will be lost.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.20 If we provide you with a credit to your account (for example
              as a goodwill gesture), such credit will be deducted from your
              Charges until the credit has been used. These credits cannot be
              redeemed for cash and any unused credits are non-refundable upon
              disconnection of your Service or termination of this Agreement.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.21 Additional call, text and/or data charges may apply when
              used outside the UK and the EEA. Data usage in the EEA is subject
              to our Fair Use Policy available on our Website at&nbsp;
            </span>
            <a
              target="__blank"
              className="doc-anchor underline hover:opacity-80"
              rel="noopener noreferrer"
              href="https://iotmobile.co.uk/help-and-advice/eu-roaming/fair-usage-policy"
            >
              https://iotmobile.co.uk/help-and-advice/eu-roaming/fair-usage-policy
            </a>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.22 Your Monthly Charge is "£X" and does not cover any charges
              beyond your allowance. Certain UK calls, including those to 070
              numbers, are not part of your monthly voice unit allowance. If you
              call numbers starting with 084, 087, 09, or 118, Three’s access
              charge is 65p per minute (with a one-minute minimum charge), and
              you will also incur a service charge set by the company you are
              calling, which they will inform you of.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Your Monthly Charge includes a £5 discount for using a recurring
              payment method, like Direct Debit. If you cancel your recurring
              payment and choose a different payment method, you will lose this
              discount. The Monthly Charge for your Package is the minimum
              amount you commit to paying for Three Services under this
              agreement, for the Minimum Term. Every April, your Monthly Charge
              will increase by the December CPI Rate plus 3.9%. An example of
              how this increase works is shown in the table below,
            </span>
          </p>
          <div align="left">
            <table
              style={{
                width: "100%",
                margin: "10px 0",
                border: "none",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>
                        Monthly Price until March 2023
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>
                        Monthly Price from April 2023 to March 2024
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>
                        Monthly Price from April 2024 to March 2025
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>Price A</span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>
                        Price A plus December 2022 CPI + 3.9% (= Price B)
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>
                        Price B plus December 2023 CPI + 3.9% (= Price C)
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>
                        We've set out an example below, showing how this would
                        work, if your Monthly Charge is £30 and CPI is 10.5% in
                        December 2022 and 5% in December 2023.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>£30.00</span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>
                        £30 + £4.32 (14.4% of £30) = £34.32
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "11pt" }}>
                        £34.32 + £3.05 (8.9% of £34.32) = £37.38
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.23 Any and all discounts, promotional offers and/or bill
              credits shall only be valid for the Minimum Period specified in
              your Tariff only.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.24 Calls and/or texts to premium rate services, non-geographic
              numbers, international numbers made in the UK and in the EEA when
              roaming (unless otherwise expressly stated by us) are not included
              in your monthly inclusive allowance. Here are some of our charges
              (incl. VAT) that aren't included in your contract, for the full
              list of charges please visit&nbsp;
            </span>
            <a
              target="__blank"
              className="doc-anchor underline hover:opacity-80"
              rel="noopener noreferrer"
              href="https://iotmobile.co.uk/help-and-advice/call-charges"
            >
              https://iotmobile.co.uk/help-and-advice/call-charges
            </a>
          </p>
          <div align="left">
            <table
              style={{
                width: "100%",
                margin: "10px 0",
                border: "none",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        Non-geographic numbers starting 084, 087, 09, 118
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        Access charge of 45p per min, plus a Service Charge
                        (Ser-vice Charge is set by the organisation you are
                        calling)
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        Personal numbering services start-ing 070
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>£0.55 per min</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        Pager calls starting 076
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>£1.22 per min</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        Corporate numbering service start-ing 070
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>£0.55 per min</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>MMS messages</span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>55p per message</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        International calling and roaming charges
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p>
                      <span style={{ fontSize: "11pt" }}>
                        Please visit:&nbsp;
                        <a
                          target="__blank"
                          className="doc-anchor underline hover:opacity-80"
                          rel="noopener noreferrer"
                          href="https://iotmobile.co.uk/help-and-advice/international-and-roaming-charges"
                        >
                          https://iotmobile.co.uk/help-and-advice/international-and-roaming-charges
                        </a>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>Other charges</span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "11pt" }}>&nbsp;</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        Collections administration fee
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>£5</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        Itemised paper bill
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>£1.50 per bill</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        Changing your phone number
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>£25</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        Replacement itemised paper bill
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>£2.50 per bill</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>
                        SIM card replacement
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "solid rgb(0, 95, 104) 0.5pt",
                      padding: "0 6px",
                    }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "12pt" }}>£0</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.25 Calls to non-geographic numbers are made up of two parts: an
              Access Charge and a Service Charge. The Access Charge is how much
              iot Mobile charges you for making the call. The current iot Mobile
              Access Charge is £0.45, plus £0.45 per minute. The Service Charge
              is set by the organisation you're calling. For more information,
              please visit the iot Mobile website&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/help-and-advice/call-charges"
              >
                https://iotmobile.co.uk/help-and-advice/call-charges
              </a>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              12.26 We may make changes to our international call costs and
              roaming charges as a result of charges imposed on us by our
              wholesale partners or providers. Such changes will not be made
              more frequently than once every three months. We will notify you
              of any such changes and your “No Fee Right to Exit” under Clause
              19.4 if these changes within our reasonable determination are
              likely to be of material dis-advantage to you.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              13. Limitation of liability
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              13.1 We will not be liable under this Agreement for any loss or
              damage caused by circumstances where:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              13.1.1 there is no breach of a legal duty of care owed to you by
              us;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              13.1.2 such loss or damage is not a reasonably foreseeable result
              of any such breach; or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              13.1.3 such loss or damage results from the breach by you of any
              term of this Agreement.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              13.2 Our liability shall not in any event include losses related
              to any business of a customer including but not limited to lost
              data, lost profits or business interruption.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              13.3 Nothing in this Agreement shall:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              13.3.1 exclude or limit our liability for death or personal injury
              resulting from our acts or omissions or those of our servants,
              agents or employees; or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              13.3.2 limit your rights as a consumer under applicable UK law.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              13.4 All Services are provided on a commercially reasonable
              efforts basis. Although we will provide the Services with
              reasonable skill and care, we make no warranty that the Services
              will meet your exact requirements or that they will always be
              available.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              13.5 Each provision of this Clause 13 operates separately. If any
              part is disallowed, or is not effective, the other parts will
              continue to apply even after the Agreement has been terminated or
              cancelled.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              14. Matters beyond our reasonable control
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              14.1 If either of us cannot do what we have promised in this
              Agreement because of something beyond our reasonable control such
              as, without limitation, lightning, flood, exceptionally severe
              weather, fire, explosion, terrorism, war, military operations,
              national or local emergency, epidemics and pandemics, civil
              disorder, industrial disputes (whether or not involving our
              employees), acts or omissions of persons for whom we are not
              responsible (including other telecommunication providers), or acts
              of local or central Government or other competent authorities,
              such party will not be liable for this.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              15. Use and disclosure of information
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.1 We need to collect certain Personal Information to provide
              you with the Goods and/or Services. This Personal Information will
              form part of a record of your dealings with us.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.2 When you contact us, we may ask for certain Personal
              Information to be able to check your identity and we may make a
              note of this. We will keep Personal Information given to us by you
              or others during your relationship with us and other companies in
              the Group (and the Manager’s Group). This includes:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.2.1 details you give us on order forms or during communications
              with you; and
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.2.2 details we receive from credit reference and fraud
              detection agencies.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3 You agree that we may use and update your Personal
              Information:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.1 for credit and credit related services and to manage your
              accounts;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.2 when applicable in relation to the Goods and/or Services
              ordered, to make credit, fraud and identity checks on you (i)
              prior to accepting your order; and (ii) subsequently for the
              purpose of risk assessment, debt collection and fraud prevention
              whilst you retain a financial obligation to us;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.3 to provide you with goods and/or services that you have
              ordered;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.4 to trace and recover debts;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.5 to prevent and detect fraud, crime and money laundering;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.6 to update our records about you; and
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.7 to check your identity
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.8 to segment our customer base and help us develop offers,
              products and services to provide you with the best customer
              experience;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.9 where we have your permission to, we will market and
              advertise our Services, products and occasionally third party
              products and services that may be of interest to you including
              third party offers, promotions, advertisements, competitions or
              commercial communications by telephone (mobile and landline when
              available),post, electronic messaging (including SMS and MMS),
              email or online or via applications;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.10 to recommend packages for you based on your use of the
              Services e.g. if you regularly exceed your voice or data plan, we
              may suggest you moving to a better Tariff;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.11 to contact you to invite you to form part of our consumer
              panel or research groups about our products and services or
              customer satisfaction surveys (you may be contacted by third
              parties on our behalf); or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.3.12 to provide you with personalised services and
              communications as well as targeted advertising from iot Mobile on
              our website and selected partner websites.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.4 If you give us false or inaccurate information or we identify
              fraud, we may record this.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.5 Any credit checks that we undertake will be recorded by the
              credit reference agencies in the form of a search 'footprint' on
              you credit file. This 'footprint' may then be seen by other people
              conducting similar searches of your credit file.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.6 We may use and disclose information about you and how you run
              your accounts to credit reference, law enforcement and fraud
              prevention agencies. For example, if you do not pay any sums owed
              to us when due, details of this failure may be passed on to credit
              reference agencies who will record this information against your
              credit file. Such records may remain on file with credit agencies
              for 6 years after your account is closed, whether settled by you
              or not.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.7 Credit Agencies may pass the Personal Information that they
              received from us to other organisations in the performance of
              credit and/or fraud checks. Your Personal Information together
              with any additional information held by credit reference agencies
              and fraud prevention agencies may also be used to trace your
              whereabouts and recover debts that you owe.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.8 Information held about you by credit reference agencies may
              be linked to records of people who are financially linked to you.
              We and other organisations may use credit reference agency and
              fraud prevention agency records that we receive about you, and
              people financially linked to you to help make decisions about you
              and them.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.9 We may also enable third party partners to tailor ads which
              are more relevant and useful to you when you are browsing the
              internet or are using mobile apps. This may involve the use of
              cookies and other similar technologies. Personalisation may be
              based on various factors such as your age and gender, your device
              details or your inferred interests but we will not share any
              information with these third parties which directly identifies
              you. However, by interacting with or viewing an ad, you should be
              aware that the third party may make the assumption that you meet
              the targeting criteria used to display the ad. You can opt out of
              targeting of advertising from third parties (so that you receive
              ads without using this information). For more information, please
              visit&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="www.youronlinechoices.com/uk"
              >
                www.youronlinechoices.com/uk
              </a>
              . We may use your location if you have told us that you are
              interested in location based offers and services.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.10 We may use your Personal Information for research and
              statistical analysis, to develop and improve our products and
              services.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.11 When assessing an application, we may use automated
              decision-making systems.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.12 Your Personal Information is confidential and, although we
              may freely disclose it to other companies within the Group (and
              the Manager may disclose it within its Group), we will only
              disclose it outside the Group when:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.12.1 you give us your consent;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.12.2 it is needed by certain reputable third parties involved
              in running accounts and/or providing services for us (for example,
              credit reference agencies or companies that we use in the
              provision of the Services);
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.12.3 it is needed in order to obtain professional advice;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.12.4 it is needed to investigate or prevent crime (e.g. to
              fraud prevention agencies);
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.12.5 the law permits or requires it, or any regulatory or
              governmental body requires it, even without your consent; or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.12.6 there is a duty to the public to reveal the Personal
              Information.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.13 We may administer your account and provide services from
              countries outside Europe that may not have the same data
              protection laws as the UK. However, we will have contracts or
              other legal mechanisms in place to ensure your Personal
              Information is adequately protected, and we remain bound by our
              obligations under the Data Protection Act even when your Personal
              Information is processed outside Europe. For full details
              visit&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/legal/privacy-and-cookies"
              >
                https://iotmobile.co.uk/legal/privacy-and-cookies
              </a>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.14 We may monitor, record, store and use any telephone, email
              or other electronic communications with you for training purposes,
              to check any instructions given to us and to improve the quality
              of our services.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.15 Where we process sensitive Personal Information, we will
              employ appropriate security measures. If we discover a security
              incident, such as compromised confidential information and
              privacy, you will be notified, and we will take steps to fix the
              issue in line with our internal security policies and procedures.
              For more information and to view our Privacy Policy, please
              visit&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/legal/privacy-and-cookies"
              >
                https://iotmobile.co.uk/legal/privacy-and-cookies
              </a>
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              "15.16 If you would like us to tell you what information we hold
              about you, or if you wish to have details of the credit reference
              or the fraud prevention agencies from whom we obtain and with whom
              we record information about you should write to us at: GKT
              Cranbourne House,135 Bath Road, Slough, SL1 3UX."
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.17 With your consent, we will contact you with marketing
              material. You can make changes to your marketing preferences
              and/or correct or update any inaccurate or incomplete information
              at any time by logging onto my account at&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/"
              >
                https://iotmobile.co.uk/
              </a>
              and visiting the My Account area.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.18 We may pass your information to the Mobile Equipment
              National Database ("MEND") and the Stolen Equipment National
              Database ("SEND") (organisations operated by Recipero Limited, a
              company registered in England and Wales under Company No. 3794898
              and with its registered office at Lawrence House, Lower Bristol
              Road, Bath BA2 9ET) to enable MEND or SEND to contact you in the
              event that you lose your Mobile Device or it is stolen from you.
              To contact us, please visit iot Mobile’s website for methods of
              contact –&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
              &nbsp;if you do not want your information to be passed to MEND
              and/or SEND.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.19 It is your responsibility to ensure that you notify any
              change of mobile device to MEND and SEND to ensure that there is
              no interruption of their service to you.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              15.20 If you give us information about another person, you confirm
              they have given you permission to provide it to us and for us to
              be able to process their personal information. You must also
              confirm that you have told them who we are and the basis on which
              we will use their information.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              16. Call monitoring
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              16.1 All conversations calls, web chat services, emails, text
              messages or other communications with iot Mobile Customer Services
              Representatives and with iot Mobile may be recorded for quality
              monitoring, training purposes, the prevention of unauthorised use
              of our telecommunications systems, effective systems operation and
              the prevention or detection of crime.
            </span>
          </p>
          <h1>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              17. Notices
            </span>
          </h1>
          <p>
            <span style={{ fontSize: "11pt" }}>
              17.1 If you want to end your agreement, you must contact us
              through the 'Contact Us' section in our website at&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
              &nbsp;and give us at least 30 days notice a future disconnection
              request by selecting a date which is at least 30 days in to the
              future. If you prefer to contact iot Mobile via letter, please
              send this to iot Mobile, Cranbourne House,135 Bath Road,Slough,SL1
              3UX. You must include your full name, your billing address with
              post code, your GKT telephone number and the name of the bank your
              direct debit is with to help us locate your account. If you would
              like to give us notice beyond 30 days in to the future, you can
              contact us at&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
              &nbsp;for more details.&nbsp;Whenever you have a No Fee Right to
              Exit you must exercise it within 30 days of it arising; if you do
              not notify us within this period, you will lose the right to
              terminate.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              17.2 Proof of sending a letter does not guarantee our receipt of
              your notice. You must ensure that you have received an
              acknowledgement from us which should be retained by you.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              17.3 We may send notices to you under or in connection with this
              Agreement:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              17.3.1 by posting them to the address of which you have informed
              us; or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              17.3.2 by SMS, which shall be sent to your mobile phone number; or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              17.3.3 by e-mail to the email address of which you have informed
              us; or
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              17.3.4 by push notification to your Mobile Device.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              17.4 Notices sent by post will be deemed to have arrived at their
              destination within 3 working days after posting, and notices
              delivered by hand or given by SMS, e-mail or push notification
              shall be deemed to have been delivered the day after the day the
              notice is sent.
            </span>
          </p>
          <h1>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              18. Complaints
            </span>
          </h1>
          <p>
            <span style={{ fontSize: "11pt" }}>
              18.1 If you ever wish to complain about the Services, we will
              endeavour to handle such complaints fairly, efficiently and
              confidentially. Please visit iot Mobile website for details of our
              complaints procedure -&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/"
              >
                https://iotmobile.co.uk/
              </a>
              /help-and-advice/complaints-procedure.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              18.2 If we can't resolve your query, you may ask that the matter
              is referred to an independent ombudsman, the details of which are
              available on our website -
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/"
              >
                https://iotmobile.co.uk/
              </a>
              /help-and-advice/complaints-procedure or by contacting us. Please
              visit iot Mobile's website for methods of contact -&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
              .
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              18.3 If you are not happy with the way that we deal with any
              disagreement and you want to take court proceedings, you must do
              so within the United Kingdom.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              19. Changes to this Agreement
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              19.1 We reserve the right to change this Agreement, our Charges or
              our Services, at any time subject to your rights under Clause 19.2
              below, these changes will take effect at the point specified in
              the notification we send you.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              19.2 In certain circumstances the changes described in Clause 19.1
              may entitle you to exercise your “No Fee Right to Exit”. We will
              notify you at least 30 days in advance of such changes being made.
              If you wish to exercise your “No Fee Right to Exit” please refer
              to the process in Clause 17. The “No Fee Right to Exit” does not
              apply when we make the following permitted changes to the
              Agreement, our Charges or our Services;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              19.2.1 If you have a handset plan, there will be an annual
              adjustment to your monthly plan charge. Each April, your monthly
              plan charge will increase by an amount equal to the Consumer Price
              Index rate of inflation published by the Office for National
              Statistics in February (“CPI rate”) plus an additional 3.9% on top
              of the CPI rate;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              19.2.2 Changes to Roaming and international call charges in
              accordance with Clause 12.26;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              19.2.3 We may make administrative or technical changes;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              19.2.4 We may make changes required by: (a) applicable laws; (b)
              regulations; (c) codes of practice; (d) a regulator; or (e) a
              court of competent jurisdiction;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              19.2.5 Where the increase or change relates solely to Additional
              Services that do not form part of the main service. In that case,
              your cancellation rights apply only to the Additional Service
              affected.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              19.3 If you carry on using Your Plan and the Services after the 30
              day notice period provided in accordance with Clause 19.2 above,
              you will be deemed to have accepted the change, and will not be
              able to subsequently terminate this Agreement without a fee under
              the terms of the notice.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              20. Transferring this Agreement
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              20.1 We can transfer the benefit and you agree that we can
              transfer the obligations under this Agreement to any third party
              (including, without limitation, any member of the Group), without
              notice to you or your consent. We have subcontracted the
              administration and management of the services we provide to iot
              Mobile (the “Manager”).
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              20.2 You may not transfer this Agreement to anyone else unless we
              have agreed in writing beforehand and we shall not unreasonably
              withhold such agreement.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              21. Third parties
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              21.1 Other than as provided for under Clause 20.1 above, nobody
              but you and us can benefit from this Agreement under the Contracts
              (Rights of Third Parties) Act 1999.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              22. General
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              22.1 If you or we break this Agreement and the other party chooses
              to overlook it, this will not prevent the other party from taking
              further action at a later time. Further, if you breach this
              Agreement in a manner that allows us to terminate the Services but
              we decide just to suspend them instead, we can still end this
              Agreement if you breach it again.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              22.2 When you use your SIM Card/eSIM, the identity of your mobile
              phone number may be sent through the networks so as to be
              identified to the phone being called. It may be used to divert
              calls to us for administration and for the investigation of fraud.
              You may be charged for any diversion. The identity of your mobile
              phone number will always be sent if you are calling 999, 911 or
              112.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              22.3 If the facility to eliminate the presentation of the number
              of an incoming call is made available; we may charge you for the
              use of such a facility at the price as stated in our List of
              Charges from time to time where we consider your use of such
              facility to be unreasonable. If you do not want your number
              displayed on receiving handsets, key 141 before the number you
              wish to call. Otherwise, you agree that our Network may allow the
              display of your mobile phone number on receiving handsets.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              22.4 For your own protection, you must keep confidential the
              electronic serial number of your Mobile Device, any lock code(s)
              associated with your Mobile Device, your voice mail access number,
              and any other personal identification password or security number.
              When choosing a password, you must not use words that are obscene
              or likely to cause offence.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              22.5 We make every effort to ensure the security of your
              communications. You are however advised that for reasons beyond
              our control, there is a risk that your communications may be
              unlawfully intercepted or accessed by those other than the
              intended recipient. For example, your communications may pass over
              third party networks over which we have no control, and if you are
              connected to an analogue network, there is no protection for your
              communications over the air interface. If you are connected to the
              digital network, your communications over the air interface with
              our systems are encrypted providing a greater level of protection,
              but even this cannot be guaranteed. Please also read carefully the
              instructions provided with your Mobile Device. Depending upon the
              manufacturer and model, your Mobile Device may send information
              stored on it and receive information to and from certain third
              parties without your knowledge.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              22.6 You must tell us promptly in writing or via the iot Mobile
              app if you change your name and address or there are any changes
              to your bank account or payment card arrangements that may affect
              your payment of the Charges.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              22.7 If you require the terms and conditions in an accessible
              format, such as braille, large font print, audio, or dyslexia
              friendly colour paper print, please speak to a member of our
              customer services team. Methods of contact can be found on iot
              Mobile's website; -&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/contactus"
              >
                https://iotmobile.co.uk/contactus
              </a>
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              23. Capacity
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              23.1 You confirm that you have full legal capacity to agree to
              this Agreement and you that you are able to pay the Charges under
              this Agreement.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              24. Customer Services PIN
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              24.1 You will be provided with a Customer Services PIN number via
              SMS when your order for a Post-Pay Tariff has been accepted by us.
              You will be required to change your PIN the first time you contact
              to iot Mobile, and it is your responsibility to protect your PIN.
              This may also be found and changed via the iot Mobile app or My
              Account at&nbsp;
              <a
                target="__blank"
                className="doc-anchor underline hover:opacity-80"
                rel="noopener noreferrer"
                href="https://iotmobile.co.uk/myaccount"
              >
                https://iotmobile.co.uk/myaccount
              </a>.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              24.2 A Customer Services PIN number allows to you to interact with
              iot Mobile. Customer Services Representatives and access
              information regarding your account, including (but not limited to)
              your outstanding balance and usage and allowance information,
              purchasing add-ons and bundles, activating/deactivating,
              termination requests and any Additional Services you may have on
              your account and performing manual payments. Please note that our
              customer services team will be unable to discuss any account
              details with you unless we can verify your identity (using the PIN
              or security questions).
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              25. Governing law and jurisdiction
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              25.1 This Agreement shall be governed by English law, unless you
              live in Scotland in which case this Agreement shall be governed by
              Scots law.
            </span>
          </p>
          <h1 style={{ textAlign: "justify", marginTop: 20 }}>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              26. Returns
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              26.1 If you made your purchase on iotmobile.co.uk, retailer or via
              the iot Mobile telephone sales team, you have 30 days to cancel
              this Agreement at no cost and paid amount will be refunded. You
              must contact us to inform us of your wish to cancel. You should
              not return the physical SIM cards to us. If you wish to cancel
              this Agreement after this 30 day period then you may do so but you
              will have to pay an Early Termination Fee.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              26.2 &nbsp;If the SIM Cards/eSIM found to be faulty and should be
              replaced, you can check the nearby store on the internet and get
              it replaced from the specific store free of charge.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              26.3 You are liable to pay for any line rental, usage and
              additional purchases, such as add-ons, accrued up to the point of
              disconnection. Failure to do this may affect your credit score.
            </span>
          </p>

          <h1>
            <span
              style={{
                fontSize: "11pt",
                backgroundColor: "transparent",
                fontWeight: 600,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              27. Privacy&nbsp;
            </span>
          </h1>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              To provide a Customer with GKT's services under this agreement,
              iot Mobile may process your personally identifiable information:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              that you provide to iot Mobile or that iot Mobile may obtain about
              you as a result of any application, registration for, or use of
              iot Mobile Services. This may include your name, current and
              previous addresses, date of birth, phone and fax numbers, gender,
              email address, employment details, bank, and credit or debit card
              information, as well as information obtained from credit reference
              and fraud prevention agencies; and
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              information acquired and processed about your use of GKT Services
              while you are a customer of GKT. This may include Location Data,
              Communications Data, dynamic IP addresses, phone number, the
              unique code identifying your Phone and SIM, data from marketing
              organizations and service providers (including information from
              other countries), and your account information, including contact
              history notes.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              For more details on how GKT uses personally identifiable
              information, please visit our Privacy Portal at Your privacy | iot
              Mobile.
            </span>
          </p>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>28. Security</span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              GKT will promptly inform you if any unauthorized disclosure, loss,
              or destruction of Personal Data happens while providing GKT
              Services and comes to their attention. Unless compelled by law or
              other obligations, GKT will refrain from discussing any specific
              case with third parties, including the media, vendors, consumers,
              or those affected, without first obtaining your consent.
            </span>
          </p>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>29. Accessibility</span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              GKT provides a range of services aimed at making communication
              easier for customers with accessibility needs, including Emergency
              SMS, Directory Enquiries, Voicemail, Interpreters! Live, and Relay
              UK. For the most current information on GKT's accessibility
              services, please visit gkt.co.uk/accessibility.
            </span>
          </p>
        </section>
      </main>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default GKTTnC;

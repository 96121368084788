import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

const Complaint = () => {
  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <section className="mx-auto max-w-6xl flex flex-col justify-center items-center">
          <h1 className="text-2xl text-center font-semibold leading-9 tracking-tight primary-text capitalize mb-1">
            Welcome to Complaint portal
          </h1>
          <p className="text-center" style={{ color: "#ff9d28" }}>
            *Note: For immediate assistance, please contact us at{" "}
            <span className="underline font-semibold">
              help@iotmobile.co.uk
            </span>
          </p>
          <div className="w-full mt-6">
            <iframe
              className="w-full h-screen"
              src="https://docs.google.com/forms/d/e/1FAIpQLSfQoH6w5ZQ6lsS8VGO_6DCs9G0EJQFOCTD1StzCfAz5_ObYSA/viewform?embedded=true"
              width="640"
              height="1272"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Complaint;

import React from "react";
import banner01Lg from "../../assets/carousel/banner_01_lg.jpg";
import banner01Sm from "../../assets/carousel/banner_01_sm.jpg";
import banner02Lg from "../../assets/carousel/banner_02_lg.jpg";
import banner02Sm from "../../assets/carousel/banner_02_sm.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CustomCarousel = () => {
  return (
    <>
      <Carousel showArrows={true} autoPlay={true} infiniteLoop>
        <div>
          <img
            className="carousel-img-lg w-full object-contain"
            src={banner01Lg}
          />
          <img
            className="carousel-img-sm w-full object-contain"
            src={banner01Sm}
          />
        </div>
        <div>
          <img
            className="carousel-img-lg w-full object-contain"
            src={banner02Lg}
          />
          <img
            className="carousel-img-sm w-full object-contain"
            src={banner02Sm}
          />
        </div>
        <div>
          <img
            className="carousel-img-lg w-full object-contain"
            src={banner01Lg}
          />
          <img
            className="carousel-img-sm w-full object-contain"
            src={banner01Sm}
          />
        </div>
        <div>
          <img
            className="carousel-img-lg w-full object-contain"
            src={banner02Lg}
          />
          <img
            className="carousel-img-sm w-full object-contain"
            src={banner02Sm}
          />
        </div>
        <div>
          <img
            className="carousel-img-lg w-full object-contain"
            src={banner01Lg}
          />
          <img
            className="carousel-img-sm w-full object-contain"
            src={banner01Sm}
          />
        </div>
        <div>
          <img
            className="carousel-img-lg w-full object-contain"
            src={banner02Lg}
          />
          <img
            className="carousel-img-sm w-full object-contain"
            src={banner02Sm}
          />
        </div>
      </Carousel>
    </>
  );
};

export default CustomCarousel;

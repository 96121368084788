import axios from 'axios';

import React, { Component } from 'react';
import { API_URL } from '../constant/Constant';

class DealerSignupService {
    
    dealerSignup(json) {
        return axios.put(`${API_URL}authentication/checkValidDealer`,json,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              }
        });

    }
    
}



export default new DealerSignupService()

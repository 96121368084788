import React from "react";
export const faqContent = [
  {
    id: 1,
    question: "How do I order a free SIM?",
    answer: (
      <>
        <p className="text-sm">
          Go to
          <a
            className="underline pl-2"
            target="__blank"
            rel="noopener noreferrer"
            href="https://www.three.co.uk/shop/sim-only/payg"
          >
            www.three.co.uk/shop/sim-only/payg
          </a>
           and select the Pay As You Go plan when adding to your basket.
        </p>
        <br />
        <p className="text-sm">
          No need to choose a Data Pack – skip this by hitting Continue to get
          your free SIM.
        </p>
      </>
    ),
  },
  {
    id: 2,
    question: "What is a Pay As You Go SIM?",
    answer: (
      <>
        <p className="text-sm">
          A Pay As You Go SIM card fits into your existing phone, and gives you
          access to our network. You aren’t tied into a contract, so you can add
          a
          <a
            className="underline pl-2"
            href="https://www.three.co.uk/pay-as-you-go/payg-data-packs"
            target="__blank"
            rel="noopener noreferrer"
          >
            Data Pack
          </a>{" "}
          or top up your credit to use your phone as much or as little as you
          need to.
        </p>
      </>
    ),
  },
  {
    id: 3,
    question: "What is a SIM only deal?",
    answer: (
      <>
        <p className="text-sm">
          A SIM-only deal is a mobile plan that provides you with data, minutes,
          and texts, but does not include a new handset. It’s ideal if you’re
          satisfied with your current phone and want to reduce your monthly
          costs. SIM-only contracts are typically much cheaper than plans that
          include a new phone. With a SIM-only plan, you can choose flexible
          contract terms, fixed 12-month or 24-month contract, depending on your
          preferences.
        </p>
      </>
    ),
  },
  {
    id: 4,
    question:
      "What is the difference between a pay monthly SIM-only deal and a pay-as-you-go SIM-only deal?",
    answer: (
      <>
        <p className="text-sm">
          A pay monthly SIM-only deal involves paying a fixed monthly fee for a
          set allowance of data, minutes, and texts. This type of plan typically
          offers a commitment for a fixed term, such as 12 or 24 months, with a
          consistent monthly payment.
          <br />
          On the other hand, a pay-as-you-go SIM-only deal allows you to pay
          only for the amount of data, minutes, and texts you use. You top up
          your account as needed.
        </p>
      </>
    ),
  },
  {
    id: 5,
    question: "What size SIM card do I need?",
    answer: (
      <>
        <p className="text-sm">
          The majority of modern smartphones use a Nano SIM, which is the
          smallest SIM card size. However, older phone models may still require
          a Micro SIM or Standard SIM. iot Mobile offers a multi-fit SIM that
          includes all three sizes, ensuring you have the right SIM for your
          device. Additionally, if your phone supports it, you can choose an
          eSIM, a digital SIM that doesn't require a physical card.
        </p>
      </>
    ),
  },
  {
    id: 6,
    question: "Can I keep my phone number if I get a SIM-only deal?",
    answer: (
      <>
        <p className="text-sm">
          Yes, you can easily keep your phone number when switching to an iot
          Mobile(Three) SIM-only plan, whether you're using your current phone
          or getting a new one.
          <br />
          If you're switching to iot Mobile from another provider, there's no
          need to contact your old network directly. Instead, just send a text
          with the word ‘PAC’ to 65075 using your old SIM. PAC stands for
          Porting Authorisation Code, and by providing us with your PAC code, we
          can transfer your old mobile number to your new iot Mobile(Three)
          SIM-only plan.
          <br />
          Your previous network will send you your PAC code along with any
          details regarding outstanding charges or penalties on your account. If
          you've completed your minimum contract term, they won’t charge you for
          the remainder of your notice period. This means you won't have to pay
          two bills at once, but be sure you're not still under contract, as
          early termination fees may apply.
          <br />
          Once you have your new iot Mobile(Three) SIM-only plan and PAC code,
          simply enter it when you set up your new account. We'll transfer your
          number to your new plan the next five working day
        </p>
      </>
    ),
  },
  {
    id: 7,
    question: "What are the available contract lenghts on SIM only deals?",
    answer: (
      <>
        <p className="text-sm">
          IoT Mobile offers 12-month, and 24-month SIM-only plans.
        </p>
      </>
    ),
  },
  {
    id: 8,
    question: "Can I use my new SIM-only deal in my phone or tablet?",
    answer: (
      <>
        <p className="text-sm">
          Some phones are locked to specific networks, meaning they will only
          work with SIM cards from that network. If you got your phone through a
          contract, it’s more likely to be locked. However, if you bought the
          phone outright from a retailer, it’s probably unlocked.
          <br />
          If your phone is locked, you’ll need to unlock it. If you’re no longer
          under contract, you can usually unlock your phone for free by
          contacting your network provider. Unlocking your phone can also
          increase its resale value if you plan to sell or trade it in.
          <br />
          To check if your phone is unlocked, simply remove your current SIM
          card and insert a SIM card from another network. If your phone works
          and gets a signal, it means it’s unlocked, and you can use your new
          SIM-only deal without any issues.
        </p>
      </>
    ),
  },
  {
    id: 9,
    question: "What if I run out of data on my SIM-only plan?",
    answer: (
      <>
        <p className="text-sm">
          If you run out of data, you can still use your phone, but you'll be
          charged extra until your bill reaches the set limit. To avoid these
          extra charges, you can buy a data add-on.
          <br />
          Just keep in mind that add-ons only work from the moment you buy them
          and won't cover data you've already used.
        </p>
      </>
    ),
  },
  {
    id: 10,
    question: "Can I get 5G coverage with a SIM Only plan?",
    answer: (
      <>
        <p className="text-sm">
          Yes, as long as you have a 5G-compatible device and are within a 5G
          coverage area, our SIMs will work perfectly. You can check the
          coverage in your area using our 5G coverage checker. LINK -
          <a
            className="underline pl-2"
            target="__blank"
            rel="noopener noreferrer"
            href="https://www.three.co.uk/support/network-and-coverage/coverage"
          >
            https://www.three.co.uk/support/network-and-coverage/coverage
          </a>
        </p>
      </>
    ),
  },
  {
    id: 11,
    question: "Can I get 5G coverage with a SIM Only plan?",
    answer: (
      <>
        <p className="text-sm">
          You can cancel your contract within 30 days of purchase at any time.
          For Pay Monthly/SIM Only contracts, if you decide to terminate early
          after the initial 30 days, you may be required to pay a fee. Our Pay
          Monthly SIM Only plans are available for 12 or 24 months. If you've
          been with us for more than 90 days, you also have the option to
          upgrade your plan. For more details, please visit:
          <a
            className="underline pl-2"
            target="__blank"
            rel="noopener noreferrer"
            href="https://www.three.co.uk/why-three/anytime-upgrade."
          >
            https://www.three.co.uk/why-three/anytime-upgrade.
          </a>
        </p>
      </>
    ),
  },
  {
    id: 12,
    question: "Can I use my SIM Only plan abroad?",
    answer: (
      <>
        <p className="text-sm">tbc</p>
      </>
    ),
  },
  {
    id: 13,
    question: "How do I activate my iot Mobile (THREE) SIM?",
    answer: (
      <>
        <p className="text-sm">To activate your SIM, follow these steps:</p>
        <ul className="text-sm pl-4">
          <li className="list-disc">Insert the SIM into your phone and power it on.</li>
          <li className="list-disc">
            You'll receive a text confirming that the activation process has
            started.
          </li>
          <li className="list-disc">A second text will instruct you to restart your phone.</li>
          <li className="list-disc">
            Your SIM will be fully activated once your phone has restarted.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 14,
    question: "What are the SIM Only deals are available on iot Mobile?",
    answer: (
      <>
        <ul className="text-sm pl-4">
          <li className="list-disc">Unlimited Lite SIMO 12M, £28 p/m</li>
          <li className="list-disc">250GB Lite SIMO 12M, £22 p/m</li>
          <li className="list-disc">120GB Lite SIMO 12M, £18 p/m</li>
          <li className="list-disc">25GB Lite SIMO 12M, £16 p/m</li>
          <li className="list-disc">12GB Lite SIMO 12M, £14 p/m</li>
          <li className="list-disc">Unlimited Lite SIMO 24M, £23 p/m</li>
          <li className="list-disc">250GB Lite SIMO 24M, £18 p/m</li>
          <li className="list-disc">120GB Lite SIMO 24M, £14 p/m</li>
          <li className="list-disc">25GB Lite SIMO 24M, £13 p/m</li>
          <li className="list-disc">12GB Lite SIMO 24M, £11 p/m</li>
        </ul>
      </>
    ),
  },
  {
    id: 15,
    question: "How can i get a PAC code? ",
    answer: (
      <>
        <p className="text-sm">
          If you're switching to iot Mobile (Three) from another service
          provider, there's no need to contact your old network directly.
          Instead, just send a text with the word ‘PAC’ to 65075 using your old
          SIM. PAC stands for Porting Authorisation Code, and by providing us
          with your PAC code, we can transfer your old mobile number to your new
          iot Mobile(Three) SIM-only plan.
        </p>
      </>
    ),
  },
  {
    id: 16,
    question: "What’s an eSIM?",
    answer: (
      <>
        <p className="text-sm">
          An eSIM is a virtual SIM card built into your phone, which allows you
          to activate a cellular plan without needing a physical SIM. With an
          eSIM, there's no waiting for activation. Many phones support both an
          eSIM and a physical SIM, enabling you to use two numbers from a single
          device.
        </p>
      </>
    ),
  },
];

import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import aboutUsBg from "../../assets/about-us-bg.jpg";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";
import useScrollToTop from "../customHooks/useScrollToTop";

const AboutUs = () => {
  useScrollToTop();
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <section className="mx-auto max-w-6xl">
          <h1 className="text-2xl font-semibold leading-9 tracking-tight primary-text capitalize mb-4">
            GK Telecom, A Trusted Brand.
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-9 mt-6">
            <div>
              <img
                src={aboutUsBg}
                className="w-full object-cover rounded-lg max-h-72 max"
                alt="About Us"
              />
            </div>
            <div>
              <p className="text-justify">
                GK Telecom was founded over 24 years ago and has steadily grown
                into one of the largest and most reputable SIM and calling card
                distributors in the UK.
                <br />
                <br />
                GK Telecom was founded in 2000 predominately focusing on calling
                cards. GK Telecom diversified into the pre paid SIM market in
                2004 and over a period of time this market has developed into
                maturity and GK Telecom is now one of only four SIM Only
                Distributors in the UK selling in excess of 100,000 SIMS per
                month across various networks.
              </p>
              <Button
                style="cta-one w-1/2 mt-5"
                type="button"
                title="Get Started"
                handleClick={() => navigate("/")}
              />
            </div>
          </div>
        </section>
        <section className="bg-[#5A949A]/10 py-6 my-14 about-us-strip mx-auto max-w-5xl">
          <div className="mx-auto max-w-5xl grid grid-cols-1 gap-y-12 md:grid-cols-3">
            <div className="text-center">
              <h2
                style={{ fontFamily: "Inter" }}
                className="font-semibold text-4xl"
              >
                5 Million
              </h2>
              <p>SIMs Delivered Globally</p>
            </div>
            <div className="text-center">
              <h2
                style={{ fontFamily: "Inter" }}
                className="font-semibold text-4xl"
              >
                24+ Years
              </h2>
              <p>Global Distribution Excellence</p>
            </div>
            <div className="text-center">
              <h2
                style={{ fontFamily: "Inter" }}
                className="font-semibold text-4xl"
              >
                200+ Countries
              </h2>
              <p>GKT eSIM Available In</p>
            </div>
          </div>
        </section>

        <section className="mx-auto max-w-6xl">
          <h2 className="text-xl font-semibold">Know more about us</h2>
          <p>
            With a wealth of experience as a SIM distributor, GK has established
            itself as a leader in the industry. However, we are now embarking on
            an exciting transformational journey by embracing the new frontier
            of eSIM technology. Our mission is to continue providing a
            world-className experience to users through our innovative eSIM
            platform. eSIM represents a groundbreaking shift that is set to
            replace traditional methodologies. At GK, we are passionate about
            ensuring that this revolutionary technology reaches every
            stakeholder, empowering them with seamless connectivity. Our goal is
            to make eSIM adoption accessible to all, offering a simple and
            straightforward process that opens doors to a new era of convenience
            and connectivity. Through our expertise and dedication, we are
            committed to delivering exceptional service, safeguarding data, and
            providing secure transactions. Join us on this remarkable journey as
            we revolutionize the way people connect and experience the world
            through the power of eSIM technology.
          </p>
        </section>
      </main>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default AboutUs;

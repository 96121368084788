import axios from 'axios';

import React, { Component } from 'react';
import { API_URL } from '../constant/Constant';

class VerificationService {

    doVerification(id) {
        //console.log("idddddddd==", id);
        const verification = {
            "userId": 0,
            "email": "string",
            "password": "string",
            "confirmPassword": "string",
            "verificationId": id,
            "customer": true
        }
        return axios.post(`${API_URL}authentication/customerVerification`, verification, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

    }

    getVerificationStatus(dealerId) {
        // const verification = {
        //     "userId": 0,
        //     "email": "string",
        //     "password": "string",
        //     "confirmPassword": "string",
        //     "dealerId": dealerId,
        //     "customer": true
        // }
        //console.log("dealer data in loader===",dealerId);
        return axios.get(`${API_URL}authentication/${dealerId}/verificationstatus`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
    }

    updateUserReqInfo(userUpdateReqId) {
        return axios.get(`${API_URL}authentication/updateUserVerification`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'userUpdateReqId': userUpdateReqId
            }
        });
    }
}

export default new VerificationService();
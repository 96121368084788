import React from "react";
// Core viewer
import { Viewer } from "@react-pdf-viewer/core";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import axios from "axios";
import { API_URL } from "../../../constant/Constant";

const DynamicComponent = ({documentId}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });


  return (
    <div className="w-full mx-auto h-[90vh] overflow-y-auto">
      <Viewer
        fileUrl={`${API_URL}api/v1/getTCDocPdf/${documentId}`}
        plugins={[defaultLayoutPluginInstance]}
      />
    </div>
  );
};

export default DynamicComponent;

import axios from 'axios';
import { API_URL } from '../constant/Constant';
class CancellationService {

    cancellationApi(emailValue, iccidMsisdnValue, resendValue) {
        return axios.post(
            `${API_URL}api/v1/sendCancellationRequestEmail/${emailValue}/${iccidMsisdnValue}/${resendValue}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
        );
    }

    checkOrderCancellationStatus(orderId) {
        return axios.get(
            `${API_URL}api/v1/orderCancellationStatus/${orderId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
        );
    }
    orderCancelConfirmation(verificationId,orderId){
      return axios.post(
          `${API_URL}api/v1/orderCancelConfirmation/${verificationId}/${orderId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
      );
    }
    saveOrderCancellationRequest(json){
      return axios.post(
          `${API_URL}api/v1/saveOrderCancellationRequest`, json,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
      );
    }
}
export default new CancellationService()